const state = {
    SOCKET_CONNECTED : false,
    SOCKET_USER_SUBSCRIBED : false
}

const actions = {
    INIT_SOCKET({rootState},socket) {
        rootState.$socket = socket
    },
    SOCKET_notification({dispatch},data) {
        dispatch('notifications/PUSH_NOTIFICATION',data)
    },
    SOCKET_connect({state,rootState}) {
        state.SOCKET_CONNECTED = true

        if ( !state.SOCKET_USER_SUBSCRIBED && rootState.auth.user.id ) {
            rootState.$socket.emit('subscribe',{
                id         : rootState.auth.user.id,
                businesses : rootState.auth.userBusinesses,
                warehouses : rootState.auth.userWarehouses,
                subscriptions : []
            })
            state.SOCKET_USER_SUBSCRIBED = true
        }

    },
    SOCKET_disconnect({state}) {
        state.SOCKET_CONNECTED = false
        state.SOCKET_USER_SUBSCRIBED = false
    },
}
export default {
    namespaced : false,
    actions,
    state
}
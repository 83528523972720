const state = {
}

const actions = {

    async GET_FILE_BROWSER({dispatch,rootState} , body) {

        let isSharedRoute = body.shared ? '/shared' : ''

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/content/${body.location}` + isSharedRoute,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_FILE_BROWSER_FOLDERS({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/folders`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_FILE_SHARED_USERS({dispatch,rootState} , body ) {

        let type = body.is_folder ? 'folder' : 'file'
        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/shared_users/${type}/${body.uuid}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async EDIT_FILE_SHARED_USERS({dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/item/shared_users`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    // Folders
    async ADD_EDIT_FOLDER({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/folder`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async DELETE_FOLDER({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/folder/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async MOVE_TO_FOLDER({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`file_browser/folder/move_to`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },



}

export default {
    namespaced:true,
    actions,
    state
}
const state = {
    businessCardview : 'dashboard',
    eventsFilter : {
        type : '',
        is_completed : null,
        event_origin : 'clients'
    },
    completionFilter : 'new'
}
const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            return data


        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    // REGISTRATIONS STATISTICS
    async GET_MONTH_REGISTER_STATISTICS({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/registrations/report`,
                method:'POST',
                data : body
            }, {root:true });
    },

    async ADMIN_GET_DASHBOARD({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/dashboard`,
                method:'GET'
            }, {root:true });
    },
    async ADMIN_CRM_GET_MANAGERS_SELECT({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/managers/select`,
                method:'GET'
            }, {root:true });
    },
    //

    // Business Crm Functions
    async ADMIN_CRM_GET_BUSINESSES({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/businesses/${body}`,
                method:'GET'
            }, {root:true });
    },
    async ADMIN_CRM_GET_TASKS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/tasks/${body}`,
                method:'GET'
            }, {root:true });
    },
    async ADMIN_CRM_TASKS_GET_TYPE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/tasks/get/${body}`,
                method:'GET'
            }, {root:true });
    },


    // Business Crm Functions
    async ADMIN_CRM_GET_BUSINESS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/${body}`,
                method:'GET'
            }, {root:true });
    },
    async ADMIN_CRM_EDIT_BUSINESS_STATUS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/status`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async ADMIN_CRM_EDIT_BUSINESS_PROPERTY({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/property`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async ADMIN_CRM_EDIT_BUSINESS_TAGS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/tags`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async ARCHIVE_BUSINESS({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/archive/${body}`,
                method:'GET'
            }, {root:true });
    },

    // Events
    async ADMIN_CRM_ADD_BUSINESS_EVENT({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/event`,
                method:'PUT',
                data : body
            }, {root:true });
    },
    async ADMIN_CRM_EDIT_BUSINESS_EVENT({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/event`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async ADMIN_CRM_DELETE_BUSINESS_EVENT({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/event/${body}`,
                method:'DELETE',
            }, {root:true });
    },

    async ADMIN_CRM_GET_BUSINESS_EVENT({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/event/${body}`,
                method:'GET',
            }, {root:true });
    },
    async ADMIN_CRM_CLIENT_ADD_BUSINESS_EVENT({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/client/business/event`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async ADMIN_CRM_GET_BUSINESS_EVENTS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/${body}/events/`,
                method:'GET',
            }, {root:true });
    },


    // LEADS
    async GET_LEADS({dispatch}) {

        return await dispatch('AJAX_SEND',
            {
                token:'public',
                route:`clientLeads`,
                method:'GET'
            });
    },
    async ADD_LEAD({dispatch,rootState},body) {

        body.project = rootState.project
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`clientLead`,
                method:'PUT',
                data : body
            });
    },
    async EDIT_CLIENT_LEAD({dispatch,rootState},body) {

        body.project = rootState.project
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`clientLead`,
                method:'POST',
                data : body
            });
    },

    // PROJECTS
    async ADD_PROJECT({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/project`,
                method:'PUT',
                data : body
            },{ root : true});
    },
    async GET_PROJECTS({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/projects`,
                method:'GET'
            },{ root : true});
    },
    async GET_PROJECT({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/project/${body}`,
                method:'GET'
            },{ root : true});
    },




    // PAYMENT PLANS

    async GET_BUSINESS_PLAN_QUOTAS({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/quotas/${body}`,
                method:'GET',
            }, {root:true });
    },
    async EDIT_BUSINESS_MODULE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/module`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async EDIT_BUSINESS_DATE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin_crm/business/date`,
                method:'POST',
                data : body
            }, {root:true });
    },


    // Technical
    async GET_SUPERADMIN_FILE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/superadmin/${body.file}`,
            method:'GET',
            fileResponse : true
        },{ root : true });
    },


}

export default {
    namespaced:true,
    state,
    actions,

}
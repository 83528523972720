const state = {
    logoKey : 0,
    templateLoaded       : false,
    headerDark           : true,

    selectedBusiness       : {},

    businessAlias          : '',
    businessName           : '',
    businessTemplate       : '',
    businessСountry        : '',
    page                   : null,
    page_buffer            : null,
    news                   : [],
    products               : [],
    product_categories     : [],
    cart                   : [],
    locations              : [],
    deliveryAllowCountries : [],
    deliveryCountries      : [],

    showCart               : false,
    openEditor             : false,
    openEditorBlockElement : false,
    openEditorBlockDeleteElement : false,
    openEditorBlockType    : null,
    openEditorGeneral      : false,

    triggerScroll          : 0,

    openImageLibrary       : false,
    imageLibraryFile       : '',
    imageLibrarySenderId   : '',

    templateVars_init      : {},
    templateVars_current   : {},
    menuItems              : [],



    templatesConfig : {
        avalon : {
            elements : [
                'NewsElement'
            ]
        }
    },

    // :: TEMPLATER EDITOR VARIABLES ::
    // Triggers and indicators
    editorMode : false,
    editorTrigger : 0,

    // Selected Indexes
    selectedPage           : '',
    selectedBlock          : null,
    selectedBlockColumn    : null,
    selectedBlockItem      : null,
    // Selected Params
    selectedBlockTypeId     : null,
    // Selected Objects
    selectedItem           : {},

    // Dialog variables
    displayEditLogo      : false,
    displayEditBlock     : false,
    displayEditColumn    : false,
    displayEditMainMenu  : false,
    displayNewPageDialog : false,
}
const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async UPLOAD_IMAGE({dispatch,state},body) {
        return await dispatch('ajax/SEND_NEW',{
            route:`upload/template/image`,
            method:'POST',
            business : state.businessAlias,
            data: { fileFormData: body }
        }, {root:true});
    },

    // :: TEMPLATER EDITOR ::

    // Tempalate Parametrs
    async SAVE_TEMPLATE_PARAM_DRAFT({dispatch,state},param) {
        let data = {
            item  : state.templateVars_current[param].item,
            type  : state.templateVars_current[param].type,
            value : state.templateVars_current[param].buffer
        }
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/param`, method:'POST',
                business : state.businessAlias,
                data : data
            }, {root : true}
        );
    },
    async SAVE_TEMPLATE_PARAMS({dispatch,state}) {
        let data = { items : [] }
        for (const key in state.templateVars_current) {
            data.items.push({
                item  : state.templateVars_current[key].production,
                type  : state.templateVars_current[key].type,
                value : state.templateVars_current[key].value
            })
        }
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/save/params`, method:'POST',
                business : state.businessAlias,
                data : data
            }, {root : true}
        );
    },
    async SAVE_TEMPLATE_EDIT({dispatch,state},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/save`,
                method:'POST',
                business : state.businessAlias,
                data: body
            }, {root: true });
    },
    // Blocks
    async ADD_BLOCK({dispatch,state},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/block/new`,
                method:'PUT',
                business : state.businessAlias,
                data: body
            },{ root : true }
        );
    },
    async DELETE_BLOCK({dispatch,state},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/block`,
                method:'DELETE',
                business : state.businessAlias,
                data: body
            }, {root: true});
    },
    async GET_TEMPLATE_BLOCKS_SELECT({dispatch,state}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`template/blocks/select`,
                method:'GET',
                business : state.businessAlias,
            },{ root : true });
    },

    // OLD STUFF

    // this must be old but soon maybe i will need it
    async SAVE_BLOCK({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`${body.alias}/template/block`, method:'PUT',data: body.data});
    },

    async GET_TEMPLATES_PUBLIC({dispatch}) {
        return await dispatch('AJAX_SEND',{token:'public',route:`templates`, method:'GET'});
    },
    async ADD_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template`, method:'PUT',data: body});
    },
    async GET_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template/${body}`, method:'GET'});
    },
    async EDIT_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`template/${body.uuid}`, method:'PUT', data: body.data});
    },
    async GET_USER_TEMPLATE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`options/template/${body}`, method:'GET'});
    },

}
const getters = {
    BLOCK_PARAM: state => param => {
        return   state.page.blocks[state.selectedBlock].value[param]
    },
    COLUMN_PARAM: state => (param,blockIndex = null, columnIndex = null) => {

        let rowIndex = blockIndex !== null ? blockIndex : state.selectedBlock
        let colIndex = columnIndex !== null ? columnIndex : state.selectedBlockColumn
        if ( state.page && state.page.blocks
            && state.page.blocks[rowIndex]
            && state.page.blocks[rowIndex].value
            && state.page.blocks[rowIndex].value.columns_parametrs
            && state.page.blocks[rowIndex].value.columns_parametrs[colIndex]) {
            return state.page.blocks[rowIndex].value.columns_parametrs[colIndex][param]
        } else {
            return null
        }

    },


}
const mutations = {

    SET_BLOCK_PARAM(state,params) {
        if ( state.page && state.page.blocks
            && state.page.blocks[state.selectedBlock]
            && state.page.blocks[state.selectedBlock].value ) {
            state.page.blocks[state.selectedBlock].value[params[0]] = params[1]
        }
    },
    SET_COLUMN_PARAM(state,params) {

        if ( state.page && state.page.blocks
            && state.page.blocks[state.selectedBlock]
            && state.page.blocks[state.selectedBlock].value
            && state.page.blocks[state.selectedBlock].value.columns_parametrs
            && state.page.blocks[state.selectedBlock].value.columns_parametrs[state.selectedBlockColumn]) {

            state.page.blocks[state.selectedBlock].value.columns_parametrs[state.selectedBlockColumn][params[0]] = params[1]

            state.page.blocks = Object.assign({},state.page.blocks)

        }
    },
    DISPLAY_EDIT_LOGO(state) {
        state.displayEditLogo = true
    },
    DISPLAY_EDIT_MAIN_MENU(state) {
        state.displayEditMainMenu = true
    },
    DISPLAY_EDIT_COLUMN(state) {
        state.displayEditColumn = true
    },



}

export default {
    namespaced:true,
    getters,
    actions,
    mutations,
    state
}
const state = {
}
const actions = {

    async GET_USER_SCHOOLS_COURSES({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {   route:`united/courses`,
                method:'GET',
            },{ root : true }
        );
    },


}

export default {
    namespaced:true,
    state,
    actions,
}
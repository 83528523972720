const actions = {

    async LINK_BUSINESS({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'stripe/link',
                method:'POST',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async UNLINK_BUSINESS({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'stripe/link',
                method:'DELETE',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async GET_STRIPE_CHECKOUT_URL({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'stripe/invoice',
                method:'POST',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );

    },

}

export default {
    namespaced:true,
    actions,
}
const state = {
    entityData : {},
    displayDialog : false,
    displayDeleteDialog : false,
    newEntity : false,
    loading : false,
    storeNamespace : null,
    items : []
}

const actions = {

}

export default {
    namespaced:true,
    actions,
    state
}
<template>
  <component v-if="$store.state.onboarding.displayWindow"
             :is="$store.state.onboarding.component" />
</template>

<script>
import AdminWelcomeWestudy_Dashboard from "@/onboardTours/AdminWelcomeWestudy/components/AdminWelcomeWestudy_Dashboard";
import AdminWelcomeWestudy_Courses from "@/onboardTours/AdminWelcomeWestudy/components/AdminWelcomeWestudy_Courses";
import ChatBots_Dashboard from "@/onboardTours/ChatBots/components/ChatBots_Dashboard";


export default {
  name: "wsOnboardWrapper",
  components : {
    AdminWelcomeWestudy_Dashboard,
    AdminWelcomeWestudy_Courses,
    ChatBots_Dashboard
  }
}
</script>

<style scoped>

</style>
const actions = {

    // :: COURSES ::
    async GET_STORAGE_INFO({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/business`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, {root : true});
    },
    async GET_STORAGE_BROWSER({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/business/browser`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body || {}
            }, {root : true});
    },
    async DELETE_FILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/user_file/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE'
            }, {root : true});
    },
    async EDIT_FILE_NAME({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/user_file`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, {root : true});
    },

    async GET_FILE_FULL_DATA({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`storage/file/full_data/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },


}

export default {
    namespaced:true,
    actions

}
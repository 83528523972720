const state = {
}

const actions = {

    // PROJECTS
    async GET_DOCUMENTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/documents`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_DOCUMENT({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/document`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async GET_SIGNATURE_REQUESTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/signature_requests`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async SIGN_DOCUMENT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/sign/document`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    async CHECK_DOCUMENT_PUBLIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`documentflow/check/document/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },










}

export default {
    namespaced:true,
    actions,
    state
}
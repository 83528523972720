const actions = {

    // STUDY

    async EXPORT_STATISTICS_STUDY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`export/study`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
                returnFile : true
            },{ root : true });

    },
    async EXPORT_EVALUATION_STUDENT_DATA({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`export/evaluation/students`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
                returnFile : true
            },{ root : true });

    },
    async EXPORT_TABLE_DATA({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`export/table_data`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
                returnFile : true
            },{ root : true });

    },


}

export default {
    namespaced:true,
    actions
}
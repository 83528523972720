// let $store;
let isProcessingQueue = false;
let QUEUE = [];

// function setStore(store) {
//     $store = store;
// }

function flushQueue() {
    QUEUE = []
}

async function handleQue() {
    if (isProcessingQueue) {
        return;
    }

    isProcessingQueue = true;

    while (QUEUE.length > 0) {
        let request = QUEUE.shift();
        try {
            let result = await getResult(request.params.baseURL, request.params.route, request.params.params, request.params.file);
            request.resolve(result);
        } catch (error) {
            request.reject(error);
        }
    }
    isProcessingQueue = false;

}
async function pushRequest(baseURL, route, params, file , resolve , reject) {
    QUEUE.push({
        resolve : resolve,
        reject : reject,
        params : {
            baseURL : baseURL,
            route : route,
            params : params,
            file : file
        }
    })
    await handleQue()
}

async function handleResultOld(baseURL, route, params, file ) {
    return new Promise( (resolve , reject ) => {
        pushRequest(baseURL, route, params, file , resolve , reject)
    })
}

async function getResult(baseURL, route, params, file ) {
    let url = baseURL + route

    // Get Fresh Access token
    if ( params.headers.Authorization &&  String(params.headers.Authorization).includes('Bearer') && localStorage.getItem('access_token')) {
        params.headers.Authorization = "Bearer " + localStorage.getItem('access_token')
    }

    if ( !file ) {

        let out = await fetch(url,params ).then((resp) => resp.json() ).catch(() => {})
        if ( !out.result ) {
            if (out.error === "Expired token") {
                let result = await refreshToken(baseURL)
                if ( !result ) { return false }
                params.headers.Authorization = "Bearer " + result

                let out = await fetch(url,params ).then((resp) => resp.json() ).catch(() => {})
                    return out
            }
        }
        return out

    } else {
        let out = await fetch(url,params ).then((resp) => resp.blob()  ).catch(() => {})
        if ( out.type === 'application/json') {
            let data = JSON.parse( await out.text() );
            if ( !data.result ) {
                return false
            }
        }
        return out
    }
}

async function refreshToken(route) {
    route = route + 'user/refresh'
    let params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`},
    };
    let result = await fetch(route,params ).then((resp) => resp.json()).catch()
    result = await result ;
    if ( !result.result ) {
        // QUEUE = []
        return false
    }
    localStorage.setItem('access_token',result.data.access)
    localStorage.setItem('refresh_token',result.data.refresh)

    return result.data.access

}
function makeAjax( baseURL, i18n ) {
    return async function ajax({route, business, method, headers = '', body = ''}, file = null) {

        if ( !headers ) { headers = {} }

        if ( business ) {
            headers = {
                ...headers ,
                'Business': business
            }
        }

        if ( headers.Authorization !== 'public' ) {
            headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
        }

        const params = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Lang' : i18n.locale,
                ...headers},
        };


        if (body) {
            if ( body.fileFormData ) {

                let data = new FormData()
                data.append('file', body.fileFormData)

                if ( body.data ) {
                    Object.keys(body.data).forEach((paramName) => {
                        data.append(paramName,body.data[paramName])
                    })
                }

                delete params.headers['Content-Type']
                // params.headers['Content-Type'] = null
                params.body = data

            } else {

                body = JSON.stringify(body);
                body = body.replace(/’/gi, '\'');
                body = unescape(encodeURIComponent(body));
                params['body'] = JSON.stringify(window.btoa( body )); }

        }

        return await handleResultOld(baseURL , route, params , file);

    }

}

export default {
    makeAjax,
    // setStore,
    flushQueue
}


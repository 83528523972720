const actions = {

    // Managers (BusinessUser)

    async ADD_MANAGER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/manager/add`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true} );
    },

    async DELETE_MANAGER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/manager/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true} );
    },

    async GET_MANAGERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/managers`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true} );
    },

    async GET_MANAGERS_SELECT({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/select/managers`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true} );
    },

    async GET_MANAGER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/manager/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true} );
    },

    async SAVE_MANAGER_PERMISSIONS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/manager/permissions`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true} );
    },

    async EDIT_MANAGER_PERMISSIONS({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`business/${state.selectedBusiness.alias}/manager`, method:'POST',data:body});
    },

}

export default {
    namespaced:true,
    actions
}
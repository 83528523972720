export default {

    businessDashboard : [
        // Owner
        { path: "products" , component: () => import("@/modules/products/components/products/BusinessDashProducts")    , props : true },
        { path: "orders"   , component: ()     => import("@/modules/products/components/orders/BusinessDashOrders")     , props : true },
        // User
        { path: "viewProducts" , component:() => import("@/modules/products/components/businessUserProducts/BusinessUserProducts")        , props : true },
        { path: "rentProducts" , component:() => import("@/modules/products/components/businessUserRentProducts/BusinessUserRentProducts")     , props : true },
    ]
}



const actions = {

    async GET_CONVERTER_STATISTICS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/converter/statistics`,
                method:'GET',
            }, {root : true});
    },
    async DELETE_STATISTICS_RECORD({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`storage/converter/statistics/${body}`,
                method:'DELETE',
            }, {root : true});
    },

}

export default {
    namespaced:true,
    actions,
}
<template>

    <v-sheet
        :color="!editorOn ? $store.state.notification_color : '#00000000'"
        dark
        class="d-flex justify-space-between notification py-6"
        :style="notificationStyle"
        @mouseover="mouseOver"
        @mouseout="mouseOut"

    >
      <v-icon v-if="!editorOn" style="margin-right: 8px">{{ $store.state.notification_icon || 'mdi-information-outline' }}</v-icon>

      <div v-if="!editorOn"  style="max-width: 600px " >
        <h5 class="notranslate" style="margin-top: 2px; margin-right: 4px"> {{  text  }} </h5>
      </div>
      <v-icon  v-if="!editorOn" class="pointer"  @click="$store.state.notification = false"  >mdi-close</v-icon>

      <v-sheet
          v-if="editorOn"
          :color="$store.state.notification_color"
          :style="$store.state.avalon.sideMenu ? `margin-left : 280px` : null"
          style="padding : 8px 16px; border-radius: 8px"
      >

        <h5 class="notranslate" style="margin-right: auto; margin-top: 1px; margin-left: auto; padding-right: 4px">
          <v-icon  style="margin-right: 8px">{{ $store.state.notification_icon || 'mdi-information-outline' }}</v-icon>
          {{  text  }}
        </h5>
      </v-sheet>

    </v-sheet>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "wsNotification",
  props : {
    text : {
      type : String
    },
    top : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      pause : false
    }
  },
  computed : {
    ...mapState('avalon' , ['editorOn']),
    notificationStyle() {
      if ( this.editorOn ) {
        return 'top : 43px; padding : 8px; left : 0; right : 0` : top : 0; padding : 20px; left : 0; right : 0;   justify-content: center;'
      }
      return !this.top ? `justify-content: space-between;; bottom: 0; padding : 20px; left : 0; right : 0` : 'top : 0; padding : 20px; left : 0; right : 0;   '
    }
  },
  methods : {
    mouseOver() {
      if (!this.pause) {
        this.pause = true
      }
    },
    mouseOut() {
      this.pause = false
        setTimeout(() => {
          if ( !this.pause ) {
            this.$store.state.notification = false
          }
        },1000)

    }
  },
  mounted() {
    setTimeout(() => {
      if ( !this.pause ) {
        this.$store.state.notification = false
      }
    },2500)
  }
}
</script>

<style scoped>
.notification {
  position : fixed;
  display: flex;
  align-content: center;
  z-index: 2147483647;
}
</style>
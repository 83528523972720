const actions = {

    // Facebook
    async CLEAR_FACEBOOK_EVENTS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'social/facebook/clear_user_events',
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

}

export default {
    namespaced:true,
    actions,
}
<template>
  <div :style="width ? `width : ${width}` : ''">
    <h5  v-if="label"
         :style="labelStyle || `color : ${labelColor || '#4F5A6E' }; font-size: 13px; font-weight: 500 `"
    >
      {{  label  }}
    </h5>

    <v-sheet
        :style="!plain ? `border : 1px solid ${wsACCENT}; padding : ${FONT_SIZE/2}px 0` : ''"
        :color="backgroundColor"
        class="d-flex justify-space-between"
    >

      <!-- TEXT INPUT  -->
      <div   class="d-flex align-center" style="width: 100%">

        <!-- Placeholder  -->
        <h5 v-if="!text"
            style="position: absolute;pointer-events: none; line-height: 1 !important"
            :style="(fontStyle || `color : ${labelColor || '#4F5A6E' }; font-size: ${FONT_SIZE}px; font-weight: 500 `) + `;margin-left : ${FONT_SIZE/2}px;`"
        >
          {{ placeholder || $t('EnterText') }}
        </h5>

        <!-- Textfield -->
        <input
            @change="$emit('change' , text)"
            v-if="!textArea"
            style="width: 100%; outline: none;"
            :style="(fontStyle || `color : ${color || '#000000' }; font-size: ${FONT_SIZE}px; font-weight: ${weight || 500} `) + `;margin-left : ${FONT_SIZE/2}px;`"
            ref="text"
            v-model="text"
        />
        <!-- Textarea  -->
        <textarea
            @change="$emit('change' , text)"
            v-else
            style="width: 100%; outline: none; resize: none; "
            :style="fontStyle || `color : ${color || '#000000' }; font-size: ${FONT_SIZE}px; font-weight: ${weight || 500} ` + `;margin-left : ${FONT_SIZE/2}px;`"
            ref="textarea"
            v-model="text"
            class="textarea"
            :rows="rows"
        />

      </div>


      <!-- Actions -->
      <div class="d-flex"
           :class="[{'align-center' : !textArea || rows === 1 } , {'align-baseline' : textArea && rows > 1 }]"
           :style="`margin-right : ${FONT_SIZE/3 + 4}px;`" >
        <!-- Clearable Icon-->
        <v-icon v-if="text && clearable" @click="text = ''; $emit('clear')" class="pointer" :color="wsACCENT">mdi-close-circle</v-icon>

        <!-- Action Button text-->
        <h5 v-if="actionText"
            @click="$emit('action')"
            class="pointer ml-2"
            :style="fontStyle || `color : ${labelColor || '#4F5A6E' }; font-size: ${FONT_SIZE}px; font-weight: 500 ` + `;margin-left : ${FONT_SIZE/2}px;`"
        >
          {{ actionText }}
        </h5>

      </div>


    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "ws-text-field-new",
  props : {
    value : {
      type : String,
    },
    block : {
      type : Object,
    },
    configStyle : {
      type : String,
    },
    label : {
      type : String
    },
    placeholder : {
      type : String,
    },
    textArea : {
      type : Boolean,
      default : false
    },
    rows : {
      type : [String, Number],
      default : 1
    },
    actionText : {
      type : String
    },
    labelColor : {
      type : String,
    },
    labelStyle : {
      type : String,
    },
    plain : {
      type: Boolean,
      default : false
    },
    fontSize : {
      type: String,
      default : '12'
    },
    fontStyle : {},
    backgroundColor : {
      type : String,
      default : 'transparent'
    },
    clearable : {
      type : Boolean,
      default : false
    },
    width : {},
    color : {
      type : String,
      default : '#000000'
    },
    weight : {
      type : String,
      default : '500'
    },
    h3 : {
      type : Boolean,
      default : false
    },
    fullWidth : {
      type : Boolean,
      default : false
    },
    bold : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      text : ''
    }
  },
  computed:  {
    FONT_SIZE() {
      return (this.fontSize || '12')
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.textArea ) {
        this.adjustTextareaHeight()
      }
      if ( this.value !== this.text) {
        this.$emit('input' , this.text )
      }
    }
  },
  methods : {
    adjustTextareaHeight() {
      const input = this.$refs.textarea;
      input.style.height = "auto";
      if ( this.text ) {
        input.style.height = input.scrollHeight + "px";
      }

    },
    checkStyles() {
      if ( this.h3 ) {
        this.fontSize = '18'
        this.weight = 600
      }
      if ( this.fullWidth ) {
        this.width = '100%'
      }
      if ( this.bold ) {
        this.weight = 600
      }
    }
  },
  beforeMount() {
    this.checkStyles()
  },
  mounted() {

    if ( !this.block && !this.configStyle ) {
      this.configStyle = 'light_1'
    }
    if (this.value) {
      this.text = this.value
    }
  }
}
</script>

<style scoped>
.auto-growable-input {
  width: 100%;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>
const state = {
    requestsCounter : {
        new : 0,
        inProcess : 0,
        finished : 0
    },
    chatMessagesArray : [],
    hasNewMessage : false,
    navigationValue : null,
    notificationsArray : [],
    displayArchive : false,
    triggerSupportButton : 0,
    triggerSupportChat : 0,
    triggerSupportChatType : null,
}
const actions = {

    // Business Get Started

    async GET_STARTED({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/get_started`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    // CRM Functions

    async GET_BUSINESS_CRM_INFO({ dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/crm_info`,
                method: 'GET',
                business : body,
            }, { root : true});

    },

    async EDIT_BUSINESS_CRM_INFO({ dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/edit_info`,
                method: 'POST',
                business : body.alias,
                data : body
            }, { root : true});

    },

    async ADD_SALES_TASK({ dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/sales_task`,
                method: 'POST',
                business : body.alias,
                data : body
            }, { root : true});

    },


    // Business Requests
    async ADD_BUSINESS_REQUEST({ dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/request`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },

    async GET_BUSINESS_REQUEST({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/request/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async GET_BUSINESS_REQUESTS({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/requests`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async GET_SUPPORT_HOME({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/home`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async GET_BUSINESS_STATS({ dispatch,rootState , state } ) {

        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/stats`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

        if ( !result  ) {
            return false
        }
        result.support_requests.forEach(el => {
            state.chatMessagesArray.push({ issue : el.uuid })
            state.notificationsArray.push(el)
        })



        return true
    },

    // CHAT
    async ADD_COMMENT({ dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/business/comment`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },

    // Admin Requests

    async GET_REQUESTS_ADMIN({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/admin/requests`,
                method:'POST',
                data : body
            }, { root : true});

    },

    async GET_ADMIN_REQUEST({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/admin/request/${body}`,
                method:'GET'
            }, { root : true});
    },

    async COMPLETE_REQUEST({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/admin/complete_request`,
                method:'POST',
                data : body
            }, { root : true});
    },

    async UPDATE_REQUEST({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/admin/update_request`,
                method:'POST',
                data : body
            }, { root : true});
    },

    async GET_REQUESTS_STATISTICS({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/admin/requests/statistics`,
                method:'POST',
                data : body
            }, { root : true});

    },



    // UPDATES

    async GET_UPDATES({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/updates` + (body ? '/in_work' : ''),
                method:'GET',
            }, { root : true});
    },
    async GET_UPDATE({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/update/${body}`,
                method:'GET',
            }, { root : true});
    },
    async GET_UPDATE_ADMIN({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`support/update/admin/${body.uuid}/${body.lang}`,
                method:'GET',
            }, { root : true});
    },

    async GET_UPDATES_ADMIN({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`support/updates/admin`,
                method:'GET',
            }, {root:true });
    },
    async GET_UPDATES_IN_WORK_ADMIN({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`support/updates/in_work/admin`,
                method:'GET',
            }, {root:true });
    },

    async ADD_EDIT_UPDATE({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`support/update`,
                method:'PUT',
                data : body
            }, {root:true });
    },

    async PUBLISH_UPDATE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`support/update/publish/${body}`,
                method:'POST',
            }, {root:true });
    },

    // EVENTS HANDLING

    async SUPPORT_EVENT({state} , body ) {
        state.chatMessagesArray.unshift(body.data)
        state.hasNewMessage = true
        state.notificationsArray.unshift({
            uuid : body.data.issue,
            text : body.data.text,
            type : 'chat'
        })
    },


}

export default {
    namespaced:true,
    actions,
    state

}
const state = {
    displayWindow : false,
    component : 'dashboard'
}
const actions = {

    async CANCEL_ADMIN_WECLOME({dispatch,rootState}) {
        rootState.business.selectedBusiness.welcome_tour_finished = true
        return await dispatch('ajax/SEND_NEW',
            {
                route:`onboarding/welcome_tour`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            }, {root : true});
    },
    async CANCEL_CHATBOTS_WECLOME({dispatch,rootState}) {
        rootState.business.selectedBusiness.welcome_tour_finished = true
        return await dispatch('ajax/SEND_NEW',
            {
                route:`onboarding/chat_bots_main`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            }, {root : true});
    },
    async DISPLAY_ONBOARD_DIALOG({state}, body) {
        state.component = body
        state.displayWindow = true
    }

}
const mutations = {
    DISPLAY_ADMIN_WELCOME_WESTUDY_DASHBOARD(state) {
        state.component = 'AdminWelcomeWestudy_Dashboard'
        state.displayWindow = true
    },
    DISPLAY_ADMIN_WELCOME_WESTUDY_COURSES(state) {
        state.component = 'AdminWelcomeWestudy_Courses'
        state.displayWindow = true
    },

}

export default {
    namespaced:true,
    actions,
    mutations,
    state
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center"},[_c('div',{class:[{'px-2' : _vm.SM  }],staticStyle:{"max-width":"760px","width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"mx-0 px-0 mt-0 pt-0",attrs:{"cols":"12","md":6}},[(_vm.PREVIOUS_COMPONENT(_vm.componentUuid))?_c(_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted ? 'router-link' : 'span',{tag:"router-link",staticClass:"noUnderline",attrs:{"to":_vm.businessDashLink('courses/component/' + _vm.PREVIOUS_COMPONENT(_vm.componentUuid).uuid )}},[_c('v-sheet',{staticClass:"d-flex align-center ",class:[
                { 'pa-6' : !_vm.SM },
                { 'pa-3' : _vm.SM },
                {'wsRoundedLightLeft' : _vm.NEXT_COMPONENT(_vm.componentUuid) && !_vm.SM },
                {'wsRoundedHalf' : !_vm.NEXT_COMPONENT(_vm.componentUuid) || _vm.SM },
                {'pointer' : _vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted }
            ],style:(("border : 1px solid " + (_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsBACKGROUND : 'grey'))),attrs:{"width":"100%","v-ripple":_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted},on:{"click":_vm.scrollTop}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsACCENT : 'grey'}},[_vm._v("mdi-arrow-left")]),_c('div',[_c('h4',{style:(("color : " + (_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsACCENT : 'grey')))},[_vm._v(" "+_vm._s(_vm.$t('GoBack')))]),_c('h5',{staticClass:"cut-text",style:(("color : " + (_vm.PREVIOUS_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsDARKLIGHT : 'grey')))},[_vm._v(_vm._s(_vm.PREVIOUS_COMPONENT(_vm.componentUuid).name))])])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"mx-0 px-0 pt-0 ",attrs:{"cols":"12","md":6}},[(_vm.NEXT_COMPONENT(_vm.componentUuid))?_c(_vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted ? 'router-link' : 'span',{tag:"router-link",staticClass:"noUnderline",attrs:{"to":_vm.businessDashLink('courses/component/' + _vm.NEXT_COMPONENT(_vm.componentUuid).uuid )}},[_c('v-sheet',{staticClass:"d-flex justify-end align-center",class:[
                { 'pointer' : _vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted },
                { 'pa-6' : !_vm.SM },
                { 'pa-3' : _vm.SM },
                {'wsRoundedLightRight' : _vm.PREVIOUS_COMPONENT(_vm.componentUuid) && !_vm.SM },
                {'wsRoundedHalf' : !_vm.PREVIOUS_COMPONENT(_vm.componentUuid) || _vm.SM },
                {'pointer' : _vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted }
            ],style:(("border : 1px solid " + (_vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsATTENTION : 'grey') + " !important")),attrs:{"width":"100%","color":_vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted ? _vm.wsATTENTION : 'grey',"v-ripple":_vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted,"dark":""},on:{"click":_vm.scrollTop}},[_c('div',[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('Next')))]),_c('h5',{staticClass:"text-right cut-text"},[_vm._v(" "+_vm._s(_vm.NEXT_COMPONENT(_vm.componentUuid).name))])]),(_vm.NEXT_COMPONENT(_vm.componentUuid).is_permitted)?_c('v-icon',{staticClass:"ml-5"},[_vm._v("mdi-arrow-right")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    businessDashboard : [
        // { path: "structure/main" ,  component: () => import("@/modules/wsu_structure/components/DashStructure.vue") , props : true , children : [
        //         { path: "" ,  component: () => import("@/modules/wsu_structure/components/institutes") , props : true },
        //         { path: "description" ,  component: () => import("@/modules/wsu_structure/components/description") , props : true },
        //         { path: "institutes" ,  component: () => import("@/modules/wsu_structure/components/institutes") , props : true },
        //         { path: "faculties" ,  component: () => import("@/modules/wsu_structure/components/faculties") , props : true },
        //         { path: "departments" ,  component: () => import("@/modules/wsu_structure/components/departments") , props : true },
        //         { path: "specialties" ,  component: () => import("@/modules/wsu_structure/components/specialties") , props : true },
        //         { path: "disciplines" ,  component: () => import("@/modules/wsu_structure/components/disciplines") , props : true },
        //         { path: "teachers" ,  component: () => import("@/modules/wsu_structure/components/teachers") , props : true },
        //         { path: "groups" ,  component: () => import("@/modules/wsu_structure/components/groups") , props : true },
        //         { path: "workload" ,  component: () => import("@/modules/wsu_structure/components/workload/workload") , props : true },
        //     ]
        // },

        { path: "structure/main/:location?" ,  component: () => import("@/modules/wsu_structure/components/DashStructure.vue") , props : true},
        { path: "structure/:root/:uuid/:location?" ,  component: () => import("@/modules/wsu_structure/components/DashStructure.vue") , props : true},
    ],
    businessPublic : [
        { path: "education_program/:educationProgram" , component:() => import("@/modules/wsu_structure/components/public/defaultDesign/PublicEducationProgram.vue")  , props : true }
    ]
}



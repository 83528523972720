<template>
  <div>
    <div
        style="position:fixed; pointer-events: none; top : 0 ; bottom: 0 ; left: 0; right: 0;  z-index: 999"
        :style="`border: 6px solid ${wsATTENTION};`"
    > </div>
    <v-sheet
        style="position: fixed ; bottom : 0; right: 0 ; left: 0px; z-index: 999; opacity: 0.9"
        :color="wsATTENTION"
        dark
        :class="!SM ? 'd-flex' : null"
        class="pa-2 px-4 justify-space-between align-center">

      <div v-if="!SM" class="d-flex">
        <v-avatar class="mr-3" size="32" :color="wsLIGHTACCENT">
          <v-img
              transition="xxx"
              v-if="$store.state.auth.userImg" :src="$store.state.auth.userImg"></v-img>
          <v-icon v-else dark>mdi-account</v-icon>
        </v-avatar>
        <div>
          <h6> {{ $store.state.auth.userName }} </h6>
          <h6 class="font-weight-light"> {{ $store.state.auth.userEmail }} </h6>
        </div>
      </div>
      <div class=" justify-space-between" v-else>
        <h6 class="text-center"> {{ $store.state.auth.userName }} </h6>
        <h6 class="text-center font-weight-light"> {{ $store.state.auth.userEmail }} </h6>
      </div>
      <h6 v-if="!SM">{{ $t('SpectatorMode')}} </h6>
      <v-btn
          @click="exitSpectator"
          class="noCaps"
          :class="SM ? 'mt-1' : null"
          outlined
          :block="SM"

          x-small
      >
        {{ $t('ExitSpectator') }}
      </v-btn>
    </v-sheet>
  </div>


</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "specatorControls",
  methods : {
    ...mapActions('auth' , ['EXIT_SPECTATOR']),

    async exitSpectator() {
      let result = await this.EXIT_SPECTATOR()

      if ( !result ) {
        return
      }
      this.$router.push( this.$store.state.auth.spectatorReturnRoute )
      this.$store.state.auth.spectatorTrigger++
      this.notify(this.$t('SpectatorModeOff'))
    },

  }
}
</script>

<style scoped>

</style>
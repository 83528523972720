const actions = {

    async LINK_BUSINESS({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'mono/link',
                method:'PUT',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async UNLINK_BUSINESS({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'mono/unlink',
                method:'DELETE',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async GET_MONO_CHECKOUT_URL({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'mono/order/url',
                method:'POST',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );

    },

}

export default {
    namespaced:true,
    actions,
}
<template>

  <div class="d-flex align-center justify-space-between pl-n2">
    <h5 v-if="label"
        :class="[{'font-weight-regular' : light}]"
        :style="`color : ${color || wsDARKER} `"> {{ $t(label) }}</h5>
    <v-switch
        v-model="switchValue"
        style="margin-right: -10px !important"
        color="green lighten-1"
        background-color="white"
        class="mt-0"
        hide-details
        inset
    />
  </div>

</template>

<script>
export default {
  name: "aParameterSwitch",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    label : {
      type : String,
      default : ''
    },
    color : {
      type : String
    },
    light : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      switchValue : false,
    }
  },
  watch : {
    value() {
      if ( this.value !== this.switchValue ) {
        this.switchValue = this.value
      }
    },
    switchValue() {
      if ( this.value !== this.switchValue ) {
        this.$emit('input',this.switchValue)
      }
    }
  },
  mounted() {
    this.switchValue = this.value
  }
}
</script>

<style scoped>

</style>
const state = {
    recentEvents : [],
    newEvents : [],
    recentEventsCount : 0,
    event : '',
    eventEntity : '',
    updateDescription : null,
    displayUpdateDescription : false,
}
const actions = {

    // Chat Bots linking
    async GET_RECENT_EVENTS({dispatch,rootState,state}) {
        let result = await dispatch('ajax/SEND_NEW',
            {
                route:`events/recent`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});

        if ( result && result !== true ) {
            state.recentEvents = result.events
            state.recentEventsCount = result.count

            if ( result.update ) {
                state.updateDescription = result.update
                state.displayUpdateDescription = true
            }
        }

    },
    async GET_EVENTS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`events`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});

    },
    async READ_EVENTS({dispatch,rootState, state}, body) {

        if (state.recentEventsCount === 0 ) {
            return
        }

        let data = body

        if ( typeof body === 'string' ) {
            data = { type : body }
        }

        let result = await dispatch('ajax/SEND_NEW',
            {
                route:`events/read`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : data,
            }, {root : true});

        if ( result ) {
            return await dispatch('GET_RECENT_EVENTS')
        }

    },
    async DELETE_EVENTS({dispatch,rootState}, body) {

        let data = body

        if ( typeof body === 'string' ) {
            data = { type : body }
        }

        let result = await dispatch('ajax/SEND_NEW',
            {
                route:`events/delete`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : data,
            }, {root : true});

        if ( !body.noUpdate && result ) {
            return await dispatch('GET_RECENT_EVENTS')
        }

    },

    async HANDLE_NEW({state,dispatch}, body) {

        if ( body.type !== 'update_description') {
            state.event = body.type
            state.eventEntity = body.entity
            state.newEvents.push(body)
        }

        dispatch('GET_RECENT_EVENTS')
    }

}

export default {
    namespaced:true,
    state,
    actions,
}
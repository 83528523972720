const state = {
    chatMessages : [],
    activeChats : [],
    selectedChatId : null,
    selectedChat : {},
    selectedContactId : null,
    selectedContact : {},
    selectedManager : {},
    selectedManagerId : null,
    sidebarType : 'active_chats',
    messengerType : 'user',
    search : '',
    chatsDisabled : false,
    selectedMessageContext : '',

    //global
    newMessagesCount: 0,
    recentMessages: [],
    dashMessengerOpened : false,
    displayMessenger : false,
    // notifications
    newMessages : [],
    displayNewMessage : false
}
const mutations = {
    SET_MESSENGER_SIDEBAR_TYPE(state,payload) {
        state.search = ''
        state.sidebarType = payload
        if ( payload === 'active_chats' ) {
            state.selectedContactId = null
            state.selectedContact = {}
        } else {
            state.selectedChatId = null
            state.selectedChat   = {}
        }
    },
    FLUSH_MESSENGER(state) {
        state.chatMessages      =  []
        state.activeChats       =  []
        state.selectedChatId    =  null
        state.selectedChat      =  {}
        state.selectedContactId =  null
        state.selectedContact   =  {}
        state.sidebarType       =  ''
        state.messengerType     =  'user'
        state.search            =  ''
        state.chatsDisabled     =  false
    },
    PUSH_NEW_MESSAGE(state,message) {
        state.chatMessages.push(message)
    },
    DISPLAY_NOTIFICATION(state,message) {
        state.newMessages.push(message)
        state.displayNewMessage = true
    }
}
const actions = {

    // CHATS

    async GET_CHATS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/chats`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },
    async ADD_CHAT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/chat`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            },{ root : true });
    },
    async GET_CONTACT_BOOK({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/contacts`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },
    async GET_CHAT_MESSAGES({dispatch,rootState,state},body) {
        state.chatMessages = [];
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/chat/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },
    async GET_CHAT_USERS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/chat/${body}/users`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },
    async EDIT_CHAT({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/edit/chat`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            },{ root : true });
    },
    async DELETE_CHAT({dispatch,rootState,state},body) {
        state.chatMessages = [];
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/delete/chat/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            },{ root : true });
    },

    // MANAGERS

    async GET_MANAGERS_LIST({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/managers`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },
    async GET_MANAGER_CHATS({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/managers/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });
    },

    // MESSAGES

    async DELETE_MESSAGE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/message/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE'
            },{ root : true });
    },
    async SEND_MESSAGE({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/send`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            },{ root : true });
    },
    async SET_MESSAGE_AS_READ({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/is_read/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            },{ root : true });
    },
    async GET_RECENT_MESSAGES({dispatch,state,rootState,}) {

        let result =  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`messenger/stats`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },{ root : true });

        if ( result ) {
            state.newMessagesCount  =  result.new_messages_count
            state.recentMessages    =  result.recent_chats
        }
    },

    // SOCKET FUNCTIONS
    async HANDLE_NEW_MESSAGE({state,commit,dispatch},input) {
        let message = input.message
        let chatInput = input.chat

        if ( state.activeChats.length === 0 ) {

            state.newMessagesCount++;

            if ( state.sidebarType ) {
                chatInput.last_message = message.text.slice(0,15)
                chatInput.new_messages_count = 1
                state.activeChats.push(chatInput)
            }

            if ( !state.sidebarType ) {
                commit('DISPLAY_NOTIFICATION',input)
            }

            return
        }

        let chat = state.activeChats.find(el=>el.uuid === message.messenger_chat_id)
        if ( !chat ) {
            chatInput.last_message = message.text.slice(0,15)
            chatInput.new_messages_count = 1
            state.activeChats.push(chatInput)
            state.newMessagesCount++;
            return
        }

        chat.last_message = message.text.slice(0,15)


        if ( state.selectedChatId === message.messenger_chat_id) {
            await dispatch('SET_MESSAGE_AS_READ',message.uuid);
            return commit('PUSH_NEW_MESSAGE',message)
        }

        chat.new_messages_count ++;

        state.activeChats = state.activeChats.filter( el=> el !== chat )
        state.activeChats.unshift(chat)

        state.newMessagesCount++;



    },
    async HANDLE_EDIT_MESSAGE({state},input) {

        if (state.chatMessages.length === 0 ) {
            return
        }
        let message = input.message

        let index = state.chatMessages.findIndex(el => el.uuid === message.uuid )
        if ( index === -1 ) {
            return
        }
        state.chatMessages[index].text = message.text
        state.chatMessages[index].is_edited = true

    }

}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}
<template>
  <v-dialog  class="monserat wsRounded"  :width="width" v-model="display"
             persistent
             :fullscreen="SM || fullscreen" >
    <v-sheet :class="getTotalClass" class="wsRoundedHalf" >

      <v-row class="no-gutters flex-wrap flex-column fill-height">

        <!-- header-->
        <v-col cols="auto" class="shrink"  >
          <div v-if="!blank"
               :class="displayShow(['sm']) ? 'px-3 ' : '' "
               class="d-flex justify-space-between align-center pt-6 " >
            <div></div>
            <h3 v-if="!SM" class="font-weight-medium text-center">
              {{ title}}
            </h3>
            <h4 v-else class="font-weight-medium text-center" > {{ title }} </h4>
            <v-btn   icon @click="confirmClose" ><v-icon >mdi-close</v-icon></v-btn>
          </div>
        </v-col>

        <!-- main content -->
        <v-col cols="auto" class="grow  " :style="SM ? 'height: 100px' : '' " >
          <v-sheet class="fill-height  overflow-y-auto pb-6  px-6 " light
                   :class="!SM ? 'wsRoundedHalf' : ' wsRounded' " >

            <div v-if="!displayConfirmDelete" class="fill-height">
              <slot name="default"></slot>
            </div>
            <div v-else>
              <h4 class="text-center">{{ deleteConfirmationText }}</h4>
            </div>

          </v-sheet>
        </v-col>

        <!-- Buttons -->
        <v-col cols="auto" class="shrink py-3"
                :class="displayShow(['sm']) ? 'px-3' :''">


          <div v-if="displayConfirmDelete"  class="d-flex justify-end">
            <v-btn   @click="displayConfirmDelete = false" :disabled="loading" class=" noCaps" dark  :color="wsDARKER" >{{ $t('Back') }}</v-btn>
            <v-btn   @click="$emit('delete'); displayConfirmDelete = false"  :loading="loading" class=" noCaps" text >{{ $t('Yes') }}</v-btn>
          </div>

        </v-col>

      </v-row>

    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "wsDialog",
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    fullscreen : {
      type : Boolean,
      default : false
    },
    blank : {
      type : Boolean,
      default : false
    },
    hideButtons : {
      type : Boolean,
      default : false,
    },
    loading : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      default : '',
    },
    saveText : {
      type : String,
      default : 'Зберегти',
    },
    cancelText : {
      type : String,
      default : 'Cancel',
    },
    showSave : {
      type : Boolean,
      default : true,
    },
    showDelete : {
      type : Boolean,
      default : false,
    },
    cancelAction : {
      type : Boolean,
      default : false,
    },
    width : {
      type : String,
      default : '600',
    },
    noNavigation : {
      type : Boolean,
      default : false,
    },
    displayConfirmDelete : {
      type : Boolean,
      default : false,
    },
    confirmDeleteText: {
      type : String,
      default : null,
    }
  },
  data() {
    return {
      display : false,
      deleteConfirmationText : ''
    }
  },
  computed : {
    getTotalClass() {
      if ( this.displayShow(['sm'])) {
        return 'fill-height'
      }
      return !this.blank ? 'px-6' : 'pa-1'
    }
  },
  watch : {
    value(val,old) {
      if ( val !== old) {
        this.display = val
      }
    },
    display(val) {
      if (this.value !== val) {
        this.$emit('input',val)
      }
      if ( val === false ) {  this.$emit('close') }
    },
  },
  methods: {
    confirmClose() {
      this.displayConfirmDelete=true
    },
    closeDialog() {

      if ( this.cancelAction ) {
        this.$emit('cancel')
      } else {
        this.display = false
      }
    }
  },
  mounted() {
    this.display = this.value
    this.deleteConfirmationText = this.confirmDeleteText ? this.confirmDeleteText : this.$t('PleaseConfirmDeleteAction')
  }
}
</script>

<style scoped>

</style>
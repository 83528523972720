const requireModule = require.context('.', false,  /\.store\.js$/);
const modules = {};


const systemModules = require.context('@modules', true, /store\.js$/);

console.log(requireModule, systemModules)
requireModule.keys().forEach(filename => {

    const moduleName = filename
        .replace(/(\.\/|\.store\.js)/g, '')

    modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

systemModules.keys().forEach(filename => {
    console.log(filename);
    let moduleName = filename
        .replace(/(\.\/|\.store\.js)/g, '')

    moduleName = moduleName.split('/')[moduleName.split('/').length - 1];
    console.log('moduleName' , moduleName);

    modules[moduleName] = systemModules(filename).default || systemModules(filename);
});

export default modules;
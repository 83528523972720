const state = {
}

const actions = {

    // PROJECTS
    async GET_PROJECTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/projects`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_PROJECT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async GET_PROJECT_EDITOR({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_PROJECT_PARTICIPANTS({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/participants/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },


    // PAGES
    async GET_PROJECT_PAGE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/page/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_EDIT_PROJECT_PAGE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/page`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async ADD_EDIT_PROJECT_PAGE_TOPIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/page/topic`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async ADD_EDIT_PROJECT_ITEM({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/item`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_PROJECT_ITEM({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/project/item/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // USER

    async GET_USER_TASKS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`project-manager/user_tasks`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },




}

export default {
    namespaced:true,
    actions,
    state
}
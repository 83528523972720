<template>

  <v-sheet :width="width">

    <v-sheet class="d-flex justify-space-between">
      <v-sheet
            v-for="(color,key) in colors" :key="key" cols="2"
            height="35"
            v-ripple :style="isSelected(key) ? `border: 2px solid ${wsACCENT}` : ''"
            style="padding: 3px" class=" wsRoundedLight"
        >
          <v-sheet
              @click="$emit('input' , colorSet ? color : key ) ; displayPicker = false"
              height="30"
              width="30"
              class="wsRoundedLight pointer d-flex align-center justify-center fill-height"
              :elevation="!isSelected(key) ? 2 : 0"
              :color="color"
          >
          </v-sheet>
        </v-sheet>

      <v-sheet
          @click="displayPicker = true"
          height="35"
          v-ripple :style="isSelected(customColor) ? `border: 2px solid ${wsACCENT}` : ''"
          style="padding: 3px" class=" wsRoundedLight"
      >
        <v-sheet
            width="30"
            class="wsRoundedLight pointer d-flex align-center justify-center fill-height"
            :elevation="!isSelected(customColor) ? 2 : 0"
            :color="customColor"
        >
          <v-icon>mdi-eyedropper</v-icon>
        </v-sheet>
      </v-sheet>

    </v-sheet>

    <v-expand-transition>
      <div class="py-4" v-if="displayPicker">
        <div class="d-flex align-center justify-space-between mb-4">
          <h5>{{ $t('ChooseColor')}} </h5>
          <v-btn @click="displayPicker = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <ws-text-field
            @change="customColor = $event ; $emit('input',$event)"
            v-model="manualColor"
            icon="mdi-palette"
        >

        </ws-text-field>
        <v-color-picker
            v-model="customColor"
            @input="changeSliderColor"
            hide-inputs hide-mode-switch width="600" show-swatches    swatches-max-height="240"></v-color-picker>

      </div>
    </v-expand-transition>

  </v-sheet>

</template>

<script>
export default {
  name: "aColorPicker",
  props : {
    value : {
      type : String,
      default : '#ffffff'
    },
    width : {
      type : String,
      default : '250'
    },
    colorSet : {
      type : Array
    }
  },
  data() {
    return {
      timeOutId : '',
      sliderColor : '',
      manualColor : '',
      customColor : "#ffffff",
      displayPicker : false,
      mouseDown : false,
    }
  },
  computed : {
    colors() {
      return this.colorSet ? this.colorSet : this.avalonConfig.colors || {}
    }
  },
  methods : {
    changeSliderColor(color) {
      if ( this.timeOutId ) {
        clearTimeout(this.timeOutId)
      }
      this.timeOutId = setTimeout(() => {
        this.$emit('input',color); this.manualColor = color ;
      },200)
    },
    isSelected(color) {
      return color === this.value
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <portal to="dialog" :disabled="!portal" @mousemove="$store.state.avalon.mouseX = $event.clientX; $store.state.avalon.mouseY = $event.clientY;">
    <v-fade-transition>
      <v-sheet
          v-if="display"
          @click.stop="overlayClick"
          :color="overlay"
          style="position: fixed; top: 0; bottom: 0; left: 0;right: 0; z-index: 999999999999999 ;"
          :style="overlayStyle"
          class="d-flex align-center "
          :class="[
            { 'justify-center' : !fullscreenAlign },
            { 'justify-end'    : fullscreenAlign === 'right' },
            { 'justify-start'  : fullscreenAlign === 'left' },
            { 'align-center'   : verticalAlign === 'center' },
            { 'align-baseline' : verticalAlign === 'top' },
            { 'align-end'      : verticalAlign === 'bottom' }

        ]"
      >
        <v-sheet
            light
            @click.stop
            @mousedown="mouseDown"
            @mouseup="mouseUp"
            :color="backgroundColor"
            :width="dialogWidth"
            :class="[
              {'fill-height '  :   fullscreen || SM || fillHeight  },
          ]"
            :style="`max-height : ${maxHeight || '90%'}; ${height ? `height : ${height};` : ''} margin-right : ${marginRight || 0}; ${borderRadius ? `border-radius : ${borderRadius}px !important` : ''}`"

            class="justify-space-between overflow-hidden d-flex flex-column"
            elevation="3"


        >

          <!-- HEADER-->
          <!-- Title and Close Button-->
          <slot name="header">

            <div v-if="!blank"
                 class="d-flex justify-space-between align-center pb-3 px-6 pt-5" >
              <h3 :style="BLOCK_TEXT_CSS(block , blockStyle, 'dialog','title')">
                {{  title  }}
              </h3>
              <v-btn
                  @click="display = false"
                  class="mr-n2"
                  :color="BLOCK_COLOR_PARAM(block , 'dialog_title', this.blockStyle)"
                  icon
              >
                <v-icon :size="BLOCK_FONT_VARIABLE(block , 'size' , 'dialog', 'title')" >mdi-close</v-icon>
              </v-btn>
            </div>
          </slot>


          <!-- CONTENT-->
          <div style=" position: relative"
               :style="notCentered ? 'height: 100%;' :  'min-height: 35px;' "
               class="overflow-y-auto "
               ref="dialogContent"
               :class="[{'px-6' : !noPadding} , {'pb-8' : !noPadding}]"
          >
            <!-- Content-->
            <slot name="default"></slot>
          </div>


          <!-- BUTTONS -->
          <slot name="footer">
            <v-sheet v-if="!noNavigation" color="transparent" style="position:relative;" class="pt-3 pa-6" >
              <!-- Transparent Overlay for Overflow -->
              <v-sheet
                  style="position:absolute; left: 0; right: 0; top: -30px;"
                  :style="`background: linear-gradient(to bottom, transparent, ${backgroundColor} );`"
                  height="30"
              />
              <div v-if="!hideButtons"  class=" d-flex justify-end">
                <!-- Cancel button  -->
                <avalon-button
                    @click="cancelButtonAction"
                    :block="block"
                    :block-style="blockStyle"
                    :text="cancelText"
                    :disabled="loading"
                    button-style="secondary"
                    class="mr-2"
                />
                <avalon-button
                    @click="$emit('save')"
                    :block-style="blockStyle"
                    :block="block"
                    :text="saveText"
                    :disabled="disableSave"
                    :loading="loading"
                    class="mr-2"
                />

              </div>

            </v-sheet>
            <v-sheet color="transparent" height="1px" v-else />
          </slot>




        </v-sheet>

      </v-sheet>
    </v-fade-transition>
  </portal>


</template>

<script>


export default {
  name: "avalonDialog",
  props : {
    blockStyle : {
      type : String,
      default : 'light_1'
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
    value : {
      type : Boolean,
      default : false,
    },
    overlayColor : {
      type : String
    },
    noPadding : {
      type : Boolean,
      default : false
    },
    fullscreen : {
      type : Boolean,
      default : false
    },
    blank : {
      type : Boolean,
      default : false
    },
    hideButtons : {
      type : Boolean,
      default : false,
    },
    loading : {
      type : Boolean,
      default : false,
    },
    title : {
      type : String,
      default : '',
    },
    subtitle : {
      type : String,
      default : '',
    },
    saveText : {
      type : String,
      default : 'Зберегти',
    },
    cancelText : {
      type : String,
      default : 'Cancel',
    },
    showSave : {
      type : Boolean,
      default : true,
    },
    showDelete : {
      type : Boolean,
      default : false,
    },
    cancelAction : {
      type : Boolean,
      default : false,
    },
    width : {
      type : String,
      default : '600',
    },
    noNavigation : {
      type : Boolean,
      default : false,
    },
    displayConfirmDelete : {
      type : Boolean,
      default : false,
    },
    closeDelete : {
      type : Boolean,
      default : false,
    },
    confirmDeleteText: {
      type : String,
      default : null,
    },
    confirmDeleteCode: {
      type : String,
      default : null,
    },
    persistent : {
      type : Boolean,
      default : false,
    },
    fullscreenWidth : {
      type : String
    },
    fullscreenAlign : {
      type : String
    },
    verticalAlign : {
      type : String,
      default : 'center'
    },
    notCentered : {
      type : Boolean,
      default : false,
    },
    titleIcon : {
      type : String
    },
    portal : {
      type : Boolean,
      default : false,
    },
    marginRight : {
      type : String
    },
    marginBottom : {
      type : String
    },
    marginTop : {
      type : String
    },
    fillHeight : {
      type : Boolean,
      default : false,
    },
    borderRadius : {
      type : String
    },
    disableSave : {
      type : Boolean,
      default : false,
    },
    deselectBlindZoneX : {
      type : Number
    },
    deleteText : {
      type : String,
      default : "Delete"
    },
    hideSave : {
      type : Boolean,
      default : false
    },
    maxHeight : {
      type : String
    },
    height : {
      type : String
    }
  },

  data() {
    return {
      display : false,
      deleteConfirmationText : '',
      deleteConfirmationCode : null,
      deleteCodeError : null,
      isMouseDown : false,
      mouseX : 999990,
      mouseY : 999990,
    }
  },
  computed : {
    backgroundColor() {
      return this.BLOCK_COLOR_PARAM(this.block , 'dialog_background', this.blockStyle)
    },
    overlay() {
      return this.BLOCK_COLOR_PARAM(this.block , 'dialog_overlay', this.blockStyle) + '44'
    },
    overlayStyle() {
      let style = `padding-top : ${this.marginTop || 0}; padding-bottom : ${this.marginBottom || 0};`

      if ( this.deselectBlindZoneX && this.$store.state.avalon.mouseX < this.deselectBlindZoneX ) {
        style += 'pointer-events : none;'
      }

      return style

    },
    dialogWidth() {
      if ( this.fullscreen && this.fullscreenWidth ) {
        return this.fullscreenWidth
      }
      return !(this.fullscreen || this.SM)  ? this.width : '100%'
    },
    getTotalClass() {
      if ( this.displayShow(['sm'])) {
        return 'fill-height '
      }
      return !this.blank ? 'px-6' : 'pa-1'
    }
  },
  watch : {
    value(val) {
      if ( this.value !== this.display) {
        this.display = val
      }
    },
    display(val) {
      if ( !val ) {
        this.displayConfirmDelete = false
      }
      if (this.value !== this.display) {
        this.$emit('input',val)
      }
      if ( val === false ) {  this.$emit('close') }
    },
  },
  methods : {

    mouseDown() {
      this.isMouseDown = true;
    },
    mouseUp() {
      this.isMouseDown = false;
    },

    deleteAction() {

      if ( this.confirmDeleteCode && this.deleteConfirmationCode !== this.confirmDeleteCode) {
        this.deleteCodeError = this.$t('WrongDeleteConfirmationCode')
        return
      }

      this.$emit('delete');
    },
    cancelButtonAction() {
      if ( this.cancelAction )  {
        this.$emit('cancel')
        return
      }
      this.display = false
    },
    overlayClick($event) {

      if ( this.deselectBlindZoneX && $event.clientX < this.deselectBlindZoneX ) {
        return
      }

      const selection = window.getSelection();
      selection.removeAllRanges();
      if ( this.isMouseDown ) {
        this.isMouseDown = false
        return
      }

      if ( this.persistent ) {
        return
      }
      this.display = false
    }
  },
  beforeMount() {
    this.overlay = this.overlayColor || this.wsACCENT
    if ( this.overlayOpacity ) {
      this.overlay +=  this.overlayOpacity
    } else {
      this.overlay += "bc"
    }
    if ( this.overlayColor ) {
      this.overlay = this.overlayColor
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = JSON.parse(JSON.stringify(this.value))
    }
    this.deleteConfirmationText = this.confirmDeleteText ? this.confirmDeleteText : this.$t('PleaseConfirmDeleteAction')
    setTimeout(()=> {
      if ( this.display && this.$refs.dialogContent ) {
        this.$refs.dialogContent.scrollTop = 0
      }
    },10)
  }
}
</script>

<style scoped>

</style>
<template>
  <wsOnboardDialog v-model="$store.state.onboarding.displayWindow"
                   :width="step === 0 ? '650' : '1000'"
                   @save="next"
                   :title="title"
                   cancel-action
                   no-navigation
                   :confirm-delete-text="$t('ConfirmExitTour')"
                   @delete="cancelTour"
                   :save-text="$t('StartTour')">



    <div v-if="step===0">

      <v-sheet class="pt-3">
        <v-img class=" mx-auto" width="270"    src="@/assets/onboarding/сhatbots.jpeg" />
      </v-sheet>
      <h3 class="font-weight-medium text-center mt-6">
        {{ $t('ChatBotsInstructionTitle') }} !
      </h3>
      <h4 class="font-weight-light text-center mt-1 ">
        {{ $t('ChatBotsInstructionDescription') }}!
      </h4>

      <div class="d-flex justify-center">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('StartTour') }}</v-btn>
      </div>


    </div>

    <div v-if="step===1">

      <v-row class=" pt-9">
        <v-col>
          <v-img style="border-radius : 10px" class="mx-auto" width="300" height="195" src="@/assets/onboarding/chatbots_notifications.png" />
          <h3 class="text-center font-weight-medium mt-3">{{ $t('ChatBotNotification')}} </h3>
          <h5 class="font-weight-light mt-3" >Розсилка ситемних оповіщень, нагадувань, повідомлень, рахунків на оплату, запрошень на курси, новин, автоматичних повідомлень та інших, подій передбачених в функціоналі WeStudy:Online </h5>
          <h5 class="font-weight-light mt-3">Для отримання нотифікацій, потрібно підʼєднати обліковий запис користувача (студента, менеджера, адміністратора) до чат-бота</h5>
        </v-col>
        <v-col>

          <v-img style="border-radius : 10px" class="mx-auto" width="300" height="195" src="@/assets/onboarding/chatbots_constructor.png" />
          <h3 class="text-center font-weight-medium mt-3" >{{ $t('ChatBotInformation') }} </h3>
          <h5 class="font-weight-light mt-3">Чат-бот, як міні мобільний додаток до Вашого бізнесу. Доступ до інформації про ваші курси, стан навчання, система оплати, автоматичні консультації, онлайн спілкування із менеджерами </h5>
          <h5 class="font-weight-light mt-3">Побудуйте ідеальну систему для Ваших клієнтів, користуючись конструктором чат-бота</h5>
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('Next') }}</v-btn>
      </div>


    </div>

    <div v-if="step===2">
      <v-img class="wsRoundedHalf mx-auto" height="400" width="600"  src="@/assets/onboarding/chatbots_link.png" />
      <h5 class="font-weight-light mt-3 text-center">Для підʼєднання обілкового запису користувача, в чат-боті натисніть на кнопку Підʼєднати акаунт, після чого введіть код інтеграції обілкового запису</h5>
      <div class="d-flex justify-end">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('Next') }}</v-btn>
      </div>
    </div>
    <div v-if="step===3">
      <v-img class="wsRoundedHalf mx-auto"  height="400" width="600"  src="@/assets/onboarding/chatbots_link_key.png" />
      <h5 class="font-weight-light mt-3 text-center">Код інтеграції знаходиться біля вашої електронної адреси в особистому кабінеті</h5>

      <div class="d-flex justify-end">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('Next') }}</v-btn>
      </div>

    </div>


  </wsOnboardDialog>
</template>

<script>
import { ONBOARD_DASHBOARD }    from "@/onboardTours/AdminWelcomeWestudy/AdminWelcomeWestudy" ;
import wsOnboardDialog from "@/onboardTours/UI/wsOnboardDialog";
import {mapActions} from "vuex";
export default {
  name: "obAdminWelcomeDashboard",
  components : {
    wsOnboardDialog
  },
  data() {
    return {
      step : 0
    }
  },
  computed : {
    title() {
      if ( this.step === 1 ) { return this.$t('ChatBotsMainFunctions') }
      if ( this.step === 2 ) { return this.$t('ChatBotsLinking') }
      if ( this.step === 3 ) { return this.$t('ChatBotsLinking') }
      return null
    }
  },
  methods : {
    ...mapActions( 'onboarding', [ 'CANCEL_CHATBOTS_WECLOME' ] ),

    next() {
      this.step++;
      if ( this.step === 4 ) {
        this.CANCEL_CHATBOTS_WECLOME()
        this.$store.state.onboarding.displayWindow = false
      }
    },

    startOnboard() {
      this.$store.state.onboarding.displayWindow = false
      ONBOARD_DASHBOARD.start(this)
    },
    cancelTour() {
      this.CANCEL_CHATBOTS_WECLOME()
      this.$store.state.onboarding.displayWindow = false
    }
  }
}
</script>

<style scoped>

</style>
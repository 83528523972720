const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },



    async GET_PRODUCTS_ADMIN({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`products/admin`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            },{ root : true });
    },
    async ADD_EDIT_PRODUCT({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`product`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            },{ root : true });
    },



    async GET_ALL({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`options/product/${body}`, method:'GET'});
    },
    async GET_PRODUCT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`product/${body}`, method:'GET'});
    },
    async EDIT_PRODUCT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`product/${body.id}`, method:'PUT', data : body.data});
    },
    async DELETE_PRODUCT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`product`, method:'DELETE', data : body});
    },
    async ADD_CATEGORY({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`category`, method:'POST',data: body});
    },
    async EDIT_CATEGORY({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`category/${body.id}`, method:'PUT', data : body.data});
    },
    async DELETE_CATEGORY({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`category/${body}`, method:'DELETE'});
    },


}

export default {
    namespaced:true,
    actions,
}
const state = {

    warehouses           : [],
    selectedWarehouse    : {}


}
const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            return data


        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async GET_WAREHOUSES({dispatch,rootState}) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouses`,
                method:'GET'
            });
    },
    async GET_WAREHOUSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/${body}`,
                method:'GET'
            });
    },
    async GET_WAREHOUSE_MANAGERS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/${body}/managers`,
                method:'GET'
            });
    },


    async FULFILL_WAREHOUSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/${body.id}/fulfill`,
                method:'POST',
                data : body.data
            });
    },
    async EDIT_RECORD_CONFIRMATION({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouseRecord/confirmation`,
                method:'POST',
                data : body
            });
    },



    async ADD_WAREHOUSE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse`,
                method:'PUT',
                data : body
            });
    },
    async ADD_WAREHOUSE_MANAGER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/${body.warehouse}/manager`,
                method:'PUT',
                data : {user : body.user}
            });
    },
    async DELETE_WAREHOUSE_MANAGER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/${body.warehouse}/manager`,
                method:'DELETE',
                data : {user : body.user}

            });
    },

    async GET_GOOGLE_LOCATIONS({dispatch,rootState}) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouses/googleLocations`,
                method:'GET'
            });
    },
    async CHECK_LOCATION_WAREHOUSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouses/googleLocation/check`,
                method:'POST',
                data : {data : body}
            });
    },

    async SAVE_GOOGLE_LOCATIONS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`${rootState.business.selectedBusiness.alias}/warehouse/googleLocation`,
                method:'POST',
                data : { data : body }
            });
    },





}

export default {
    namespaced:true,
    actions,
    state
}
const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            return data


        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async ADD_EDIT_CATEGORY({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/category`,
                method:'PUT',
                data : body
            },{ root : true });
    },
    async GET_CATEGORIES({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/categories`,
                method:'POST',
                data : body
            },{ root : true });
    },
    async GET_CATEGORIES_PUBLIC({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/public/${body}`,
                method:'GET',
            },{ root : true });
    },

    async ADD_TOPIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/categories/${body.category}/topic/${body.lang}`,
                method:'PUT'
            },{ root : true});
    },
    async EDIT_TOPIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/categories/topic`,
                method:'POST',
                data : body,
            },{ root : true});
    },
    async GET_TOPIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/topic/${body.uuid}/${body.lang}`,
                method:'GET'
            },{ root : true});
    },
    async GET_TOPIC_PUBLIC({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`documentation/public/topic/${body}`,
                method:'GET'
            },{ root : true});
    },






    async GET_TOPICS({dispatch,rootState},body = 'fine') {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`documentation/edit/${body}`,
                method:'GET'
            });
    },
    async GET_TOPICS_PUBLIC({dispatch,rootState},body = 'fine') {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`documentation/${body}`,
                method:'GET'
            });
    },


    async SAVE_ALL({dispatch,rootState}, body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`documentation`,
                method:'POST',
                data : body
            });
    },









}

export default {
    namespaced:true,
    actions,
}
const state = {
    display : false,
    component : {},
    src : '',
    title : ''
}

const actions = {

}

export default {
    namespaced:true,
    actions,
    state

}

export default function makeAjaxCustom() {

        return async function ajax_custom(route, params, blob = false ) {
            console.log(route)
            console.log(params)
            if ( !blob ) {
                return await fetch(route,params).then((resp) => resp.json()  ).catch((error) => {
                    console.log(error)
                })
            } else {
                return await fetch(route,params).then((resp) => resp.blob()  ).catch((error) => {
                    console.log(error)
                })
            }

        }
    }


<template>
  <v-img
      v-if="!title && !titleRaw"
      :height="height" :max-width="height"  src="/library/img/westudy_logo_small.png" />
  <div v-else class="d-flex align-center">
    <v-img
        :class="[{'mr-4' : noDivider}]"
        :height="height" :width="height" :max-width="height"  src="/library/img/westudy_logo_small.png" />
    <v-divider v-if="!noDivider" style="border-width: 1px; margin: 0 10px" :style="`border-color: ${wsDARKLIGHT}`"  vertical></v-divider>
    <h4 :style="fontStyle" >{{ title ? $t(title) : titleRaw }}</h4>
  </div>
</template>

<script>
export default {
  name: "wsLogo",
  props : {
    height : {
      type : String,
      default : '32'
    },
    title : {
      type : String
    },
    titleRaw : {
      type : String
    },
    color : {
      type : String
    },
    noDivider : {
      type : Boolean,
      default : false
    },
    fontSize : {
      type : String
    }
  },
  computed : {
    fontStyle() {
      let style = `color : ${this.color || this.wsACCENT};`
      if (this.fontSize) {
        style += `font-size : ${this.fontSize}px;`
      }
      return style
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-hover v-model="hover">
    <v-sheet
        @click="action"
        :class="[{pointer : !disabled}]"
        :v-ripple="!disabled"
        class="d-flex justify-center align-center mr-2"
        :style="iconStyle"
        width="50"
        height="50"
    >
      <v-icon :color="iconColor"
      >
        {{ icon }}
      </v-icon>
    </v-sheet>
  </v-hover>

</template>

<script>
export default {
  name: "avalonIcon",
  props : {
    block : {
      type : Object,
      default() {return{config:{}}}
    },
    disabled : {
      type : Boolean,
      default : false
    },
    icon : {
      type : String
    },
    outlined : {
      type : Boolean,
      default : false
    },

  },
  data() {
    return {
      hover : false
    }
  },
  computed : {
    iconStyle() {
      let style = ''
      style += 'border-radius : 50%;'
      if ( !this.outlined) {
        style += `background: ${this.iconBgColor};`
      } else {
        style += `border: 1px solid ${this.iconBgColor};`
        style += `background: transparent;`
      }

      return style
    },
    iconBgColor() {
      let color = this.BLOCK_COLOR_PARAM(this.block ,  'ux_arrow_bg', this.block.config.style)
      if ( this.disabled || this.hover) {
        color += '66'
      }
      return color
    },

    iconColor() {
      let color = this.BLOCK_COLOR_PARAM(this.block ,  'ux_arrow', this.block.config.style) +(this.disabled ? '66' : '')
      if ( this.outlined) {
        color = this.iconBgColor
      }
      return color
    }
  },
  methods : {
    action() {
      if ( this.disabled) {
        return
      }
      this.$emit('click')
    }
  }

}
</script>



<style scoped>

</style>
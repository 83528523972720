import {mapActions,mapState} from "vuex";

export default {
    data() {
        return {
            EVENTS_TRIGGER : {
                ALL : 1,
                BUSINESS_LEAD : 1,
                BUSINESS_USER : 1,
                STUDENT_REPLY_FILE : 1,
                ORDER_COURSE : 1,
                ORDER_COURSE_PAYMENT : 1
            }
        }
    },
    computed : {
        ...mapState('events', ['event','recentEventsCount']),
        EVENT() {
            return this.event
        },
        EVENT_ENTITY() {
            return this.$store.state.events.eventEntity ?? ''
        }
    },
    watch : {
        async recentEventsCount(value,old) {
            this.EVENTS_TRIGGER.ALL++
            if ( value > old && this.EVENTS_TRIGGER[this.event.toUpperCase()]) {
                this.EVENTS_TRIGGER[this.event.toUpperCase()]++
            }

        },
    },
    methods : {
        ...mapActions('events', ['READ_EVENTS']),

        getEventIcon(type) {
            switch(type) {
                case 'business_user' : return 'mdi-account-school'
                case 'business_lead' : return 'mdi-card-account-phone-outline'
                case 'student_reply_file' : return 'mdi-file'
                case 'student_comment' : return 'mdi-forum'
                case 'order_course' : return 'mdi-text-box-check-outline'
                case 'order_course_payment' : return 'mdi-account-cash'
                case 'student_evaluation_success' : return 'mdi-content-paste'
                case 'student_teacher_reply' : return 'mdi-chat'
                case 'new_course_published' : return 'mdi-school-outline'
                default : return 'mdi-information-variant'
            }
        }


    }
}


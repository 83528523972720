const actions = {


    // LEADS
    async ADD_FAQ_ITEM({dispatch,rootState},body) {

        body.project = rootState.project
        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/item`,
                method:'PUT',
                data : body
            }, {root:true });
    },
    async EDIT_FAQ_ITEM({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/item`,
                method:'POST',
                data : body
            }, {root:true });
    },
    async GET_FAQ_ITEM({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/item/${body}`,
                method:'GET',
            }, {root:true });
    },
    async GET_FAQ_ITEMS({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/items/${body}`,
                method:'GET'
            }, {root:true });
    },
    async GET_FAQ_ITEM_ADMIN({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/item/${body}/admin`,
                method:'GET',
            }, {root:true });
    },
    async GET_FAQ_ITEMS_ADMIN({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`faq/items/${body}/admin`,
                method:'GET'
            },{ root:true });
    },

    // Technical


}

export default {
    namespaced:true,
    actions,

}
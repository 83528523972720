import VueSocketIO from "vue-socket.io";

export default function makeSocket( store, wss ) {

    return new VueSocketIO({

        debug: true,
        connection: wss,
        protocol : 'echo-protocol',
        token : 'aw0*^%9de4_329&#52p',
        vuex: {
            store,
            actionPrefix: 'SOCKET_',
            mutationPrefix: 'SOCKET_'
        }
    })

}
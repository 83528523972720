const state = {

    socialsAuthUrlGoogle : null,
    socialsAuthUrlFacebook : null,

    isSpectator : false,
    spectatorTrigger : 0,
    spectatorReturnRoute : '',
    accessToken : '',
    refreshToken : '',
    resendToken : '',
    loggedIn : false,
    proceedUser : {},
    proceedAuthSuccessTrigger : 0,
    facebookPixelTrigger : 0,



    showNewUserDialog : false,

    modalAuth : false,
    modalType : '',

    userType : null,
    userID : null,
    user : {
        businesses : [],
        managingBusinesses : [],
        locations  : [],
        roles : []
    },
    userLang : null,
    userRentedProducts : null,
    userEmail: '',
    userName : '',
    userBusinesses : [],
    userWarehouses : [],
    userManagingBusinesses : [],
    businessManagerPermissions : {},
    userLinkKey : '',
    userImg : '',
    userRoles : [],

    changePasswordSent : false,
    showChangePansword : false,
    changePasswordMailReady: false,
    changePasswordMailResult: false,

    rootPlace : '',

    registerTimeoutOn : false,

    mainDashboardRequest : false,

}
const getters = {
    isLoggedIn(state) {
        return state.loggedIn
    },
}
const mutations = {
    TOOGLE_LOADER(state) {
        state.loader = !state.loader;
    },
    SET_TOKEN(state,data) {

        state.loggedIn = true;

        state.accessToken = data.access
        state.refreshToken = data.refresh

        localStorage.setItem('access_token',data.access)
        localStorage.setItem('refresh_token',data.refresh)

    },
    SET_RESEND_TOKEN(state,payload) {
        state.resendToken = payload.token
    },

    TOGGLE_AUTH_MODAL(state) {
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRMAION(state) {
        state.modalType = 'AUTH_REGISTER_CONFIRM'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_CONFIRM_INFO(state) {
        state.modalType = 'AUTH_CONFIRM_INFO'
        state.modalAuth = !state.modalAuth;
    },
    SHOW_MODAL_PASSWORD_RESET(state) {
        state.modalType = 'AUTH_PASSWORD_RESET'
        state.modalAuth = !state.modalAuth;
    },
    SET_EMAIL_RESULT(state,payload) {
        state.changePasswordMailResult = payload.result;
    },
    TOGGLE_EMAIL_RESULT_READY(state) {
        state.changePasswordMailReady = !state.changePasswordMailReady
    },
    FLUSH_CHANGE_PASSWORD(state) {
        state.changePasswordSent = false;
        state.showChangePansword = false;
        state.changePasswordMailReady= false;
        state.changePasswordMailResult= false;
        state.loader = false;
    },
    LOGOUT(state)  {

        this.dispatch('auth/UNSUBSCRIBE_SOCKET')

        state.userID       = null;
        state.authToken    = null;
        state.refreshToken = null;
        state.userEmail    = null;
        state.userName     = null;
        state.loggedIn     = false;
        state.userEmail    = ''
        state.userLinkKey  = ''
        state.userLang     = null
        state.userType     = null
        state.userID       = null
        state.userRentedProducts     = null
        state.userBusinesses         = []
        state.userWarehouses         = []
        state.userManagingBusinesses = []
        state.businessManagerPermissions = {}
        state.userImg = ''

   //     this.dispatch('cart/CLEAR_CART', null, {root:true})
   //      this.dispatch('business/RESET_BUSINESS_STATE')

        state.user = {
            businesses : [],
            managingBusinesses : [],
            locations  : []
        }
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');



    }
}
const actions = {

    UNSUBSCRIBE_SOCKET({rootState,state}) {
        rootState.$socket.emit('unsubscribe',{
            id         : state.userID,
            businesses : state.userBusinesses,
            warehouses : state.userWarehouses,
            subscriptions : []
        })
    },

    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },

    async LOGIN({commit,dispatch,rootState}, body) {

        let data = await dispatch('ajax/SEND_NEW',{
            token:'public' ,
            route:`user/auth`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        },{ root : true }
        );

        if ( !data ) { return }

        commit('SET_TOKEN',data)
        return await dispatch('GET_USER', data.access )

    },

    async ADD_PHONE_NUMBER({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',{
                route:`user/phone`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true }
        );
    },

    async LOGIN_FROM_CODE({commit,dispatch}, body) {

        let data = await dispatch('ajax/SEND_NEW',{
            token:'public' ,
            route:`user/auth/${body}`,
            method:'POST',
        },{root : true});

        if ( !data ) { return }

        commit('SET_TOKEN',data)
        return await dispatch('GET_USER', data.access )

    },

    async LOGIN_AS_USER({ commit,dispatch, rootState} , body ) {

        let result =  await dispatch('ajax/SEND_NEW' ,
            {
                route:`user/control/${body}`,
                business : rootState.business.selectedBusiness.alias || null,
                method: 'POST',
            }, {root : true});

        if ( !result ) {
            return
        }
        commit('SET_TOKEN',result)
        await dispatch('GET_USER', result.access )
    },

    async EXIT_SPECTATOR({dispatch, commit , rootState}  ) {

        let result =  await dispatch('ajax/SEND_NEW' ,
            {
                route:`user/control`,
                business : rootState.business.selectedBusiness.alias || null,
                method: 'DELETE',
            }, {root : true});

        if ( !result ) {
            return false
        }
        commit('SET_TOKEN',result)
        await dispatch('GET_USER', result.access )
        return true
    },

    async GET_SOCIALS_AUTH_URL({ dispatch, rootState , state} , body  ) {

        let result =  await dispatch('ajax/SEND_NEW' ,
            {
                route:`social/auth/url`,
                business : rootState.business.selectedBusiness.alias || null,
                method: 'POST',
                data : body
            }, {root : true});
        if ( !result ) {
            return
        }
        state.socialsAuthUrlGoogle = result.google
        state.socialsAuthUrlFacebook = result.facebook

    },

    async REGISTER({commit,dispatch,rootState}, body) {

        if ( rootState.referral.businessReferral ) {
            body.referral = rootState.referral.businessReferral
        }
        let data = await dispatch('ajax/SEND_NEW',{
            token:'public' ,
            route:`user/register`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        },{root : true}
        );

        if ( !data ) {
            if (rootState.ajax.error.includes('60001')) {
                state.registerTimeoutOn = true
                setTimeout(()=> {
                    state.registerTimeoutOn = false
                },45000)
                return 'timeout'
            }
            return false
        }

        commit('SET_TOKEN',data)
        return await dispatch('GET_USER', data.access )


    },

    async CHECK_EMAIL({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',{
            token:'public' ,
            route:`user/auth/register/check`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        }, {root : true }
        );
    },

    async GET_USER({dispatch,rootState},token) {

        let data = await dispatch('ajax/SEND_NEW',{
            token: token ,
            route:`user`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias
        },{ root : true }
        );

        if ( !data ) { return false}
        dispatch('SET_USER', data );

        return true

    },

    async REQUEST_PASSWORD({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',{
            route:`user/reset`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body

        },{ root: true });

    },
    async REQUEST_CHANGE_CONFIRM({commit,rootState},body) {
        commit('TOOGLE_LOADER')
        try {
            const data = await rootState.$ajax({
                route: 'auth/change/code',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('TOOGLE_LOADER');
                return data
            } else {
                commit('TOOGLE_LOADER');
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            this.$store.commit('TOOGLE_NOTIFICATION',{message:'Server Error'})
            return false
        }
    },
    async CHANGE_PASSWORD({dispatch,rootState,commit},body) {

        let data =  await dispatch('ajax/SEND_NEW',{
            route:`user/reset/confirm`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        },{ root: true });

         if ( !data ) { return false }
         commit('SET_TOKEN',data)
         return await dispatch('GET_USER', data.access )

    },
    async RESEND_CONFIRMATION({commit,rootState},body) {
        commit('TOOGLE_LOADER')
        try {
            const data = await rootState.$ajax({
                route: 'auth/confirm/resend',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('TOOGLE_LOADER');
                return data
            } else {
                commit('TOOGLE_LOADER');
                return data
            }
        } catch (e) {
            commit('TOOGLE_LOADER');
            this.$store.commit('TOOGLE_NOTIFICATION',{message:'Server Error'})
            return false
        }
    },
    async CONFIRM_ACCOUNT({dispatch,commit,rootState},body) {
        try {
            const data = await rootState.$ajax({
                route: 'auth/confirm',
                method: 'POST',
                body: body
            });
            if (data.result) {
                commit('SET_TOKEN',{token:data.access_token})
                state.loggedIn = true;
                localStorage.setItem('access_token',data.access_token)
                dispatch('GET_USER',data.access_token);
                return data
            } else {
                return data
            }
        } catch (e) {
            return false
        }
    },

    async UPLOAD_USER_IMAGE({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',{
            route:`user/image`,
            method:'POST',
            data: {
                fileFormData : body.file
            } },
            {root:true});
    },



    SET_USER({state,rootState},data) {

        state.proceedUser = {}
        state.isSpectator = data.spectator || false
        state.userID         = data.id;
        state.userEmail      = data.email;
        state.userName       = data.lastname+' '+data.firstname;
        state.userType       = 'new';
        state.user           = data
        state.userBusinesses = []
        state.userWarehouses = data.warehouses
        state.userImg = data.img
        state.userLinkKey = data.link_key
        state.userLang = data.language || null
        state.userRoles = data.roles || []

        if ( data.rent_product ) {
            state.userRentedProducts = data.rent_product
        }

        if (data.businesses) {
            data.businesses.forEach((business)=>{
                state.userBusinesses.push(business.alias)
            })
        }
        if (data.managing_businesses) {
            data.managing_businesses.forEach((business)=>{
                state.businessManagerPermissions[business.alias] = JSON.parse(JSON.stringify(business.permissions))
                state.userManagingBusinesses.push(business.alias)
            })
        }

        if ( data.roles.includes('ROLE_USER') )        { state.userType = 'user' }
        if ( data.roles.includes('BUSINESS_MANAGER') ) { state.userType = 'manager' }
        if ( data.roles.includes('ROLE_BUSINESSMAN') ) { state.userType = 'businessman' }
        if ( data.roles.includes('ROLE_SUPERADMIN') )  { state.userType = 'superadmin' }


        rootState.$socket.emit('subscribe',{
            id         : data.id,
            businesses : state.userBusinesses,
            warehouses : state.userWarehouses,
            subscriptions : []
        })
        rootState.socket.SOCKET_USER_SUBSCRIBED = true;

        if ( rootState.business.selectedBusiness.alias ) {

            rootState.dashboard.showBusinessToOwner   =
                rootState.auth.userBusinesses.includes(rootState.business.selectedBusiness.alias)
            rootState.dashboard.showBusinessToManager =
                rootState.auth.userManagingBusinesses.includes(rootState.business.selectedBusiness.alias)

        }

        if (data.fbq && data.fbq.length > 0) {
            state.facebookPixelTrigger++
        }

        state.loggedIn = true;


    },


    // Proceed As

    async GET_PROCEED_USER({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',{
                token:'public' ,
                route:`user/get_proceed_data`,
                method:'POST',
                data : body
            },{ root : true }
        );

    },

    async PROCEED_AS({dispatch,rootState, state, commit},body) {

        if ( body.main_dashboard) {
            state.mainDashboardRequest = true
        }

        let data = await dispatch('ajax/SEND_NEW',{
                token:'public' ,
                route:`user/proceed_as`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true }
        );

        if ( !data ) { return }

        commit('SET_TOKEN',data)
        state.mainDashboardRequest = false
        return await dispatch('GET_USER', data.access )

    },


    // Verification

    async VERIFY_EMAIL({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',{
                token:'public' ,
                route:`user/auth/verify`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true }
        );
    },

    async GET_VERIFICATION_CODE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',{
                token: body ? 'public' : null ,
                route:`user/auth/verify/resend`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : { uuid : body }
            }, {root : true }
        );
    },

    async UNITE_ACCOUNTS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',{
                route:`user/auth/unite`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true }
        );
    },





}
export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}
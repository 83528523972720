export default {
    methods : {
        moduleIsActive(moduleName) {
            let module = this.$store.state.business.selectedBusiness.modules.find(el=>el.name === moduleName)
            if (module) { return module.active }
            return false
        },

        modulePermission(module, permission) {
            if ( this.$store.state.business.selectedBusiness.userPermissions ) {
                if ( this.$store.state.business.selectedBusiness.userPermissions.modulePermissionsObject ) {
                    if ( this.$store.state.business.selectedBusiness.userPermissions.modulePermissionsObject[module] ) {
                        if ( this.$store.state.business.selectedBusiness.userPermissions.modulePermissionsObject[module][permission] ) {
                            return this.$store.state.business.selectedBusiness.userPermissions.modulePermissionsObject[module][permission].value
                        }
                    }
                }

            }

            return false
        }

    }
}
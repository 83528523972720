export default {

    businessDashboard : [
        { path: "fine_mailer/letters"          , component:() => import("@/modules/finemailer/components/campaigns/MailerLetters.vue")       , props : true },
        { path: "fine_mailer/campaigns"        , component:() => import("@/modules/finemailer/components/campaigns/MailerCampaigns.vue") , props : true },
        { path: "fine_mailer/contacts"         , component:() => import("@/modules/finemailer/components/MailerContacts.vue")                , props : true },
        { path: "fine_mailer/templates"        , component:() => import("@/modules/finemailer/components/MailerTemplates.vue")           , props : true },
        { path: "fine_mailer/settings"         , component:() => import("@/modules/finemailer/components/MailerSettings.vue")            , props : true },
        { path: "fine_mailer/view/:uuid"       , component:() => import("@/modules/finemailer/components/campaigns/ViewMailDetails.vue") , props : true },
    ]
}



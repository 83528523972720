const state = {
}

const actions = {

    // PROJECTS
    async GET_ACHIEVEMENTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`gamification/achievements`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_ACHIEVEMENT({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`gamification/achievement`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_ACHIEVEMENT({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`gamification/achievement/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // STUDENT
    async GET_STUDENT_ACHIEVEMENTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`gamification/student/achievements`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
}

export default {
    namespaced:true,
    actions,
    state
}
const state = {
    navigation : [],
    pageData : {},
    filtersData : {}
}
const actions = {

    async ADD_EDIT_DISCOUNT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'discount',
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },
    async DELETE_DISCOUNT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`discount/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },



    async GET_BUSINESS_DISCOUNTS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'discount/all/permanent',
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async GET_BUSINESS_PROMOCODES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'discount/all/promocodes',
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },


}

export default {
    namespaced:true,
    state,
    actions,
}
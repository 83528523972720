const state = {
    sideMenu : true,
    sideMenuMobile : false,
    pageTitle : '',
    breadcrumbs : [],
    modalDash : false,
    modalType : '',
    messages : 0,
    notifications : 0,
    newMessage : null,
    lastMessages : [],

    showLogoutConfirm : false,

    showBusinessToOwner   : false,
    showBusinessToManager : false,

    UPLOAD_LOADING : false,

    displayPageNavigation : false,
    pageNavigationRoot : '',
    pageNavigationReturnRoute : '',
    pageNavigationItems : [],
    pageNavigation : '',

    pageData : {},
    displayPaymentWarning : false,

    serverSearchTrigger : 0,
}
const mutations = {
    TOGGLE_SIDEBAR(state) {
        state.sideMenu = !state.sideMenu
    },
    SET_PAGE_TITLE(state,payload) {
        state.pageTitle = payload;
    },
    SET_BREADCRUMBS(state,payload) {
        state.breadcrumbs = payload;
    },
    TOGGLE_DASH_MODAL(state) {
        state.modalDash = !state.modalDash;
        if (!state.modalDash) {
            state.modalType = '' ;
        }
    },
    SHOW_MODAL_CONFIRM_LOGOUT(state) {
        state.showLogoutConfirm = true
    },
    SHOW_MODAL_CHANGE_AVATAR(state) {
        state.modalType = 'CHANGE_AVATAR'
        state.modalDash = !state.modalDash;
    },
    NEW_MESSAGE_COUNT(state) {
        state.messages++
    },
    SET_MESSAGE_COUNT(state,payload) {
        state.messages = payload
    },
    SET_NEW_MESSAGE(state,payload) {
        state.newMessage = payload
    },
    SET_LAST_MESSAGES(state,payload) {
        state.lastMessages = payload
    },
    ADD_LAST_MESSAGE(state,payload) {
        state.lastMessages.unshift(payload)
    },
    DELETE_UNREAD_MESSAGE(state,payload) {
        state.messages = payload.count
        state.lastMessages = state.lastMessages.filter(el=>el.author !== payload.user)
    }


}
const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async GET_BUSINESS({dispatch,rootState},body) {

        rootState.business.selectedBusiness.alias = body

        state.showBusinessToOwner   = rootState.auth.userBusinesses.includes(body)
        state.showBusinessToManager = rootState.auth.userManagingBusinesses.includes(body)

        //here i will check if user is owner of business, and get specific data
        // orders products and etc COUNT STATISTIC FOR OWNER
        // the same for user
        // now i will just set selected business and GET Business just for fun
        let business = await dispatch('AJAX_SEND',{token:'public',route:`business/${body}`, method:'GET'})
        rootState.business.selectedBusiness = business.data

        return await dispatch('AJAX_SEND',{token:'public',route:`business/${body}`, method:'GET'});
    },

    // User Dashboards
    async GET_DASHBOARD_ADMIN({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:'dashboard/' + rootState.business.selectedBusiness.alias,
            method:'GET'});
    },
    async GET_DASHBOARD_ADMIN_WESTUDY({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`dashboard/admin/westudy`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async GET_DASHBOARD_USER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:'dashboard/businessSubscriber',
            method:'POST',
            data : body});
    },
    async GET_DASHBOARD_USER_WESTUDY({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`dashboard/user/westudy`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async GET_NOTIFICATION_COUNT({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:'dashboard/getCount', method:'GET'});
    },
    async GET_GMAP_GEOCODE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`google/maps/geocode/${body}`,
            method:'GET'});
    },




}
export default {
    namespaced:true,
    state,
    mutations,
    actions
}
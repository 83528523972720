export default {
    data() {
        return {
            DUPLICATED_COLORS : [
                ['wsACCENT' , 'wsDARK'],
                ['wsBACKGROUND' , 'wsLIGHTACCENT'],
                ['wsWHITE' , 'wsWHITETEXT']
            ]
        }
    },
    computed : {

        DASH_COLORS() { return [
            { text: 'settings.customization.dash.colors.sidebar_bg', value: "wsDASHBACKGROUND"},
            { text: 'settings.customization.dash.colors.sidebar_text', value: "wsSIDEBARTEXT"},
            { text: 'settings.customization.dash.colors.accent', value: "wsATTENTION"},
            { text: 'settings.customization.dash.colors.darker', value: "wsDARKER"},
            { text: 'settings.customization.dash.colors.dark', value: "wsDARK"},
            { text: 'settings.customization.dash.colors.dark_light', value: "wsDARKLIGHT"},
            { text: 'settings.customization.dash.colors.border', value: "wsBACKGROUND"},
            { text: 'settings.customization.dash.colors.light_card', value: "wsLIGHTCARD"},
        ]
        },
        DASH_PARAMS() { return [
        ]
        },

        PUBLIC_COLORS() { return [
            { text: 'settings.customization.public.colors.header', value: "wsHEADER_BG"},
            { text: 'settings.customization.public.colors.header_text', value: "wsHEADER_TEXT"},
            { text: 'settings.customization.public.colors.footer', value: "wsFOOTER_BG"},
            { text: 'settings.customization.public.colors.footer_text', value: "wsFOOTER_TEXT"},
            { text: 'settings.customization.public.colors.main_block', value: "wsPAGE_TITLE_BG"},
            { text: 'settings.customization.public.colors.main_block_font', value: "wsPAGE_TITLE_FONT"},
            { text: 'settings.customization.dash.colors.dark', value: "wsDARK"},
            { text: 'settings.customization.dash.colors.darker', value: "wsDARKER"},
            { text: 'settings.customization.dash.colors.light_card', value: "wsLIGHTCARD"},
            { text: 'settings.customization.dash.colors.dark_light', value: "wsDARKLIGHT"},
            { text: 'settings.customization.dash.colors.border', value: "wsBACKGROUND"},

            { text: 'settings.customization.public.colors.accent_color', value: "wsATTENTION"},
            ]
        },
        PUBLIC_PARAMS() { return [
                { value: 'HEADER_BUSINESS_TEXT'}
            ]
        },

        COLORS() {
            return this.$store.state.colors.colorsSet
        },
        PARAMS() {
            return this.$store.state.colors.paramsSet
        },
        // Main Colors
        wsACCENT() {
            return this.COLORS.wsACCENT
        },
        wsDARK() {
            return this.COLORS.wsDARK
        },

        wsDARKER() {
            return this.COLORS.wsDARKER
        },
        wsDARKLIGHT() {
            return this.COLORS.wsDARKLIGHT
        },
        wsBACKGROUND() {
            return this.COLORS.wsBACKGROUND
        },
        wsLIGHTACCENT() {
            return this.COLORS.wsLIGHTACCENT
        },
        wsLIGHTCARD() {
            return this.COLORS.wsLIGHTCARD
        },
        wsSUCCESS() {
            return this.COLORS.wsSUCCESS
        },
        wsLIGHTSUCCESS() {
            return this.COLORS.wsLIGHTSUCCESS
        },
        wsATTENTION() {
            return this.COLORS.wsATTENTION
        },
        wsWARNING() {
            return this.COLORS.wsWARNING
        },
        wsDASHBACKGROUND() {
            return this.COLORS.wsDASHBACKGROUND
        },
        wsWHITETEXT() {
            return this.COLORS.wsWHITETEXT
        },
        wsWHITE() {
            return this.COLORS.wsWHITE
        },

        wsSUCCESSDARK() {
            return this.COLORS.wsSUCCESSDARK
        },

        wsHEADER_BG() {
            return this.COLORS.wsHEADER_BG
        },
        wsTEXT() {
            return this.COLORS.wsTEXT
        },
        wsPAGE_TITLE_BG() {
            return this.COLORS.wsPAGE_TITLE_BG
        },
        wsPAGE_TITLE_FONT() {
            return this.COLORS.wsPAGE_TITLE_FONT
        },
        wsSIDEBARTEXT() {
            return this.COLORS.wsSIDEBARTEXT
        },
        wsHEADER_TEXT() {
            return this.COLORS.wsHEADER_TEXT
        },
        wsFOOTER_BG() {
            return this.COLORS.wsFOOTER_BG
        },
        wsFOOTER_TEXT() {
            return this.COLORS.wsFOOTER_TEXT
        },

        //Params

        DARK_HEADER() {
            return this.PARAMS.DARK_HEADER
        },
        HEADER_BUSINESS_TEXT() {
            return this.PARAMS.HEADER_BUSINESS_TEXT
        }

    },
    methods : {
        HANDLE_COLORS(data) {
            console.log('HANDLE_PUBLIC_COLORS')
            if (!data) {
                this.LOAD_DEFAULT_COLOR_SET()
                return
            }



            for (let colorName of Object.keys(data)) {
                let duplicatedColors = this.DUPLICATED_COLORS.find(el => el.includes(colorName))
                if (duplicatedColors) {
                    for (let duplicatedColor of duplicatedColors) {
                        data[duplicatedColor] = data[colorName]
                    }
                }
            }
            data = this.COPY(data)

            Object.keys(this.$store.state.colors.defaultColors).forEach(color => {
               if (data[color]) {
                   this.$store.state.colors.colorsSet[color] = data[color]
                   document.documentElement.style.setProperty(`--${color}`, data[color]);
               } else {
                   this.$store.state.colors.colorsSet[color] = this.$store.state.colors.defaultColors[color]
                   document.documentElement.style.setProperty(`--${color}`, this.$store.state.colors.defaultColors[color]);
               }
            })

            Object.keys(this.$store.state.colors.defaultParams).forEach(param => {
                if (data[param] || data[param] === false) {
                    this.$store.state.colors.paramsSet[param] = data[param]
                } else {
                    this.$store.state.colors.paramsSet[param] = this.$store.state.colors.defaultParams[param]
                }
            })


        },
        LOAD_DEFAULT_COLOR_SET() {
            Object.keys(this.$store.state.colors.defaultColors).forEach(color => {
                this.$store.state.colors.colorsSet[color] = this.$store.state.colors.defaultColors[color]
                document.documentElement.style.setProperty(`--${color}`, this.$store.state.colors.defaultColors[color]);
            })
            Object.keys(this.$store.state.colors.defaultParams).forEach(param => {
                this.$store.state.colors.paramsSet[param] = this.$store.state.colors.defaultColors[param]
            })
        }
    }
}
export default [
    { path: "" , component:() => import("@/modules/wsu_homepage/components/PageWsuHomepage.vue")  , props : true ,children :
            [
                { path: '' ,                       component:() => import("@/modules/wsu_homepage/components/home/HomeMainWsu")      },

                { path: 'register' ,               component:() => import("@/components/pages/homepage_westudy/register/HomepageRegister") },
                { path: 'login' ,                  component:() => import("@/components/auth/LoginPage") },
                { path: 'documentation',           component:() => import("@/components/pages/homepage_westudy/documentation/HomeDocumentationWestudy")  },
                { path: "documentation/:project/:alias?" , component:() => import("@/components/pages/homepage_westudy/documentation/HomeDocumentationWestudy") , props : true},
                { path: 'contacts',                component:() => import("@/components/pages/homepage_westudy/contacts/HomeContactsWestudy")          } ,
                { path: 'about',                   component:() => import("@/components/pages/homepage_westudy/about/HomeAboutWestudy")             } ,
                { path: 'about/confidential',      component:() => import("@/components/pages/homepage_westudy/about/homeConfidentialPolycy")      } ,
                { path: 'about/refund',            component:() => import("@/components/pages/homepage_westudy/about/homeRefundRules")              } ,
                { path: 'news/',                   component:() => import("@/components/pages/homepage/news/HomeNews")                    } ,
                { path: 'news/:alias',             component:() => import("@/components/pages/homepage/news/SingleNews") , props : true , } ,
            ] },

]



<template>
  <div>
    <div v-html="text"
         :id="`text_${uuid}`"
         style="transition: opacity 0.2s ease; word-break: break-word"
         :style="`opacity : ${ready ? 1 : 0}`"
    />
  </div>

</template>

<script>
export default {
  name: "avalonHtmlViewer",
  props : {
    text : {
      type : String
    },
    blockStyle : {
      text : String,
      default : 'light_1'
    },
    blockWidth : {
      text : Number,
      default : 1240
    },
    wsColors : {
      type : Boolean,
      default : false
    },
    isView : {
      type : Boolean,
      default : false
    },
    updateTrigger : {
      default : 0
    }
  },
  data() {
    return {
      ready : false,
      uuid : null,
      resizeTimeout : null
    }
  },
  computed : {
    siteConfig() {
      return this.$store.state.avalon.config
    }
  },
  watch : {
    WINDOW_WIDTH() {
      this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      setTimeout(()=>{
        this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      },100)

    },
    SM() {
      this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      setTimeout(()=>{
        this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      },100)

    },
    MOBILE_VIEW() {
      this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      setTimeout(()=>{
        this.PROCESS_TEXT_SIZE(`text_${this.uuid}` , this.blockWidth)
      },100)

    },
    'siteConfig.colors' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    'siteConfig.styles' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    'siteConfig.fonts' : {
      handler() {
        this.processTextStyling()
      },
      deep : true
    },
    text(){
      setTimeout(()=> {
        this.processTextStyling()
      },10)
    },
    updateTrigger() {
      setTimeout(()=> {
        this.processTextStyling()
      },10)

    }
  },
  methods : {
    processTextStyling() {
      this.PROCESS_TEXT_STYLES(`text_${this.uuid}` , this.blockStyle , this.wsColors , this.isView , this.blockWidth)
    }
  },
  beforeMount() {
    this.uuid = this.$uuid.v4()
  },
  mounted() {
    setTimeout(()=> {
      this.PROCESS_TEXT_STYLES(`text_${this.uuid}` , this.blockStyle , this.wsColors , this.isView , this.blockWidth)
      this.ready = true
    },1)
  }

}
</script>

<style scoped>

</style>
const state = {
    businessReferral : null,
}
const actions = {

    async GET_REFERRALS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referrals`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_EDIT_REFERRAL({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referral`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_REFERRAL({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referral/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async GET_REFERRAL_DETAILS({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referral/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_REFERRAL_PAYMENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referral/payment/add`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },

    async CHECK_BUSINESS_REFERRAL({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`referral/check/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },





}

export default {
    namespaced:true,
    state,
    actions,
}
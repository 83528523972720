<template>
  <v-sheet>
    <h5 v-if="label" class="wsMIDDLE font-weight-regular">{{ $t(label) }}</h5>
    <div  class="d-flex  flex-wrap mr-n2">

      <v-hover #default="{hover}" v-for="(item,i) in COLORS_LABELS" :key="i">
        <v-sheet
            @click="color = item"
            height="28" width="28" :color="item"
            class="wsRoundedLight mr-2 mt-2 pointer"
            :style="hover || color === item ? 'outline: 2px solid var(--wsACCENT) !important' : null"
            v-ripple
        />
      </v-hover>


    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "wsSystemColorSelect",
  props : {
    value : {
      type: String,
      default: null
    },
    mandatory : {
      type: Boolean,
      default: false
    },
    label : {
      type: String,
    }
  },
  data() {
    return {
      color : null
    }
  },
  watch : {
    color() {
      if (this.value !== this.color) {
        this.$emit('input',this.color)
      }
    },
    value() {
      if (this.value !== this.color) {
        this.color = this.value
      }
    }
  },
  mounted() {
    if (this.value) {
      this.color = this.value
    }
    if ( !this.color && this.mandatory ) {
      this.color = this.COLORS_LABELS[0]
    }
  }
}
</script>


<style scoped>

</style>
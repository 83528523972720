const actions = {

    // BUSINESSES
    async GET_BUSINESSES({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/businesses/${body}`,
                method:'GET'
            }, {root:true });
    },

    async GET_NEW_BUSINESSES({dispatch} , body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/new/businesses`,
                method:'POST',
                data : body
            }, {root:true });
    },

    async GET_ACTIVE_BUSINESSES({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/active/businesses`,
                method:'GET',
            }, {root:true });
    },

    async GET_DEBT_BUSINESSES({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/active/businesses/debt`,
                method:'GET',
            }, {root:true });
    },

    async GET_DELETED_BUSINESSES({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/deleted/businesses`,
                method:'GET',
            }, {root:true });
    },

    async GET_DEACTIVATED_BUSINESSES({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/deactivated/businesses`,
                method:'GET',
            }, {root:true });
    },

    // PAYMENTS

    async GET_PAYMENTS({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments`,
                method:'GET',
            }, {root:true });
    },

    async GET_BUSINESS_PAYMENTS({dispatch}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/business_payments/${body}`,
                method:'GET',
            }, {root:true });
    },



    async ADD_PAYMENT({dispatch} , body  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payment`,
                method:'PUT',
                data : body,
            }, {root:true });
    },

    // EXPENSES

    async GET_EXPENSES({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/expenses`,
                method:'GET',
            }, {root:true });
    },

    async DELETE_EXPENSE({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/expense/${body}`,
                method:'DELETE',
            }, {root:true });
    },

    async ADD_EXPENSE({dispatch} , body  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/expense`,
                method:'PUT',
                data : body,
            }, {root:true });
    },

    // STATISTICS

    async GET_STATISTICS({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/statistics/general/${body}`,
                method:'GET',
            }, {root:true });
    },

    // Business
    async GET_INFO_FOR_BUSINESS({rootState, dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/business/owner/info`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async CHECKOUT_BUSINESS_OWNER_PAYMENT_PLAN({rootState, dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/business/owner/checkout/payment_plan`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root:true });
    },
    async CHECKOUT_BUSINESS_OWNER_CHANGE_PLAN({rootState, dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/business/owner/checkout/change_plan`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root:true });
    },


    async GET_BUSINESS_ACTIVE_ORDERS({rootState, dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/business/orders`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_PAYMENT_BUTTON({rootState, dispatch}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`admin/payments/business/orders/button`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root:true });
    },


}

export default {
    namespaced:true,
    actions,
}
const state = {
    notifications : [],

    notify       : '',
    notify_title : '',
    notify_text  : '',
    notify_image   : '',
}

const actions = {
    async PUSH_NOTIFICATION({dispatch, rootState},notification) {
        switch (notification.event) {

            case 'support' :
                return await dispatch('support/SUPPORT_EVENT',notification, {root:true})
            case 'streaming' :
                return await dispatch('stream/STREAM_EVENT',notification, {root:true})
            case 'order_new' :
                notification.icon = 'mdi-cart'
                notification.action = 'openOrderds'
                notification.event = 'Нове замовлення'
                notification.description = 'Дивитись'
                notification.type = 'order_new'
                break;

            case 'link_telegram' :
                notification.icon = 'mdi-send'
                notification.action = ''
                notification.event = 'Інтеграція'
                notification.description = "Telegram акаунт під'єднано"
                notification.type = 'link_telegram'
                break;

            case 'event' :
                return await dispatch('events/HANDLE_NEW',notification, {root:true})

            case 'messenger_new_message' :
                await dispatch('events/HANDLE_NEW',notification, {root:true})
                return await dispatch('messenger/HANDLE_NEW_MESSAGE',notification.payload, {root:true})
            case 'messenger_edit_message' :
                return await dispatch('messenger/HANDLE_EDIT_MESSAGE',notification.payload, {root:true})

            case 'course_completion' :
                rootState.courses.courseCompletionId = notification.entity
                rootState.courses.displayCourseCompletionDialog = true
        }

    },
}
export default {
    namespaced : true,
    state,
    actions
}
<template>
  <v-sheet
      color="red"
      :style="`
              -webkit-mask: url('${WESTUDY_ICON(icon)}') no-repeat ;
              mask: url('${WESTUDY_ICON(icon)}') no-repeat 50% 50%;
              mask-size : contain;
              position:relative;
              background-color: ${ dark ? '#ffffff' : color} !important;`
              "
      :height="size"
      :width="size"
      :min-height="size"
      :min-width="size"
      :max-height="size"
      :max-width="size"
      contain
      :class="!noRightMargin ? 'mr-3' : ''"
  />

</template>

<script>
export default {
  name: "wsIcon",
  props : {
    icon : {
      type : String
    },
    color : {
      type : String,
      default : '#000000'
    },
    size : {
      type : String,
      default : '24'
    },
    dark : {
      type : Boolean,
      default : false
    },
    noRightMargin : {
      type : Boolean,
      default : false
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <wsOnboardDialog v-model="$store.state.onboarding.displayWindow">
    <h1 class="font-weight-light">
      <v-btn @click="startOnboard">start tour</v-btn>
    </h1>
  </wsOnboardDialog>
</template>

<script>
import wsOnboardDialog from "@/onboardTours/UI/wsOnboardDialog";
export default {
  name: "obAdminWelcomeDashboard",
  components : {
    wsOnboardDialog
  },
  methods : {
    startOnboard() {
      this.notify('')
    }
  }
}
</script>

<style scoped>

</style>
const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async GET_DOMAIN_STATUS({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/status/${body.alias}`, method:'POST',data : body.data} );
    },

    async GET_DOMAIN_ORDERS({rootState,dispatch}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/orders`, method:'GET'} );
    },
    async CHECK_DOMAIN({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/u/check`, method:'POST', data:body});
    },
    async ORDER_DOMAIN({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/u/order/${body.alias}`, method:'POST', data:body.data});
    },
    async CANCEL_DOMAIN_ORDER({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/u/order/admin/cancel`, method:'POST', data:body});
    },
    async ACTIVATE_DOMAIN_ORDER({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/u/order/admin/activate`, method:'POST', data:body});
    },


    async PARK_DOMAIN({rootState,dispatch}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken, route:`domains/cpanel`, method:'GET'});
    },




}

export default {
    namespaced:true,
    actions,
}
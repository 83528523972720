const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },


    // QUESTION BANKS

    async INIT_PUBLIC({dispatch}) {
        return await dispatch('AJAX_SEND', { route: 'public', method: 'GET'})
    },
    async INIT_PUBLIC_MAIN({dispatch}) {
        return await dispatch('AJAX_SEND', { route: 'public_main', method: 'GET'})
    },
    async NEW_LEAD({dispatch},body) {
        return await dispatch('AJAX_SEND', { route: 'public/lead', method: 'PUT',data:body})
    },
    async SPECIALTY_DETAILS({dispatch},body) {
        return await dispatch('AJAX_SEND', { route: `public/specialty/${body}`, method: 'GET'})
    },




}

export default {
    namespaced:true,
    actions
}
const actions = {
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },


    async GET_DATA({dispatch,rootState},body) {
        var route = `structure/${body.type}`
        if(body.uuid) {  route = `structure/${body.type}/${body.uuid}` }
        if(body.condition) {  route = `structure/${body.type}/${body.uuid}/${body.condition}` }
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: route, method: 'GET'})
    },
    async SAVE_DATA({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/${body.type}`, method:'PUT',data: body.data});
    },
    async DELETE_DATA({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/${body.type}`, method:'DELETE',data: body.data});
    },



    async SAVE_FACULTY({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/faculty`, method:'PUT',data: body});
    },
    async SAVE_DEPARTMENT({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/department`, method:'PUT',data: body});
    },
    async SAVE_DISCIPLINE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/discipline`, method:'PUT',data: body});
    },
    async SAVE_LECTURE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/lecture`, method:'PUT',data: body});
    },
    async GET_MANAGER_DEPARTMENT({dispatch,rootState}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`structure/managerDepartment`, method:'GET'});
    },




    // SPECIALTIES
    async GET_SPECIALTIES({dispatch,rootState}) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: 'specialties', method: 'GET'})
    },
    async GET_DEPARTMENT_SPECIALTIES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND', {token: rootState.auth.accessToken, route: `specialties/department/${body}`, method: 'GET'})
    },





}

export default {
    namespaced:true,
    actions
}
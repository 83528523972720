<template>
  <wsOnboardDialog v-model="$store.state.onboarding.displayWindow"
                   :width="step === 0 ? '650' : '1000'"
                   @save="next"
                   :title="title"
                   cancel-action
                   no-navigation
                   :confirm-delete-text="$t('ConfirmExitTour')"
                   @delete="cancelTour"
                   :save-text="$t('StartTour')">



    <div v-if="step===0">

      <v-sheet class="pt-3">
        <v-img transition="xxx" class="wsRounded mx-auto" width="270"    src="@/assets/img/welcomeImage.png" />
      </v-sheet>
      <h3 class="font-weight-medium text-center mt-6">
        {{ $t('WelcomeTourTitle') }} !
      </h3>
      <h4 class="font-weight-light text-center mt-1 ">
        {{ $t('WelcomeTourInvitation') }}!
      </h4>

      <div class="d-flex justify-center">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('StartTour') }}</v-btn>
      </div>


    </div>

    <div v-if="step===1">

      <v-row class=" pt-9">
        <v-col>
          <v-img transition="xxx" style="border-radius : 10px" class="mx-auto" width="300" height="195" src="@/assets/img/wsDash.png" />
          <h3 class="text-center font-weight-medium mt-3">{{ $t('PersonalDashboard')}} </h3>
          <h5 class="font-weight-light mt-3" >Через особистий кабінет власник керує своїм інфобізнесом, створює та редагує курси, надає менеджерам часткові або повні права роботи в платформі, а студенти безпосередньо отримують навчальні послуги.</h5>
          <h5 class="font-weight-light mt-3">Для переходу в особистий кабінет – необхідно авторизуватися на платформі через Публічний сайт.</h5>

        </v-col>
        <v-col>

          <v-img transition="xxx" style="border-radius : 10px" class="mx-auto" width="300" height="195" src="@/assets/img/wsPublic.png" />
          <h3 class="text-center font-weight-medium mt-3" >{{ $t('PublicWebsite') }} </h3>
          <h5 class="font-weight-light mt-3">Публічний сайт автоматично створюється при реєстрації бізнесу. Подальше налаштування відбувається через Конструктор Сайту.</h5>
          <h5 class="font-weight-light mt-3">Публічний сайт за замовченням має лендінг з двома воронками продажів: «Заявка» та «Купівля курсів», блок «Новини», сторінки «Про нас» та «Контакти». Клієнти мають можливість самостійно реєструватися на Публічному сайті та купувати курси.</h5>
          <h5 class="font-weight-light mt-3">Публічний сайт завжди достуний за посиланням <a :href="'https://westudy.ua/' + $store.state.business.selectedBusiness.alias" target="_blank">westudy.ua/{{ $store.state.business.selectedBusiness.alias }}</a>  </h5>


        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('Next') }}</v-btn>
      </div>


    </div>

<!--    <div v-if="step===2">-->


<!--      <v-row class="py-6">-->
<!--        <v-col>-->
<!--          <v-img class="wsRounded mx-auto" width="300" aspect-ratio="1.1" src="@/assets/img/main_westudy.png" />-->
<!--        </v-col>-->
<!--        <v-col align-self="center">-->
<!--          <h3 class="text-center font-weight-medium mt-3" >{{ $t('PublicWebsite') }}</h3>-->
<!--          <h5 class="font-weight-light mt-3">Якщо вам не потрібні лендінг сторінки та публічний сайт, ви можете налаштувати публчну сторінку тільки для авторизації та входу в особистий кабінет </h5>-->
<!--        </v-col>-->
<!--      </v-row>-->

<!--      <div class="d-flex justify-end">-->
<!--        <v-btn @click="next" dark elevation="0"  :color="wsATTENTION" class="noCaps mt-6 px-16">{{ $t('Next') }}</v-btn>-->
<!--      </div>-->

<!--    </div>-->

  </wsOnboardDialog>
</template>

<script>
import { ONBOARD_DASHBOARD }    from "@/onboardTours/AdminWelcomeWestudy/AdminWelcomeWestudy" ;
import wsOnboardDialog from "@/onboardTours/UI/wsOnboardDialog";
import {mapActions} from "vuex";
export default {
  name: "obAdminWelcomeDashboard",
  components : {
    wsOnboardDialog
  },
  data() {
    return {
      step : 0
    }
  },
  computed : {
    title() {
      if ( this.step === 1 ) { return this.$t('WelcomeTourGeneralTitle') }
      return null
    }
  },
  methods : {
    ...mapActions( 'onboarding', [ 'CANCEL_ADMIN_WECLOME' ] ),

    next() {
      this.step++;
      if ( this.step === 2 ) {
        this.startOnboard()
      }
    },

    startOnboard() {
      this.$store.state.onboarding.displayWindow = false
      ONBOARD_DASHBOARD.start(this)
    },
    cancelTour() {
      this.CANCEL_ADMIN_WECLOME()
      this.$store.state.onboarding.displayWindow = false
    }
  }
}
</script>

<style scoped>

</style>
const state = {
    pageData : {},
    filtersData : {},
    navigationSelect : [],
}
const actions = {
    async AJAX_SEND({rootState},body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth' : body.token },
            }

            if(body.data) { params['body'] = body.data }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else { console.log(data) }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    async ADD_USER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:'users/add', method:'PUT', data: body});
    },

    async EDIT_BUSINESS_GENERAL_SETTINGS({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'business/settings/general',
                method:'POST',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async GET_BUSINESS_TRANSLATIONS_ARRAY({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'business/settings/translations',
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async EDIT_BUSINESS_SERVICE_KEY({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'business/service_key/edit',
                method:'PUT',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async REMOVE_BUSINESS_SERVICE_KEY({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:'business/service_key/remove',
                method:'DELETE',
                data:body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
}
export default {
    namespaced:true,
    state,
    actions
}
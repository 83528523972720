const state = {

    colorSet : [ '#2a2a2a', '#fdfdfd', '#567186', '#94b3c7', '#F2F8FB', '#FE834B' ],

    displayEditor : false,
    pageData : {},
    filtersData : {},
    navigationSelect : [],

    emailDisplayEditor : false,
    emailPageData : {},
    emailFiltersData : {},
    emailNavigationSelect : [],

    emailNewsletterPageData : {},
    emailNewsletterFiltersData : {},
    emailNewsletterNavigationSelect : [],

    emailDesignSaveFunction : null,
    emailDesignEditFunction : null,
    emailReceiversType : 'auto',

    emailReadyToPublish : false,

    headingTypesArray : [
        'user_auth_data' ,
        'course_name',
        'password_reset_code',
        'order_number',
        'order_course_name',
        'order_price',
    ],
    buttonTypesArray : [
        'order_payment_button',
        'dashboard_link'
    ],

    // Telegram
    telegram : false,
    telegramBot : {},

}
const actions = {

    // :: EMAIL ::
    // Newsletter

    async ADD_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async EDIT_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async DELETE_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async ADD_NEWSLETTER_GROUP({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/settings/email/business/receiver_group`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async DELETE_NEWSLETTER_GROUP({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/receivers_group/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_NEWSLETTERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/business`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async PUBLISH_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/${body}/publish`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },

    async GET_BUSINESS_NEWSLETTER_TEMPLATES({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/business/templates`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async ADD_EMAIL_TEMPLATE({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/business/template`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root:true });
    },
    async DELETE_EMAIL_TEMPLATE({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/business/template/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },

    // Design
    async SAVE_NEWSLETTER_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/design`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_NEWSLETTER_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/design/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_DEFAULT_EMAIL_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/default/design/auto/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },

    // RECEIVERS
    async SET_NEWSLETTER_RECEIVERS_TYPE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/receivers`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_NEWSLETTER_RECEIVERS_STATS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/${body}/receivers/stats`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_COURSES_SELECT({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/get_select/courses`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },

    // STATS
    async MARK_RECIEVER_AS_READ({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/receiver/link/read`,
                method:'POST',
                data : body,
            }, {root:true });
    },

    // SETTINGS
    async GET_DEFAULT_SETTINGS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/settings/email/business`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async SAVE_DEFAULT_SETTINGS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/settings/email/business`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root:true });
    },



    // :: TELEGRAM ::
    // Newsletter

    async PUBLISH_TELEGRAM_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/business/telegram/${body}/publish`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },


    async ADD_TELEGRAM_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/telegram/business`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_TELEGRAM_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/telegram/business/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_TELEGRAM_NEWSLETTERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/telegram/business`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },

    async EDIT_TELEGRAM_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/telegram/business`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async DELETE_TELEGRAM_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/telegram/business/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },



    // ADMIN
    async ADD_ADMIN_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_ADMIN_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_ADMIN_NEWSLETTERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletters/admin`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async SET_ADMIN_NEWSLETTER_RECEIVERS_TYPE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/receivers`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async SAVE_ADMIN_NEWSLETTER_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/design`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_ADMIN_NEWSLETTER_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/design/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async GET_ADMIN_SYSTEM_EMAIL_DESIGN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/design/auto/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async PUBLISH_ADMIN_NEWSLETTER({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/admin/${body}/publish`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },
    async UPLOAD_ADMIN_IMAGE({dispatch,state},body) {
        return await dispatch('ajax/SEND_NEW',{
            route:`upload/admin/image`,
            method:'POST',
            business : state.businessAlias,
            data: { fileFormData: body }
        }, {root:true});
    },

    // USER

    async UNSUBSCRIBE_EMAIL({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`newsletter/unsubscribe`,
                method:'POST',
                data : body,
            }, {root:true });
    },



}

export default {
    namespaced:true,
    state,
    actions,

}
const state = {
    display : false,
    src : '',
    title : ''
}

const actions = {
}

export default {
    namespaced:true,
    actions,
    state

}
<template>
<div>
  <h5  v-if="label"
       :key="SCALE_COEFFICIENT + 'label'"
       :style="BLOCK_TEXT_CSS( block , configStyle , 'form' , 'label' ,null,null, DEFAULT_BLOCK_WIDTH)"
     >
    {{  label  }}
  </h5>

  <v-sheet
      :style="`border : 1px solid ${BLOCK_COLOR_PARAM(block , 'form_border', configStyle)}; padding : ${FONT_SIZE/3}px 0`"
      :color="BLOCK_COLOR_PARAM(block , 'form_background' , configStyle) "
      class="d-flex justify-space-between"
  >

    <!-- TEXT INPUT  -->
    <div   class="d-flex " style="width: 100%">

      <!-- Placeholder  -->
      <h5 v-if="!text"
          :key="SCALE_COEFFICIENT + 'placeholder'"
          style="position: absolute;pointer-events: none; line-height: 1 !important"
          :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null , true , DEFAULT_BLOCK_WIDTH ) + `;margin-left : ${FONT_SIZE/2}px;`"
      >
        {{ placeholder || $t('EnterText') }}
      </h5>

      <!-- Textfield -->
      <input
          v-if="!textArea"
          :key="SCALE_COEFFICIENT + 'input'"
          style="width: 100%; outline: none;"
          :style="BLOCK_TEXT_CSS(block , configStyle , 'form' , 'text' , null , false , DEFAULT_BLOCK_WIDTH ) + `;margin-left : ${FONT_SIZE/2}px;`"
          ref="text"
          v-model="text"
      />
      <!-- Textarea  -->
      <textarea
          v-else
          :key="SCALE_COEFFICIENT + 'input'"
          style="width: 100%; outline: none; resize: none; "
          :style="BLOCK_TEXT_CSS(block , configStyle , 'form' , 'text' , null , false , DEFAULT_BLOCK_WIDTH ) + `;margin-left : ${FONT_SIZE/2}px;`"
          ref="textarea"
          v-model="text"
          class="textarea"
          :rows="rows"
      />

    </div>


    <!-- Actions -->
    <div class="d-flex"
         :class="[{'align-center' : !textArea || rows === 1 } , {'align-baseline' : textArea && rows > 1 }]"
         :style="`margin-right : ${FONT_SIZE/3 + 4}px;`" >
      <!-- Clearable Icon-->
      <v-icon v-if="text" @click="text = ''; $emit('clear')" class="pointer" :color="BLOCK_COLOR_PARAM(block , 'form_icon', configStyle)">mdi-close-circle</v-icon>

      <!-- Action Button text-->
      <h5 v-if="actionText"
          :key="SCALE_COEFFICIENT + 'action_text'"
          @click="$emit('action')"
          class="pointer ml-2"
          :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null , null, DEFAULT_BLOCK_WIDTH  )"
      >
        {{ actionText }}
      </h5>

    </div>


  </v-sheet>
</div>
</template>

<script>
export default {
  name: "avalonTextField",
  props : {
    value : {
      type : String,
    },
    block : {
      type : Object,
    },
    configStyle : {
      type : String,
    },
    label : {
      type : String
    },
    placeholder : {
      type : String,
    },
    textArea : {
      type : Boolean,
      default : false
    },
    rows : {
      type : [String, Number],
      default : 1
    },
    actionText : {
      type : String
    }
  },
  data() {
    return {
      text : ''
    }
  },
  computed:  {
    FONT_SIZE() {
      return this.BLOCK_FONT_VARIABLE(this.block, 'size' , 'form' , 'text' )*this.SCALE_COEFFICIENT
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.textArea ) {
        this.adjustTextareaHeight()
      }
      if ( this.value !== this.text) {
        this.$emit('input' , this.text )
      }
    }
  },
  methods : {
    adjustTextareaHeight() {
      const input = this.$refs.textarea;
      input.style.height = "auto";
      if ( this.text ) {
        input.style.height = input.scrollHeight + "px";
      }

    },
  },
  mounted() {
    if ( !this.block && !this.configStyle ) {
      this.configStyle = 'light_1'
    }
    if (this.value) {
      this.text = this.value
    }
  }
}
</script>

<style scoped>
.auto-growable-input {
  width: 100%;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>
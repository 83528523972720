<template>
  <div >
    <v-row>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.firstname"
            :placeholder="$t('Firstname')"
            :label="$t('Firstname')"
            :error="nameError"
            @input="nameError = false"
        />
      </v-col>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.lastname"
            :placeholder="$t('Lastname')"
            :label="$t('Lastname')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.password"
            :placeholder="$t('Password')"
            :label="$t('Password')"
            :error="passwordError"
            @input="passwordError = false"
        />
      </v-col>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.repeat_password"
            :placeholder="$t('PasswordRepeat')"
            :label="$t('PasswordRepeat')"
            :error="passwordError"
            @input="passwordError = false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.email"
            :placeholder="$t('Email')"
            :label="$t('Email')"
            :error="emailError || userError"
            @input="emailError = false ; userError = false"
        />
      </v-col>
      <v-col cols="6">
        <ws-phone-input
            :label="$t('Phone')"
            :error="phoneError || userError"
            @input="phoneError=false ; userError = false "
            v-model="entityData.phone"
            hide-details
        />
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col cols="6">
        <wsDatePicker :label="$t('Birthday')" :placeholder="$t('ChooseDate')" v-model="entityData.birthday" />
      </v-col>
      <v-col cols="6">
        <ws-text-field
            v-model="entityData.country"
            :placeholder="$t('Country')"
            :label="$t('Country')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "wsNewUserInput",
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      entityData : {},
      nameError : false,
      passwordError : false,
      emailError : false,
      userError : false,

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,
    }
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.value , this.entityData) ) {
          this.entityData = this.COPY(this.value)
        }
      },
      deep : true
    },
    entityData : {
      handler() {
        if ( !this.EQ(this.value , this.entityData) ) {
          this.$emit('input' , this.COPY(this.entityData))
        }
      },
      deep : true
    }
  },
  methods : {
    validate() {
      if ( !this.entityData.email) {
        this.emailError = true
        this.notify(this.$t('EnterEmail') , 'warning')
        return false
      }
      if(  !this.reg.test(this.entityData.email)  ) {
        this.emailError = true
        this.notify(this.$t('WrongEmail') , 'warning');
        return false
      }
      if ( !this.entityData.password) {
        this.passwordError = true
        this.notify(this.$t('EnterPassword') , 'warning')
        return false
      }
      if ( this.entityData.password !== this.entityData.repeat_password ) {
        this.passwordError = true
        this.notify(this.$t('PasswordNotMatch') , 'warning')
        return false
      }
      if ( !this.entityData.phone) {
        this.phoneError = true
        this.notify(this.$t('EnterPhone') , 'warning')
        return false
      }
      if ( !this.entityData.firstname) {
        this.nameError = true
        this.notify(this.$t('EnterFirstname') , 'warning')
        return false
      }
      return true
    }
  },
  mounted() {
    this.$emit('set-validate' , this.validate)
    if ( this.value ) {
      this.entityData = this.COPY(this.value)
    }
  }
}
</script>

<style scoped>

</style>
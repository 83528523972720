const state = {
    view : 'kanban'
}
const actions = {

    async GET_BUSINESS_LEADS({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`leads`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },  {root:true});
    },
    async ADD_BUSINESS_LEAD({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`lead`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true} );
    },
    async ADD_BUSINESS_LEAD_ADMIN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`lead/admin`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true} );

    },
    async EDIT_BUSINESS_LEAD({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`lead/edit`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true} );
    },
    async DELETE_BUSINESS_LEAD({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`lead/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root:true} );
    },


}

export default {
    namespaced:true,
    state,
    actions,
}
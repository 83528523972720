import getRouter  from '@/router/router.js'
import getStore   from '@/store/store.js'
import ajaxPlugin   from './plugins/ajax';
import ajaxNewPlugin   from './plugins/ajax_new';
import makeAjaxCustom from "./plugins/ajax_custom";
import makeSocket from "@/plugins/socket";
import makeI18n   from "@/plugins/i18n"
import fineTime   from "@/plugins/fineTime";

const i18n     = makeI18n()

const ajax_custom = makeAjaxCustom();

const store    = getStore(ajaxPlugin,ajaxNewPlugin,ajax_custom, process.env.VUE_APP_API_URL, process.env.VUE_APP_MAIN_HOST, process.env.VUE_APP_SECONDARY_HOST , i18n)
const router   = getRouter(store);
const socket   = makeSocket(store, process.env.VUE_APP_WS_SOCKET);
// ajaxNewPlugin.setStore(store)
// ajaxPlugin.setStore(store)

const ajax     = ajaxPlugin.makeAjax( process.env.VUE_APP_API_URL , i18n );
const ajax_new = ajaxNewPlugin.makeAjaxNew( process.env.VUE_APP_API_URL , i18n );

const $fineTime = (dateInput = new Date() , formatInput = null) => {
    return fineTime.create(dateInput, i18n , formatInput);
};


export default {
    i18n,
    ajax   ,
    ajax_new,
    ajax_custom,
    router ,
    store  ,
    socket,
    $fineTime
}
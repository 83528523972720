const state = {

}

const actions = {

    // Courses
    async GET_COURSES_NEW({ dispatch}, body ) {
        return await dispatch('ajaxMarket/SEND',
            {
                route:`courses`,
                method:'POST',
                data : body
            }, { root : true});
    },

    async GET_COURSES({ dispatch}, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`market/courses`,
                method:'POST',
                data : body
            }, { root : true});
    },

    async GET_BUSINESS_MARKET({dispatch }, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                business : body,
                route:`market/business/info`,
                method:'GET',
            }, { root : true});
    },



}

export default {
    namespaced:true,
    actions,
    state
}
const state = {
    selectedElementId : null
}
const actions = {


    // ADMIN
    async ADD_COURSE_CERTIFICATE({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/add`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async DELETE_COURSE_CERTIFICATE({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/delete`,
                method:'DELETE',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_COURSE_CERTIFICATE_EDIT({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/edit/${body.uuid}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async SAVE_CERTIFICATE({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/save/${body}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true }
        );
    },

    async GET_CERTIFICATE_TEMPLATES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/templates`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_COURSE_ISSUED_CERTIFICATES({rootState,dispatch} , body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/issued/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true}
        );
    },

    async GET_CERTIFICATE_DATA_EDIT({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/data/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async DELETE_CERTIFICATE_DATA({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/data/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async DELETE_CERTIFICATE_DATA_TRANSLATION({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/data/translation/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },



    async EDIT_CERTIFICATE_DATA({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/edit`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },




    // STUDENT
    async GET_COURSE_CERTIFICATE_STUDENT({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/student/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_STUDENT_CERTIFICATES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificates/student`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async DOWNLOAD_CERTIFICATE({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/download`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
                returnFile : true,
            },{ root : true }
        );
    },

    async DOWNLOAD_CERTIFICATE_ADMIN({rootState,dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/admin/download`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
                returnFile : true,
            },{ root : true }
        );
    },

    async GET_COURSE_CERTIFICATE_PUBLIC({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/check/${body}`,
                method:'GET',
            },{ root : true }
        );
    },

    async GET_CERTIFICATE_ISSUE_DATA({rootState,dispatch} , body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/student/issue/data/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ISSUE_STUDENT_CERTIFICATE({rootState,dispatch} , body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`certificate/student/issue`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },








}

export default {
    namespaced:true,
    actions,
    state
}
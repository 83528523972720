const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async QUERY_LOCATION({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`np/location/${body}`, method:'GET'});
    },
    async QUERY_STREET({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`np/street/${body.city}/${body.street}`, method:'GET'});
    },
    async NP_QUERY_WAREHOUSE({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`np/warehouse/${body}`, method:'GET'});
    },

    async GMAP_GEOCODE_ADDRESS({dispatch},body){
        return await dispatch('AJAX_SEND',{token:'public',route:`google/maps/geocode/address`, method:'POST',data: body});
    }
}

export default {
    namespaced:true,
    actions,
}
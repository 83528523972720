export default {
    data() {
        return {
            items : [],
            entityData : {},
            displayDialog : false,
            displayDeleteDialog : false,
            newEntity : false,
            loading : false,
            storeNamespace : null
        }
    },
    methods : {

        // Ajax functions

        async GET_ITEMS( method , callback = null  , namespace = null ,  data = null ) {
            this.loading = true
            if ( !namespace ) {
                namespace = this.storeNamespace
            }

            let storeRoute = namespace ? `${namespace}/${method}` : method
            let result = await this.$store.dispatch(storeRoute , data)

            if ( !result || result === true ) {
                this.ERROR()
                this.loading = false
                return false
            }
            if ( callback ) {
                callback.call(this, result);
            } else {
                this.items = result.items
            }
            this.loading = false

        },

        async ADD_EDIT_ENTITY( method , callback = null ,  namespace  = null ,  data = null , items = null , isNew = null , key = 'uuid' , unshift = false ) {

            if ( !namespace ) {
                namespace = this.storeNamespace
            }
            if ( !data ) {
                data = this.entityData
            }
            if ( !items ) {
                items = this.items
            }
            if ( isNew === null ) {
                isNew = this.newEntity
            }

            let storeRoute = namespace ? `${namespace}/${method}` : method
            let result = await this.$store.dispatch(storeRoute , data)
            if ( !result ) {
                this.ERROR()
                return false
            }
            if ( callback ) {
                callback(result)
            }
            else if ( isNew ) {
                !unshift ? items.push(result) : items.unshift(result)
            } else {
                let index = items.findIndex( el => el[key] === result[key])
                if ( index === -1 ) {
                    this.ERROR()
                    return false
                }
                items[index] = result
                this.items = this.COPY(items)
            }
            this.displayDialog = false
            return true
        },

        async DELETE_ENTITY( method , callback = null ) {
            let namespace = this.storeNamespace
            let storeRoute = namespace ? `${namespace}/${method}` : method

            let result = await this.$store.dispatch(storeRoute , this.entityData.uuid)
            if ( !result ) {
                this.ERROR()
                return false
            }
            if ( callback ) {
                callback(result)
            }
            else {
                let index = this.items.findIndex( el => el.uuid === this.entityData.uuid )

                if ( index === -1 ) {
                    this.ERROR()
                    return false
                }
                this.items.splice(index, 1)
            }
            this.displayDeleteDialog = false
            return true
        },

        // Dialogs Functions
        OPEN_NEW_ENTITY() {
            this.newEntity = true
            this.entityData = {}
            this.displayDialog = true
        },
        OPEN_EDIT_ENTITY(item) {
            this.newEntity = false
            this.entityData = this.COPY(item)
            this.displayDialog = true
        },
        OPEN_DELETE_ENTITY(item) {
            this.entityData = this.COPY(item)
            this.displayDeleteDialog = true
        },

        // Technical
        API(method , callback) {
            if ( method.includes('ADD_EDIT') ) {
                this.ADD_EDIT_ENTITY(method)
            }
            if ( method.includes('GET') ) {
                this.GET_ITEMS(method , callback)
            }
            if ( method.includes('DELETE') ) {
                this.DELETE_ENTITY(method)
            }
            if ( method.includes('ns/') ) {
                this.STORE_NAMESPACE(method.split('/')[1])
            }
        },

        STORE_NAMESPACE(namespace) {
            this.storeNamespace = namespace
        },
    },
    mounted() {
        if (this.$options.init) {
            let init = this.$options.init.split('/')
            this.storeNamespace = init[0]
            if ( init.length > 1 ) {

                if (this.$options.init_callback) {
                    this.API(init[1] , this.$options.init_callback )
                } else {
                    this.API(init[1])
                }
            }
        }
        if (this.$options.headers) {
            this.headers = this.$options.headers
        }


    }
}
const state = {

    editorOn : false,
    previewFromSideMenu : true,

    triggerMobileTextOverlapCheck : 0,
    // group elements udpate
    updateElementsArray : {},
    updateElementsTrigger : 0,
    updateElementsTimeout : null,
    checkMobileArray : [],

    undoBuffer : [],
    redoBuffer : [],
    blockSettingsIsEditing :false,
    previewPageData : {},
    previewMode : false,

    siteIsPublished : false,
    config : {},
    favicon : null,
    faviconDark : null,
    defaultConfig : {},
    blocksConfig : {},
    elementsConfig : {},
    colorsVariables : {},
    fontsVariables : {},
    selection : null,
    selectionChange : 0,
    scrollPosition : 0,
    navigationPages : [],
    langs : [],
    menuNavigation : 'pages',
    menuNavigationReturn : 'menuNavigationReturn',
    menuNavigationStyleTheme : null,
    menuNavigationElement : null,
    configChanged : false,
    initialConfigData : {},
    displaySearch : false,
    pageSearch : '',


    langPrimary : [],
    viewAvalon : false,
    isLoadingFont : false,
    fonts : [],

    mobileView : false,
    mobileWidth : '320px',

    editorMode : true,
    sideMenu : false,

    selectedLang : 'ua',
    selectedPage : null,
    selectedNews : null,

    homepage : '',
    header : {
        config : {}
    },
    footer : null,
    mainMenu : [],
    folders : [],
    pagesSelect : [],
    coursesSelect : [],
    newsSelect : [],

    //triggers
    updateHeaderTrigger : 0,
    elementsResizeTrigger : 0,
    rowHeightChangeTrigger : 0,
    sidemenuTrigger : 0,

    //dialogs
    displayNewBlockDialog : false,
    displayLanguageDialog : false,

    displayEditFolderDialog : false,
    displayDeleteFolderDialog : false,


    quillSelectionIndex : 0,
    quillSelectionLength : 0,
    quillEditor : {},
    selectedFolder : {},
    selectedElement : null,
    selectedBlockId : null,
    selectedElementType : null,
    selectedElementDesign : null,
    selectedBlockType : null,
    selectedBlockStyle : null,
    selectedBlockDesign : null,
    elementSettingsOn : false,
    elementSettingsCloseTrigger : 0,
    mouseX: 0,
    mouseClickX : 0,
    mouseY: 0,
    imageLibraryOn : false,

    bufferCopyBlockId : null,
    paramDropdownId : null,

    blockPricingUpdateTrigger : 0

}

const actions = {

    // GENERAL



    async GET_SETUP_DATA({ dispatch,rootState  } ) {
        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/setup_data`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET',
            }, { root : true});
    },

    async SETUP_WEBSITE({ dispatch,rootState  } ,body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/setup`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body,
            }, { root : true});


    },
    async EDIT_PUBLISH_STATUS({ dispatch,rootState  } ,body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/publish`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body,
            }, { root : true});

    },
    async SAVE_CHANGES({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/save_changes/${body}/${state.selectedLang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            }, { root : true});

    },
    async CANCEL_CHANGES({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/cancel_changes/${body}/${state.selectedLang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            }, { root : true});

    },
    async UNPUBLISH_PAGE({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/unpublish/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            }, { root : true});

    },

    async SET_COURSE_TEMPLATE({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/course/template`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },
    async GET_COURSE_TEMPLATE({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/course/template/${body.course}/${body.lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async SAVE_CHANGES_ALL({ dispatch,rootState }   ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/publish_all/${state.selectedLang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            }, { root : true});

    },

    // Site Settings

    async SAVE_SITE_SETTINGS({ dispatch,rootState } ,body   ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/settings`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },


    // Business
    async GET_BUSINESS_AVALON({dispatch,rootState},body) {

        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/business${body.public ? '/public' : '' }`,
                business : body.business,
                method:'GET'
            }, { root : true});

        if ( !result ) {
            return false
        }
        rootState.business.selectedBusiness = result.business
        if ( result.no_website ) {
            rootState.business.selectedBusiness.alias = result.alias
            rootState.business.selectedBusiness = result.business
            return { noWebsite : true }
        }

        state.config = result.config
        state.favicon = result.favicon
        state.faviconDark = result.favicon_dark
        state.mainMenu = result.main_menu || []
        state.header = result.header || { config : {} }
        state.footer = result.footer
        state.langs = result.langs
        state.langPrimary = result.lang_primary
        state.siteIsPublished = result.is_published



        rootState.business.selectedBusiness.alias = result.alias

        if ( body.public ) {
            rootState.business.selectedBusiness.alias = result.business.alias;
            rootState.business.selectedBusiness.modules = result.business.modules;
            result.business.payments.forEach( (payment)=>{
                rootState.cart.payments.push({text:payment.name,value:payment.id})
            })
        }

        return result.business

    },
    async GET_MENU_NAVIGATION({dispatch,rootState,state}) {

        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/navigation`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});
        if ( !result ) {
            return
        }

        state.folders = result.folders || []
        state.pagesSelect = result.pages || []
        state.coursesSelect = result.courses || []
        let homepages = result.pages.filter(el => el.homepage )
        let homepagesObject = {}

        homepages.forEach(page=> {
            homepagesObject[page.lang] = page.value
        })

        state.homepage = homepagesObject
    },
    async ADD_LANGUAGE({ dispatch,rootState } ,body  ) {

        return  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/language`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body,
            }, { root : true});

    },

    // Header
    async EDIT_HEADER({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/header`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },
    async ADD_MENU_ITEM({ dispatch,rootState, state } ,body  ) {

        let data = {}
        if (body)  {
            data.parent = body
        }
        let result =  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/menu/item`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : data,
            }, { root : true});

        if ( !result ) {
            return false
        }
        if ( !body ) {
            state.mainMenu.push(result)
        } else {
            let item = state.mainMenu.find(el => el.value === body )
            if ( !item ) {
                return false
            }
            item.children.push(result)
        }


    },

    async ADD_EDIT_FOLDER({ dispatch, rootState ,state } , body  ) {
        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/folder`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

        if ( !result ) {
            return false
        }
        if ( !state.displayEditFolderDialog ) {
            state.folders.push(result)
        } else {
            let index = state.folders.findIndex( el=> el.value === result.value)
            if (index !== -1 ) {
                state.folders[index] = result
                state.folders = JSON.parse(JSON.stringify(state.folders))
            }
        }


        return result

    },
    async DELETE_FOLDER({ dispatch, rootState ,state } , body  ) {
        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/folder`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
                data : body
            }, { root : true});

        if ( !result ) {
            return false
        }
        let index = state.folders.findIndex( el=> el.value === body.id)
        if (index !== -1 ) {
            state.folders.splice(index , 1 )
        }
        if ( body.delete_content ) {
            state.pagesSelect = state.pagesSelect.filter( el => el.folder !== body.id)
        } else {
            state.pagesSelect.forEach((page,index) => {
                if ( page.folder === body.id ) {
                    state.pagesSelect[index].folder = null
                }
            })
            state.pagesSelect = JSON.parse(JSON.stringify(state.pagesSelect))
        }


        return result

    },

    async EDIT_MENU_ITEM({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/menu/item`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },
    async DELETE_MENU_ITEM({ dispatch,rootState } , body  ) {

        let result =  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/menu/item/${body.value}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE'
            }, { root : true});

        if ( !result ) {
            return
        }
        if ( !body.parent ) {
            let index = state.mainMenu.findIndex(el => el.value === body.value)
            if ( index === -1 ) {
                return
            }
            state.mainMenu.splice(index, 1)
        } else {
            let parentIndex = state.mainMenu.findIndex(el => el.value === body.parent)
            if ( parentIndex === -1 ) {
                return
            }
            let index = state.mainMenu[parentIndex].children.findIndex(el => el.value === body.value)
            if ( index === -1 ) {
                return
            }
            state.mainMenu[parentIndex].children.splice(index, 1)
        }


    },

    async GET_FOOTER_DRAFT({ dispatch,rootState , state } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/footer/${state.selectedLang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_FOOTER_PUBLIC({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/footer/get/public`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async GET_MAIN_MENU_DRAFT({ dispatch,rootState }, body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/main_menu/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_MAIN_MENU({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/main_menu/get/public`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },

    async GET_TEMPLATE_PAGES({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/template/pages/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_TEMPLATE_PAGE({ dispatch,rootState }, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/template/page/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_TEMPLATE_BLOCKS({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/template/blocks/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_TEMPLATES_CONFIG({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/template/config`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async EDIT_SITE_CONFIG({ dispatch,rootState  } ,body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/config`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body,
            }, { root : true});

    },

    async GET_COLOR_PALETTES({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/template/palettes`,
                method:'GET'
            }, { root : true});

    },



    // ADMIN
    async GET_ADMIN_TEMPLATES({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/templates`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_ADMIN_TEMPLATE({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/template/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_ADMIN_TEMPLATE_PAGES({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route: !body ?  `avalon/admin/pages` : `avalon/admin/template/${body}/pages` ,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_ADMIN_TEMPLATE_BLOCKS({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route: !body ?  `avalon/admin/blocks` : `avalon/admin/template/${body}/blocks` ,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_ADMIN_TEMPLATE_BLOCK({ dispatch,rootState } , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/blocks/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async ADD_SITE_TEMPLATE({ dispatch,rootState } ,body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/template`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },

    // Categories
    async ADD_BLOCK_CATEGORY({ dispatch,rootState } ,body  ) {

        return  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/categories/block`,
                method:'PUT',
                data : body,
            }, { root : true});

    },


    // Image libraries

    async GET_UNSPLASH_BROWSER({ dispatch,rootState } ,body  ) {

        return  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/unsplash/photos`,
                method:'POST',
                data : body,
            }, { root : true});

    },












}

export default {
    namespaced:true,
    actions,
    state
}
<template>
  <div>
    <slot :time="timeLeftFormatted"
          :time-value="timeLeft"
          :start="restartCountdown"
          :pause="stopCountdown"
          :proceed="startCountdown"
          :finished="timeLeft < 1"
    >
      <h5>{{  timeLeft  }}</h5>
    </slot>
  </div>

</template>

<script>
export default {
  name: "wsTimer",
  props : {
    seconds : {
      type : [String,Number],
      default : 60
    },
    startOnMount : {
      type : Boolean,
      default : true
    },
    restartTrigger : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      timeLeft : 60,
      timerId : null,
    }
  },
  watch : {
    restartTrigger() {
      this.restartCountdown()
    },
  },
  computed : {
    timeLeftFormatted() {
      let days = Math.floor(this.timeLeft / (60 * 60 * 24)); // Calculate the days
      let hours = Math.floor((this.timeLeft % (60 * 60 * 24)) / (60 * 60)); // Calculate the remaining hours after subtracting days
      let minutes = Math.floor((this.timeLeft % (60 * 60)) / 60); // Calculate the remaining minutes after subtracting hours
      let seconds = this.timeLeft % 60; // Calculate the remaining seconds

      // Formatting
      days = days > 0 ? days : ''; // If days exist, show them
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (days) {
        return `${this.$tc('day' , days)} ${hours}:${minutes}:${seconds}`; // If there are days, show days:hours:minutes:seconds
      } else if (hours > 0) {
        return `${hours}:${minutes}:${seconds}`; // If there are no days but hours exist, show hours:minutes:seconds
      } else {
        return `${minutes}:${seconds}`; // Otherwise, show minutes:seconds
      }
    }
  },
  methods: {
    restartCountdown(){
      this.timeLeft = this.seconds
      this.stopCountdown()
      this.startCountdown()
      this.$emit('restart')
    },
    startCountdown() {
      this.timerId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft -= 1; // decrease time left by 1 every second
        } else {
          this.stopCountdown(); // stop the countdown when it reaches 0
          this.timerFinished(); // call the event or method that should be triggered when the timer finishes
        }
      }, 1000); // 1000 milliseconds = 1 second
    },
    stopCountdown() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.$emit('pause')
      }
    },
    timerFinished() {
      this.$emit('finish');
    },
  },
  mounted() {
    if ( this.startOnMount ) {
      this.restartCountdown()
    }
  }
}
</script>

<style scoped>

</style>
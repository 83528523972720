<template>
  <v-hover #default="{hover}">
    <div>
      <v-sheet  color="transparent"  class="d-flex align-center justify-space-between" style="width: 100%">
        <h5  v-if="label"
             :style="`color : ${fontColor ||wsDARKER}`"
             style="line-height: 1.5; user-select: none;cursor: default; padding: 14px 0"
             :class="[{'font-weight-regular' : light}]"
        >
          <v-icon v-if="icon" :color="wsACCENT"> {{ icon }}</v-icon>
          {{ $t(label) }}
        </h5>
        <div  style="width: 70px; height: 46px;"  class="d-flex align-center justify-end">
          <v-text-field
              ref="valueText"
              background-color="transparent"
              @change="paramValue = bufferValue; $emit('change' , paramValue)"
              v-model="bufferValue"
              class="text-right"
              style="font-size: 13px; font-weight: 600;"
              hide-details
              reverse
              solo
              flat
          />
          <h5 :style="`color : ${valueColor}`" class="ml-1">{{ unit }}</h5>
        </div>
      </v-sheet>
      <div >
        <v-slider
            v-model="paramValue"
            @change="$emit('change' , paramValue)"
            @input="bufferValue = paramValue"
            :color="!hover ?  wsACCENT : wsDARKER "
            :track-color="hover ? wsACCENT :  (trackColor || (wsBACKGROUND))"
            :height="trackHeight || '5'"
            hide-details
            :min="min"
            :max="max"
            :step="step"
            class="px-0 wsSlider pointer"
            :class="[{'lightTrack' : light}]"
            style="width: 100%"

        />
      </div>


    </div>
  </v-hover>

</template>

<script>
export default {
  name: "aParameterSlider",
  props : {
    value : {
      default : 0
    },
    label : {
      type : String,
      default : ''
    },
    trackColor : {
      type : String,
    },
    fontColor : {
      type : String,
    },
    valueColor : {
      type : String,
    },
    trackHeight : {
      type : String,
    },
    default : {
      type : Number,
      default : 0
    },
    max : {
      type : String,
      default : '100'
    },
    min : {
      type : String,
      default : '0'
    },
    icon : {
      type : String,
    },
    light : {
      type : Boolean,
      default : false
    },
    unit : {
      type : String,
    },
    step : {
      type : Number,
      default : 1
    }
  },
  data() {
    return {
      paramValue : 0,
      bufferValue : 0,
    }
  },
  computed : {
    uuid() {
      return this.$uuid.v4()
    }
  },
  watch : {
    value() {
      if ( this.value !== this.paramValue ) {
        this.paramValue = this.value
        this.bufferValue = this.value
      }
    },
    paramValue() {
      if ( this.value !== this.paramValue ) {
        this.$emit('input',this.paramValue)
      }
    }
  },
  mounted() {

      this.$nextTick(()=> {
        let valueTextfield = this.$refs.valueText

        let input = valueTextfield.$el.querySelector('input')
        let inputSlot = valueTextfield.$el.querySelector(".v-input__slot")
        inputSlot.style.padding = 0
        if ( this.valueColor ) {
          input.style.color = this.valueColor
        }

      })


    this.paramValue = this.value
    if ( !this.paramValue ) {
      this.paramValue = this.default
    }
    this.bufferValue = this.value
  }
}
</script>

<style scoped>
.wsSlider >>> .v-slider--horizontal  {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.lightTrack >>> .v-slider__track-container {
  height : 1px !important
}
</style>
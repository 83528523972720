const actions = {

    // Chat Bots linking
    async GET_CHATBOTS_STATUS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/status`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async LINK_TELEGRAM_BOT({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/link/telegram`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async UNLINK_TELEGRAM_BOT({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/unlink/telegram`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            }, {root : true});
    },
    // Chat Bot Constructor
    async GET_CHATBOTS_CARDS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/constructor`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async EDIT_CARD({dispatch,rootState}, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/constructor/card`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async DELETE_CARD({dispatch,rootState}, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/constructor/card`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
                data : body
            }, {root : true});
    },

    async ADD_CARD_BUTTON({dispatch,rootState}, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/constructor/card/button`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            }, {root : true});
    },
    async DELETE_CARD_BUTTON({dispatch,rootState}, body ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`business/chatbots/constructor/card/button`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
                data : body
            }, {root : true});
    }



}

export default {
    namespaced:true,
    actions,
}
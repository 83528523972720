<template>
  <div class="d-flex justify-center ">
    <div class="d-flex justify-center mt-2 wsRoundedSemi overflow-hidden"
         style="border : 1px solid var(--wsACCENT); "
    >
      <div v-for="(item,i) in items" :key="'type' + i" class="d-flex" >

        <v-hover #default="{hover}">
          <v-sheet
              @click="handleAction(item)"
              class="pa-3 pointer"
              :color="hover ? (wsACCENT+33) : 'transparent'"
              v-ripple
          >
            <h5 class="wsACCENT">{{ item.textLang ? $t(item.textLang) : item.text }}</h5>
          </v-sheet>
        </v-hover>

        <v-divider
            v-if="i !== items.length - 1"
            style="border-color : var(--wsACCENT)"
            vertical
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wsButtonPanel",
  props: {
    items : {
      type : Array,
      default() {
        return [
          { text : 'Button1' , value : 'Button1'},
          { text : 'Button2' , value : 'Button1'},
        ]
      }
    }
  },
  methods : {
    handleAction(item) {
      this.$emit('action' , item.value)
      if (item.action) {
        item.action()
      }
    }
  }
}
</script>



<style scoped>

</style>
const state = {
    disciplineExpand : [],
    navigationSelect : 0,
    displayAllComponentsEvaluation : true
}

const actions = {

    async GET_COURSE_EVALUATION_COMPONENTS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/course/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_COMPONENT_STUDENTS_LIST({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/component/students/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_STUDENT_COMPONENTS_LIST({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/student/components/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async GET_STUDENT_NEW_REPLIES_LIST({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/course/replies/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_STUDENT_NEW_REPLIES_COUNT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/course/replies/${body}/count`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async EDIT_COMPONENT_EVALUATION({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/edit/component`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },
    async EDIT_COMPONENT_EVALUATION_SELF_CONTROL({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/edit/component/self_control`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },
    async GET_TASK_REPLIES({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/component/replies/get`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async RESET_STUDENT_REPLY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/reset_reply/${body}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async RESET_STUDENT_TEST_TRIES({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/reset_test`,
                method:'DELETE',
                business: rootState.business.selectedBusiness.alias,
                data: body
            },{ root : true });
    },


    async ADD_EDIT_TEACHER_REPLY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/teacher_reply`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    async DELETE_TEACHER_REPLY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`evaluation/teacher_reply/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },





















}

export default {
    namespaced:true,
    actions,
    state
}
<template>
  <div class="overflow-hidden">
    <div style="min-width: 800px" class="overflow-x-auto">
      <div class="d-flex flex-row justify-space-between "
           style="position: relative"
      >

        <v-row no-gutters>
          <v-col cols="2" v-for="(item,i) in items" :key="i"
                 :class="[{'pl-3' : i !== 0 }]"
          >
            <v-sheet
                style="border: 1px solid var(--wsBACKGROUND);"
                class="wsRoundedSemi flex-grow-1 pa-4"
            >
              <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
              <h5 style="font-size: 12px" class="py-1 wsACCENT">{{ item.title }}</h5>
              <h3 style="font-size: 19px" class="wsACCENT">
                {{ item.text }}
                <span class="font-weight-regular" v-if="item.text_regular">({{ item.text_regular }})</span>
              </h3>
            </v-sheet>
          </v-col>
        </v-row>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wsStatRow",
  props : {
    items : {
      type : Array,
      default() { return [] }
    }
  }
}
</script>

<style scoped>

</style>
const state = {

    pageData : {
        filters : []
    },
    filtersData : {},


    filter : {
        date : [],
        period : null,
    },
    statisticsDefault :
      [
            {
                breakpoint: "",
                numberOfCols: 12,
                items: [
                    { id: 1, x: 0, y: 0,
                        width: 8, height: 3 ,
                        minWidth : 4,
                        minHeight : 2,
                        heightPx : 300,
                        resizing : false,
                        item : { block : 'statisticsOrders' } },
                ]
            },
        ],

}
const actions = {


    async GET_STUDY_STATISTICS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`statistics/study`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET',
            }, {root : true});
    },







    // OLD STUFF
    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async GET_PRODUCTS_STATISTICS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`statistics/${rootState.business.selectedBusiness.alias}/products`,
                method:'POST',
                data : body
            });
    },
    async GET_ORDERS_STATISTICS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`statistics/${rootState.business.selectedBusiness.alias}/orders`,
                method:'POST',
                data : body
            });
    },
    async GET_WAREHOUSES_STATISTICS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`statistics/${rootState.business.selectedBusiness.alias}/warehouses`,
                method:'POST',
                data : body
            });
    },
    async GET_CLIENTS_STATISTICS_STATS({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`statistics/${rootState.business.selectedBusiness.alias}/clients/stats`,
                method:'POST',
                data : body
            });
    },
    async GET_CLIENTS_STATISTICS_FULL({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`statistics/${rootState.business.selectedBusiness.alias}/clients/full`,
                method:'POST',
                data : body
            });
    },



}

export default {
    namespaced:true,
    state,
    actions,
}
/*
    YYYY?           |  null|2023                                 |  Shows year if it is different than current
    YYYY            |  2024                                      |  Current Year
    MM              |  06                                        |  Current Month
    DD              |  24                                        |  Current Date
    hh              |  12                                        |  Current Hours
    mm              |  48                                        |  Current Minutes
    ss              |  36                                        |  Current Seconds

    YMDT            |  2024-06-24 12:48,                         |  Formated string, good for php to parse DateTime
    YMD             |  2024-06-24                                |  Formated string, good for date picker
    DMY             |  24-06-2024                                |  Ukrainian style date
    time            |  12:48,                                    |  Current Time

    Month           |  Червень,                                  |  Translated name of Current Month
    Today           |  Сьогодні        | 24 Червня               |  Composite translated string , returns Today , or Date with Month
    Day             |  Понеділок                                 |  Translated name of Current Day of Week
    regular()       |  Сьогодні        | 24 Червня, 2023         |  format('Today, YYYY?'), Returns Today, and shows year if it is different from current
    regularTime()   |  Сьогодні, 12:48 | 24 Червня,  2023, 12:48 |  The same as regular() but with current time
*/
class FineTime {

    // Constructor functions
    constructor(dateInput = new Date() , formatInput = null) {
        this.t = (text) => text;
        this.setTime(dateInput)
        if ( formatInput) {
            return this.format(formatInput)
        }
    }

    valueOf() {
        return this.date.getTime();
    }

    static create(dateInput = new Date(),i18n = null, formatInput = null) {

        const  fineTime = new FineTime(dateInput)

        fineTime.setVueParams(i18n)

        if ( formatInput) {
            return fineTime.format(formatInput)
        }

        return fineTime
    }

    setVueParams(i18n) {
        console.log('[fineTime:setVueParams]' , i18n)
        if (i18n) {
            this.t = (text) => i18n.t(text);
            this.prepareVariables();
        }
        return this
    }

    prepareVariables() {
        const today = new Date();
        this.isThisYear = this.date.getFullYear() === today.getFullYear();
        this.isToday =
            this.date.getDate() === today.getDate() &&
            this.date.getMonth() === today.getMonth() &&
            this.date.getFullYear() === today.getFullYear();

        this.Day = this.t(`Day_${this.date.getDay()}`) ;

        this.M = String(this.date.getMonth() + 1)
        this.MM = String(this.date.getMonth() + 1).padStart(2, '0');
        this.DD = String(this.date.getDate()).padStart(2, '0');

        this.hh = String(this.date.getHours()).padStart(2, '0');
        this.mm = String(this.date.getMinutes()).padStart(2, '0');
        this.ss = String(this.date.getSeconds()).padStart(2, '0');

        this.YYYY = this.date.getFullYear();

        this.Today = this.isToday? this.t('Today') : `${this.DD} ${this.t(`Month_${this.M}`)}`;
        this.Month = this.date.getMonth() + 1;
        this.time =`${this.hh}:${this.mm}`;

        return this
    }

    setTime(dateInput) {

        if (dateInput instanceof Date) {

            this.date = dateInput;

        } else if (!isNaN(dateInput)) {

            this.date = new Date(dateInput < 1e10 ? dateInput * 1000 : dateInput);

        } else if (typeof dateInput === 'string') {

            if (dateInput === 'tomorrow') {
                return this.tomorrow();
            }

            if (dateInput === 'today') {
                return this.today();
            }

            this.date = new Date(dateInput);

        } else {

            this.date = new Date();

        }

        this.prepareVariables();

        return this
    }

    // Set time

    tomorrow() {

        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.date = date;
        this.prepareVariables();
        return this;
    }

    today() {

        const date = new Date();
        this.date = date;

        return this;
    }

    todayUTC() {

        const date = new Date(  new Date().toISOString());
        this.date = date;

        return this;
    }



    // Modifications functions

    removeTime() {
        this.date.setHours(0, 0, 0, 0);
        return this
    }

    // Print time functions
    format(formatInput) {

        const replacements = {

            //Base values
            'YYYY': this.YYYY,
            'MM': this.MM,
            'DD': this.DD,
            'Day': this.Day,
            'hh': this.hh,
            'mm': this.mm,
            'ss': this.ss,
            // Composite values
            'YMDT': `${this.YYYY}-${this.MM}-${this.DD} ${this.hh}:${this.mm}`,
            'YMD': `${this.YYYY}-${this.MM}-${this.DD}`,
            'DMY': `${this.DD}-${this.MM}-${this.YYYY}`,
            'time': this.time,
            // Translations values
            'Month': this.Month,
            'Today': this.Today,
            // Unix
            'timestamp': Math.floor(this.date.getTime() / 1000)
        };


        let result = formatInput.replace(/YYYY\?|YYYY|MM|DD|hh|mm|ss|YMDT|YMD|DMY|time|Day|Month|Today/g, match => {
            if (match === 'YYYY?') {
                return !this.isThisYear ? this.YYYY : '';
            }
            return replacements[match];
        });

        // Cleanup
        result = result.replace(/,\s*,/g, ', ');        // Double commas
        result = result.replace(/,\s*$/, '');           // Trailing commas
        result = result.replace(/^\s*,/, '');           // Leading commas
        result = result.replace(/\s*,\s*$/, '');        // Commas with surrounding spaces at the end
        result = result.replace(/-\s*-/, '-');          // Double dashes
        result = result.replace(/-\s*$/, '');           // Trailing dashes
        result = result.replace(/^\s*-/, '');           // Leading dashes
        result = result.replace(/\s*-\s*$/, '');        // Dashes with surrounding spaces at the end
        result = result.replace(/\/\s*\//g, '/');       // Double slashes
        result = result.replace(/\/\s*$/, '');          // Trailing slashes
        result = result.replace(/^\s*\//, '');          // Leading slashes
        result = result.replace(/\s*\/\s*$/, '');

        return result.trim();
    }

    phpTimestamp() {
        return parseInt(Math.floor(this.date.getTime() / 1000))
    }

    regular() {
        return this.format('Today, YYYY?')
    }

    regularTime() {
        return this.format('Today, YYYY?, time')
    }

    time() {
        return this.format('time')
    }

    YMD() {
        return this.format('YMD')
    }

    MDY() {
        return this.format('MDY')
    }

    YMDT() {
        return this.format('YMDT')
    }
}

export default FineTime;
<template>
  <ws-dialog
      @save="API('ADD_EDIT_ACHIEVEMENT')"
      v-if="$store.state.entitiesFunctions.displayDialog"
      v-model="$store.state.entitiesFunctions.displayDialog"
      :title="$store.state.entitiesFunctions.newEntity ? $t(`${title}.add`) : $t(`${title}.edit`)"
      :show-delete="!$store.state.entitiesFunctions.newEntity"
  >
    <slot name="default"></slot>
  </ws-dialog>
</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";

export default {
  name: "wsDeleteDialog",
  mixins : [entitiesFunctions],
  props : {
    title : {
      type : String,
    },
    method : {
      type : String,
    },

  }
}
</script>

<style scoped>

</style>
const state = {
    credits : 0
}
const actions = {

    // :: COURSES ::
    async GET_CLIENT_BONUS_CREDITS({dispatch,rootState,state}) {

        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`bonus_credits`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});
        if ( !result ) {
            return
        }
        state.credits = result.credits || 0
    },







}

export default {
    namespaced:true,
    actions,
    state

}
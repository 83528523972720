<template>
  <div  style="width: 100%; max-width: 740px;">
    <h2 class="wsDARKER mb-2"
        :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
        :style="`font-size: ${!SM ? 24 : 19}px`"
    >
      {{  title  }}
    </h2>

    <div
        style="border : 1px solid var(--wsBACKGROUND);"
        class="wsRoundedLight mb-3"
        v-for="(item,i ) in itemsArray" :key="i"
    >
      <div @click="item.expanded = !item.expanded"
          style="background-color: var(--wsLIGHTCARD)"
           class="pa-3 px-4 d-flex justify-space-between align-center pointer">
        <h4 class="wsDARKER">
          <span v-if="numbered">{{ i+1 }}. </span>
          {{ item.title }}
        </h4>
        <v-icon :color="wsACCENT">mdi-chevron-{{item.expanded ? 'up' : 'down'}}</v-icon>
      </div>

      <v-expand-transition>
        <div v-if="item.expanded">
          <v-divider style="border-color: var(--wsBACKGROUND)"></v-divider>
          <div class="pa-3">
            <slot name="expanded.content" :item="item">
              <h5 class="font-weight-regular wsDARKER" style="line-height: 1.7">{{  item.text  }}</h5>
            </slot>

          </div>
        </div>
      </v-expand-transition>

    </div>

    <ws-button class="mt-3" label="ApplyOnCourse" />

  </div>

</template>

<script>
export default {
  name: "wsAccordionVariant",
  props : {
    title : {
      type : String
    },
    items : {
      type : Array,
      default() { return [] }
    },
    numbered : {
      type : Boolean,
      default: false
    }
  },
  data() {
    return {
      itemsArray :[]
    }
  },
  watch : {
    items : {
      handler() {
        this.itemsArray = this.validateItems(this.items)
      }
    }
  },
  methods : {
    validateItems(items) {
      let newItems = this.COPY(items)
      for (let index in items) {
        if (!Object.keys(newItems[index]).includes('expanded')) {
          newItems[index].expanded = false
        }
      }

      return this.COPY(newItems)

    }
  },
  mounted() {
    this.itemsArray = this.validateItems(this.items)
  }
}
</script>



<style scoped>

</style>
<template>
  <ws-dialog
      v-if="$store.state.entitiesFunctions.displayDeleteDialog"
      v-model="$store.state.entitiesFunctions.displayDeleteDialog"
      :title="$t(`${title}.delete`)"
      display-confirm-delete
      close-delete
      @delete="API(method)"
  />
</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";

export default {
  name: "wsDeleteDialog",
  mixins : [entitiesFunctions],
  props : {
    title : {
      type : String,
    },
    method : {
      type : String,
    },

  }
}
</script>

<style scoped>

</style>
const state = {
    triggerClientCard : 0,
}
const actions = {

    async GET_BUSINESS_CLIENTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/clients`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async GET_BUSINESS_CLIENTS_SELECT({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/clients/select`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async GET_BUSINESS_CLIENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async ADD_BUSINESS_CLIENT_EVENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/event`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body,
            }, {root : true});
    },
    async GET_BUSINESS_CLIENT_EVENTS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/${body}/events`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async BUSINESS_CRM_GET_MANAGERS_SELECT({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/managers/select`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async GET_CLIENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/${body}/profile`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            },{ root : true });
    },
    async ADD_CLIENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`crm/client/new`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, {root : true});
    },
    async DELETE_CLIENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`crm/client/delete/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            }, {root : true});
    },


    async EDIT_CLIENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/profile`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data: body,
            },{ root : true });
    },
    async ADD_NEW_TAG({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/tag`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data: body,
            },{ root : true });
    },

    async GET_CLIENT_ORDERS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/${body}/orders`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            },{ root : true });
    },
    async GET_CLIENT_COURSES({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/client/${body}/courses`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            },{ root : true } );
    },

    // Custom fields

    async ADD_CUSTOM_FIELD({ dispatch,rootState  } ,body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/custom_field`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body,
            }, { root : true});


    },
    async DELETE_CUSTOM_FIELD({ dispatch,rootState  } ,body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/custom_field/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            }, { root : true});


    },

    async GET_CRM_SETTINGS({ dispatch,rootState  }   ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`crm/settings`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET',
            }, { root : true});


    },






}

export default {
    namespaced:true,
    state,
    actions,
}
<template>
  <div class="d-flex justify-center">

    <div :class="[{'px-2' : SM  }]" style="max-width: 760px; width: 100%"  >

      <v-row>
        <v-col cols="12" :md="6" class="mx-0 px-0 mt-0 pt-0">
          <router-link
              v-if="PREVIOUS_COMPONENT(componentUuid)"
              :is="PREVIOUS_COMPONENT(componentUuid).is_permitted ? 'router-link' : 'span'"
              :to="businessDashLink('courses/component/' + PREVIOUS_COMPONENT(componentUuid).uuid )"

              class="noUnderline"
          >

            <v-sheet
                @click="scrollTop"
                width="100%"
                class="d-flex align-center "
                :v-ripple="PREVIOUS_COMPONENT(componentUuid).is_permitted"
                :style="`border : 1px solid ${PREVIOUS_COMPONENT(componentUuid).is_permitted ? wsBACKGROUND : 'grey'}`"
                :class="[
                  { 'pa-6' : !SM },
                  { 'pa-3' : SM },
                  {'wsRoundedLightLeft' : NEXT_COMPONENT(componentUuid) && !SM },
                  {'wsRoundedHalf' : !NEXT_COMPONENT(componentUuid) || SM },
                  {'pointer' : PREVIOUS_COMPONENT(componentUuid).is_permitted }
              ]"
            >
              <v-icon :color="PREVIOUS_COMPONENT(componentUuid).is_permitted ? wsACCENT : 'grey'" class="mr-5">mdi-arrow-left</v-icon>
              <div>
                <h4  :style="`color : ${PREVIOUS_COMPONENT(componentUuid).is_permitted ? wsACCENT : 'grey'}`"> {{ $t('GoBack') }}</h4>
                <h5 class="cut-text" :style="`color : ${PREVIOUS_COMPONENT(componentUuid).is_permitted ? wsDARKLIGHT : 'grey'}`">{{ PREVIOUS_COMPONENT(componentUuid).name }}</h5>
              </div>

            </v-sheet>
          </router-link>
        </v-col>
        <v-col  cols="12" :md="6" class="mx-0 px-0 pt-0 ">
          <router-link
              v-if="NEXT_COMPONENT(componentUuid)"
              :is="NEXT_COMPONENT(componentUuid).is_permitted ? 'router-link' : 'span'"
              :to="businessDashLink('courses/component/' + NEXT_COMPONENT(componentUuid).uuid )"
              class="noUnderline"

          >
            <v-sheet
                @click="scrollTop"
                width="100%"
                :color="NEXT_COMPONENT(componentUuid).is_permitted ? wsATTENTION : 'grey' "
                :style="`border : 1px solid ${NEXT_COMPONENT(componentUuid).is_permitted ? wsATTENTION : 'grey'} !important`"
                class="d-flex justify-end align-center"
                :class="[
                  { 'pointer' : NEXT_COMPONENT(componentUuid).is_permitted },
                  { 'pa-6' : !SM },
                  { 'pa-3' : SM },
                  {'wsRoundedLightRight' : PREVIOUS_COMPONENT(componentUuid) && !SM },
                  {'wsRoundedHalf' : !PREVIOUS_COMPONENT(componentUuid) || SM },
                  {'pointer' : NEXT_COMPONENT(componentUuid).is_permitted }
              ]"
                :v-ripple="NEXT_COMPONENT(componentUuid).is_permitted"
                dark
            >

              <div>
                <h4 class="text-right">{{ $t('Next') }}</h4>
                <h5 class="text-right cut-text"> {{ NEXT_COMPONENT(componentUuid).name }}</h5>
              </div>

              <v-icon v-if="NEXT_COMPONENT(componentUuid).is_permitted" class="ml-5">mdi-arrow-right</v-icon>
            </v-sheet>
          </router-link>
        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>
export default {
  name: "componentNavigation",
  props : {
    component : {
      type : Object,
      default() { return {} }
    },
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
    componentUuid() {
      if (this.entity.component_id) {
        return this.entity.component_id
      }
      if ( this.component.uuid ) {
        return this.component.uuid
      }
      return null
    }
  },
  watch : {
    component : {
      handler() {
        this.INIT_STUDENT_COURSE( this.component.course_id)
      },
      deep : true
    },
    entity : {
      handler() {
        this.INIT_STUDENT_COURSE( this.entity.course_id)
      },
      deep : true
    }
  },
  methods : {
    scrollTop() {
      window.scrollTo(0, 0)
    },
    displayEntity( component ) {

      let url  = null
      if (  !component.is_permitted ) { return }
      this.notify('try to open: '  + component.entity_type  , 'success')
      switch(component.entity_type) {
        case 'lecture'    :  url = component.lecture ? 'courses/lecture/' + component.lecture.value : null ; break;
        case 'task'       :  url = component.task    ? 'courses/task/'    + component.task.value    : null ; break;
        case 'task_test'  :  url = component.task    ? 'courses/task/'    + component.task.value    : null ; break;
        case 'task_video' :  url = component.task    ? 'courses/task/'    + component.task.value    : null ; break;
        case 'file'       :  url = 'courses/file/'    + component.uuid  ; break;
        case 'video'      :  url = 'courses/file/'    + component.uuid       ; break;
      }

      if ( url ) {
        this.$router.push(this.businessDashLink(url))
        return this.businessDashLink(url)
      }
      return false
    },
  },
  mounted() {
    if ( this.component.uuid ) {
      this.INIT_STUDENT_COURSE(this.component.course_id)
    }
    if ( this.entity.component_id ) {
      this.INIT_STUDENT_COURSE(this.entity.course_id)
    }

  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template>

    <v-sheet
        v-if="items.length > 0"
        :color="color"
        dark
        class="d-flex justify-space-between pa-3 notification"
        :style="!top ? 'bottom: 0;' : 'top : 0'"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
    >

      <template v-if="!messenger">
        <v-icon>{{ getEventIcon(firstItem.type)  }}</v-icon>

        <div style="max-width: 600px ">
          <h5 class="font-weight-light">{{ firstItem.text }}</h5>
        </div>

        <v-icon class="pointer"  @click="$store.state.notification = false" >mdi-close</v-icon>
      </template>

      <template v-else>
        <v-icon>mdi-mail</v-icon>

        <div v-if="lastItem.payload" style="max-width: 600px ">
          <h5 v-if="lastItem.payload.chat">{{ lastItem.payload.chat.name }}</h5>
          <h5 v-if="lastItem.payload.message"  class="font-weight-light">{{ lastItem.payload.message.text.slice(0,25) }}</h5>
        </div>

        <v-icon class="pointer"  @click="$emit('close')" >mdi-close</v-icon>

      </template>

    </v-sheet>

</template>

<script>
export default {
  name: "wsNotification",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    top : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      pause : false
    }
  },
  watch : {
    items : {
      handler() {
        setTimeout(() => {
          if ( !this.pause ) {
            this.$emit('close')
          }
        },2500)
      },
      deep : true
    }
  },
  computed : {
    messenger() {
      if (!this.firstItem ) {
        return false
      }
      if ( this.firstItem.event ===  'messenger_new_message' ) {
        return true
      }

      return false
    },
    color() {

      if (!this.firstItem.type) {
        return this.wsACCENT
      }

      switch(this.firstItem.type) {
        case 'business_user' : return this.wsSUCCESS
        case 'business_lead' : return this.wsSUCCESS
        case 'student_reply_file' : return this.wsACCENT
        case 'order_course' : return this.wsSUCCESS
        case 'order_course_payment' : return this.wsSUCCESS
        default : return this.wsACCENT
      }

    },
    firstItem() {
      if (this.items.length === 0) {
        return {}
      }
      return this.items[0]
    },
    lastItem() {
      if (this.items.length === 0) {
        return {}
      }
      return this.items[this.items.length - 1]
    }
  },
  methods : {
    mouseOver() {
      if (!this.pause) {
        this.pause = true
      }
    },
    mouseOut() {
      this.pause = false
        setTimeout(() => {
          if ( !this.pause ) {
            this.$emit('close')
          }
        },1000)

    }
  },
  mounted() {
    setTimeout(() => {
      if ( !this.pause ) {
        this.$emit('close')
      }
    },2500)
  }
}
</script>

<style scoped>
.notification {
  position : fixed;
  padding: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2147483647;
}
</style>
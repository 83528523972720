<template>
  <v-menu
      v-model="expand"
      offset-y
      :close-on-click="false"
      :close-on-content-click="false"
      max-height="260"
  >
    <template #activator="{ on, attrs }">
      <v-sheet
          @click="expand = true"
          v-bind="attrs"
          class="d-flex align-center"
          color="transparent"
          >
        <div class="d-flex align-center"     style="position: relative; width: 100%">
          <h5 v-if="!search" style="position:absolute;  pointer-events: none; opacity: 0.3;" :style="textStyle">
            {{ placeholder || $t('EnterText') }}
          </h5>
          <input
              ref="input"
              class="input"
              v-model="search"
              @blur="handleBlur"
              style="width: 100%"
              :style="textStyle"
          >
        </div>
        <v-icon
            @mouseover="mouseOverDropdown = true"
            @mouseleave="mouseOverDropdown = false"
            v-if="search && clearable && expand"
            @click="clear" :color="wsACCENT" >mdi-close-circle</v-icon>


      </v-sheet>
    </template>


    <v-sheet
        @mouseover="mouseOverDropdown = true"
        @mouseleave="mouseOverDropdown = false"
    >
      <v-hover v-for="(item , i) in itemsFiltered" :key="i"  #default="{hover}">
        <v-sheet
            @click="selectItem(item)"
            :color="hover ? wsLIGHTCARD : 'transparent'"
            class="pa-3 pointer" >

          <slot name="item" :item="item" :element="item">
            <h5 :style="`color : ${wsACCENT}`" >
              {{ item.text ? item.text : item }}
            </h5>
          </slot>

        </v-sheet>

      </v-hover>
      <h5 class="pa-3" :style="`color : ${wsACCENT}`" v-if="itemsFiltered.length === 0 ">
        {{ noItemsText || $t('NoItemsFoundByQuery')  }} : "{{ search }}"
      </h5>
    </v-sheet>

  </v-menu>


</template>

<script>
export default {
  name: "wsInputSelector",
  props : {
    value : {},
    items : {
      type : Array,
      default() {return [] }
    },
    noItemsText : {
      type : String
    },
    clearable : {
      type : Boolean,
      default : true
    },
    placeholder : {
      type : String
    },
    inputStyle : {
      type : String
    }
  },
  data() {
    return {
      itemValue : '',
      itemName : 'false',
      search : '',
      expand : false,
      mouseOverDropdown : false,
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items

      if ( this.search && this.search !== this.itemName ) {
        items = items.filter(el => el.text.toLowerCase().includes(this.search.trim().toLowerCase())  )
      }

      return items
    },
    textStyle() {
      if ( this.inputStyle ) {
        return this.inputStyle
      }

      return `font-size : 16px ; font-weight : bold; color : ${this.wsDARKER}`
    }
  },
  methods : {
    clear() {
      this.search = ''
      this.itemName = ''
      this.itemValue = null
      setTimeout(() => {
        this.$refs.input.focus()
      },1)
    },
    selectItem(item) {
      this.search = item.text
      this.itemName = item.text
      this.itemValue = item.value
      this.expand = false
    },
    handleBlur() {
      if ( !this.mouseOverDropdown ) {
        this.expand = false
      }
    },
  },
  watch : {
    itemValue() {
      if ( this.itemValue !== this.value) {
        this.$emit('input' , this.itemValue)
      }
    },
    value() {
      if ( this.itemValue !== this.value) {
        let item = this.items.find(el => el.value === this.value )
        if ( item ) {
          this.itemValue = this.value
          this.itemName = item.text
          this.search = item.text
        } else {
          this.itemValue = null
          this.itemName = null
          this.search = null
        }
      }
    }
  },
  mounted() {
    if ( this.value ) {
      let item = this.items.find(el => el.value === this.value )
      if ( item ) {
        this.itemValue = this.value
        this.itemName = item.text
        this.search = item.text
      }
    }
  }
}
</script>

<style scoped>
.input {
  outline: none;
}
</style>
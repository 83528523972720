const state = {
      cart              : {},
      showCart          : false,
      showCheckout      : false,
      quantity          : 0,
      deliveries        : [],
      payments          : [],
      unfinishedPayment : false,
      quickDeliveryField: {
          string : '',
          street_name : '',
          street_number: '',
          complete : false
      },

}
const mutations = {

    // ADD_CART_ITEM(state,body){
    //
    //     if (!state.cart[body.id]) {
    //         body.quantity = 1  } else {
    //         body.quantity++
    //     }
    //     state.cart = Object.assign({},state.cart,{ [body.id] : body})
    //     state.quantity++
    //
    //     if ( body.rentedProduct ) {
    //         if (!state.cart[body.rentedProduct.id]) {
    //             body.rentedProduct.quantity = 1  } else {
    //             body.rentedProduct.quantity++
    //         }
    //         state.cart = Object.assign({},state.cart,{ [body.rentedProduct.id] : body.rentedProduct})
    //         state.quantity++
    //     }
    //
    // },
    // REMOVE_CART_ITEM(state,body){
    //
    //
    //     body.quantity--;
    //     if ( body.rentedProduct ) {
    //         body.rentedProduct.quantity--;
    //     }
    //     if(body.quantity > 0) {
    //         state.cart = Object.assign({},state.cart,{ [body.id] : body})
    //         if ( body.rentedProduct ) {
    //             state.cart = Object.assign({},state.cart,{ [body.rentedProduct.id] : body.rentedProduct})
    //         }
    //
    //     } else {
    //         delete state.cart[body.id]
    //         state.cart = Object.assign({},state.cart)
    //         if ( body.rentedProduct ) {
    //             delete state.cart[body.rentedProduct.id]
    //             state.cart = Object.assign({},state.cart)
    //         }
    //     }
    //
    //     state.quantity--
    //
    // },
    REMOVE_CART_ITEM_ALL(state,body){

        state.quantity = parseFloat(state.quantity) - parseFloat(body.quantity)
        delete state.cart[body.id]
        state.cart = Object.assign({},state.cart)

    },

}
const actions = {
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.business) {
                params.business = body.business
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async MAKE_ORDER({dispatch,rootState},body) {
        let public_route = rootState.auth.userID ? '' :   'public/'
        if ( rootState.auth.userID  ) {
            body.data.user = rootState.auth.userID
        }
        let token = body.data.user ? rootState.auth.accessToken : 'public'
        return await dispatch('AJAX_SEND',{
            token:token ,
            route:`order/${public_route}${body.alias}`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: body.data
        });
    },

    ADD_CART_ITEM({state,rootState}, product){

        var body = JSON.parse(JSON.stringify(product))



            if (!state.cart[body.id]) {
            body.quantity = 1
            if (body.min_quantity) {
                body.quantity = body.min_quantity
                state.quantity += (body.min_quantity-1)
            }

        } else {
            body.quantity = state.cart[body.id].quantity + 1

        }
        var rentObj = null
        if ( body.rentedProduct) {
            rentObj = JSON.parse(JSON.stringify(body.rentedProduct)) ;
            delete body['rentedProduct']
        }
        state.cart = Object.assign({},state.cart,{ [body.id] : body})
        state.quantity++

        if ( rentObj ) {
            var add = true;
            if ( rootState.auth.userRentedProducts ) {
                if ( rootState.auth.userRentedProducts[rentObj.id] ) {
                    add = state.cart[body.id].quantity > rootState.auth.userRentedProducts[rentObj.id].quantity
                }
            }
            if ( add ) {
                if (!state.cart[rentObj.id]) {
                    rentObj.quantity = 1
                    if (body.min_quantity) {
                        rentObj.quantity = body.min_quantity
                        state.quantity += (body.min_quantity-1)
                    }

                }

                else {
                    rentObj.quantity = state.cart[rentObj.id].quantity + 1;
                }
                state.cart = Object.assign({},state.cart,{ [rentObj.id] : rentObj})
                state.quantity++
            }

        }

    },
    REMOVE_CART_ITEM({state},product){

        var body = JSON.parse(JSON.stringify(product))
        let limitQuantity = body.min_quantity ? (body.min_quantity -1) : 0

        state.cart[body.id].quantity --;
        state.quantity--;

        if ( state.cart[body.id].quantity === limitQuantity  ) {
            delete state.cart[body.id]
            state.quantity -=limitQuantity
        }

        if ( body.rentedProduct ) {
            state.cart[body.rentedProduct.id].quantity --;
            state.quantity--;
            if ( state.cart[body.rentedProduct.id].quantity === 0  ) {
                delete state.cart[body.id]
            }
        }

        // if ( body.rentedProduct ) {
        //     state.cart[body.rentedProduct.id].quantity --;
        //     state.quantity--;
        //
        //     state.cart[body.rentedProduct.id].quantity -=limitQuantity;
        //     state.quantity -=limitQuantity;
        //
        //     if ( state.cart[body.rentedProduct.id].quantity === limitQuantity  ) {
        //         delete state.cart[body.rentedProduct.id]
        //     }
        // }


        // body.quantity--;
        // if ( body.rentedProduct ) {
        //     body.rentedProduct.quantity--;
        // }
        // if(body.quantity > 0) {
        //     state.cart = Object.assign({},state.cart,{ [body.id] : body})
        //     if ( body.rentedProduct ) {
        //         state.cart = Object.assign({},state.cart,{ [body.rentedProduct.id] : body.rentedProduct})
        //         state.quantity--
        //     }
        //
        // } else {
        //     delete state.cart[body.id]
        //     state.cart = Object.assign({},state.cart)
        //     if ( body.rentedProduct ) {
        //         state.quantity--
        //         delete state.cart[body.rentedProduct.id]
        //         state.cart = Object.assign({},state.cart)
        //     }
        // }
        //
        // state.quantity--

    },

    async ORDER_COURSE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:'token' ,
            route:`order/course`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: body
        },{ root : true });
    },
    async GET_PROMOCODE({dispatch,rootState} , body ) {
        return await dispatch('ajax/SEND_NEW',{
            token:'token' ,
            route:`discount/course/promocode/${body.code}/${body.course_id}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
        },{ root : true });
    },




    CLEAR_CART({state}) {
        state.cart = {}
        state.quantity = 0
    }

}

export default {
    namespaced:true,
    actions,
    mutations,
    state
}
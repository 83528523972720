import componentNavigation from "@/components/pages/westudy/student/StudentCourse/UI/componentNavigation";
export default {
    components : {
        componentNavigation
    },
    computed : {
        STUDENT_COURSE() {
            if ( !this.$store.state.courses.studentCourse ) {
                return null
            }
            return this.$store.state.courses.studentCourse.uuid ? this.$store.state.courses.studentCourse : {}
        }
    },
    methods: {
        async INIT_STUDENT_COURSE(course = null) {
            if ( this.STUDENT_COURSE.uuid ||  this.STUDENT_COURSE.uuid === course ) {
               return
            }
            let result =  await this.$store.dispatch('courses/GET_STUDENT_COURSE' , course)  ;
            if ( !result ) {
                return
            }
            this.$store.state.courses.studentCourse = result.course

        },
        NEXT_COMPONENT(uuid) {
            if ( !uuid ) {
                return null
            }
            if ( !this.STUDENT_COURSE || !this.STUDENT_COURSE.uuid ) {
                return null
            }

            let moduleIndex = null
            let topicIndex = null
            let componentIndex = null

            try {
                this.STUDENT_COURSE.modules.forEach( (module,mIndex) => {
                    module.topics.forEach((topic, tIndex) => {
                        topic.components.forEach( (el,cIndex) => {
                            if ( el.uuid === uuid) {
                                moduleIndex = mIndex
                                topicIndex = tIndex
                                componentIndex = cIndex
                            }
                        })
                    })
                })
                if ( this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex].components[componentIndex + 1 ] ) {
                    return this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex].components[componentIndex + 1 ]
                }
                if ( this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex+1] ) {
                    if (  this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex+1].components[0] ) {
                        return this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex+1].components[0]
                    }
                }

                if ( this.STUDENT_COURSE.modules[moduleIndex+1] ) {
                    if ( this.STUDENT_COURSE.modules[moduleIndex+1].topics[0] ) {
                        if ( this.STUDENT_COURSE.modules[moduleIndex+1].topics[0].components[0] ) {
                            return this.STUDENT_COURSE.modules[moduleIndex+1].topics[0].components[0]
                        }
                    }
                }
            } catch(_) {
                return null
            }



            return null
        },
        PREVIOUS_COMPONENT(uuid) {
            if ( !uuid ) {
                return null
            }
            if ( !this.STUDENT_COURSE || !this.STUDENT_COURSE.uuid ) {
                return null
            }


            let moduleIndex = null
            let topicIndex = null
            let componentIndex = null

            try {

                this.STUDENT_COURSE.modules.forEach( (module,mIndex) => {
                    module.topics.forEach((topic, tIndex) => {
                        topic.components.forEach( (el,cIndex) => {
                            if ( el.uuid === uuid) {
                                moduleIndex = mIndex
                                topicIndex = tIndex
                                componentIndex = cIndex
                            }
                        })
                    })
                })

                if ( this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex].components[componentIndex - 1 ] ) {
                    return this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex].components[componentIndex - 1 ]
                }
                if ( this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex-1] ) {
                    let index = this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex-1].components.length - 1
                    if (  this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex-1].components[index] ) {
                        return this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex-1].components[index]
                    }
                }
                if ( this.STUDENT_COURSE.modules[moduleIndex-1] ) {
                    let indexTopic = this.STUDENT_COURSE.modules[moduleIndex-1].topics.length - 1
                    if ( this.STUDENT_COURSE.modules[moduleIndex-1].topics[indexTopic] ) {
                        let indexComponent = this.STUDENT_COURSE.modules[moduleIndex-1].topics[indexTopic].components.length - 1
                        if ( this.STUDENT_COURSE.modules[moduleIndex-1].topics[indexTopic].components[indexComponent] ) {
                            return this.STUDENT_COURSE.modules[moduleIndex-1].topics[indexTopic].components[indexComponent]
                        }
                    }
                }


            } catch(_) {
                return null
            }



            return null
        },
        EDIT_COMPONENT(component) {

            if ( !this.STUDENT_COURSE || !this.STUDENT_COURSE.uuid ) {
                return {}
            }

            let moduleIndex = null
            let topicIndex = null
            let componentIndex = null

            this.STUDENT_COURSE.modules.forEach( (module,mIndex) => {
                module.topics.forEach((topic, tIndex) => {
                    topic.components.forEach( (el,cIndex) => {
                        if ( el.uuid === component.uuid) {
                            moduleIndex = mIndex
                            topicIndex = tIndex
                            componentIndex = cIndex
                        }
                    })
                })
            })

            this.$store.state.courses.studentCourse.modules[moduleIndex].topics[topicIndex].components[componentIndex] = JSON.parse(JSON.stringify(component))
            this.$store.state.courses.studentCourse = Object.assign({} , this.$store.state.courses.studentCourse)

        },
        IS_LAST_COMPONENT(component) {
            if ( !this.STUDENT_COURSE || !this.STUDENT_COURSE.uuid ) {
                return {}
            }

            let moduleIndex = null
            let topicIndex = null
            let componentIndex = null

            this.STUDENT_COURSE.modules.forEach( (module,mIndex) => {
                module.topics.forEach((topic, tIndex) => {
                    topic.components.forEach( (el,cIndex) => {
                        if ( el.uuid === component.uuid) {
                            moduleIndex = mIndex
                            topicIndex = tIndex
                            componentIndex = cIndex
                        }
                    })
                })
            })
            if ( moduleIndex || moduleIndex === 0 ) {
                if ( topicIndex || topicIndex === 0 ) {
                    if ( componentIndex || componentIndex === 0 ) {
                        if ( moduleIndex === (this.STUDENT_COURSE.modules.length -1) ) {
                            if ( topicIndex === (this.STUDENT_COURSE.modules[moduleIndex].topics.length - 1) ) {
                                if ( componentIndex ===  (this.STUDENT_COURSE.modules[moduleIndex].topics[topicIndex].components.length -1)  ) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }

            return false

        },
        PROCESS_COMPONENT_COMPLETION(component) {
            if ( !this.STUDENT_COURSE ) {
                return null
            }
            let passed = component.passed

            if ( component.entity_type === 'file' || component.entity_type === 'video' || component.entity_type === 'lecture'  ) {
                component.passed = true
                passed = true
            }

            if ( component.is_stop_course && component.passed ) {

                let nextComponent = this.NEXT_COMPONENT(component.uuid)
                if ( nextComponent ) {
                    if ( nextComponent.date_start || nextComponent.date_end) {

                        let online = false
                        let permission = true

                        if (nextComponent.date_start ) {
                            online =  isNaN(nextComponent.date_start)
                        }

                        if (nextComponent.date_end ) {
                            online =  isNaN(nextComponent.date_end)
                        }

                        let date = new Date()

                        let dateStart = new Date(nextComponent.date_start)
                        let dateEnd = new Date(nextComponent.date_end)

                        if ( !online ) {
                            dateStart = new Date(component.student_profile_date * 1000)
                            dateStart = dateStart.setDate(dateStart.getDate() + nextComponent.date_start )
                            dateEnd = new Date(component.student_profile_date * 1000)
                            dateEnd = dateEnd.setDate(dateEnd.getDate() + nextComponent.date_end )
                        }

                        if (nextComponent.date_start &&  date <  dateStart )   {
                            permission = false
                        }

                        if (nextComponent.date_end &&  date > dateEnd  )   {
                            permission = false
                        }

                        nextComponent.is_permitted = permission
                    } else {
                        nextComponent.is_permitted = true
                    }
                    this.EDIT_COMPONENT(nextComponent)
                }



            }

            this.EDIT_COMPONENT(component)

            return {
                passed : passed,
                entity : JSON.parse(JSON.stringify(this.STUDENT_COURSE))
            }
        }
    }
}
const actions = {

    async GET_INVOICE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`invoice`,
                method:'POST',
                data:body,
                business : rootState.business.selectedBusiness.alias
            },{ root : true}
        );
    },

}

export default {
    namespaced:true,
    actions,
}
import AColorPicker from "@/components/AvalonEditor/UI/aColorPicker";
import AParameterSwitch from "@/components/AvalonEditor/UI/aParameterSwitch";
import AParameterSlider from "@/components/AvalonEditor/UI/aParameterSlider";
import AParameterSelect from "@/components/AvalonEditor/UI/aParameterSelect";
import WebFontLoader from 'webfontloader';
import avalonButton from "@/components/AvalonEditor/UI/avalonButton";
import avalonHtmlViewer from "@/components/AvalonEditor/UI/avalonHtmlViewer";

import avalonTextField from "@/components/AvalonEditor/View/elements/form/avalonTextField";
import avalonPhoneField from "@/components/AvalonEditor/View/elements/form/avalonPhoneField";
import avalonSelectField from "@/components/AvalonEditor/View/elements/form/avalonSelectField";
import avalonDialog from "@/components/AvalonEditor/UI/avalonDialog";
import avalonIconButton from "@/components/AvalonEditor/UI/avalonIconButton.vue";


export default {
    components : {
        AColorPicker,
        AParameterSwitch,
        AParameterSlider,
        AParameterSelect,
        avalonButton,
        avalonHtmlViewer,
        avalonTextField,
        avalonPhoneField,
        avalonSelectField,
        avalonDialog,
        avalonIconButton
    },
    data() {
        return {
            EMPTY_BLOCK_PLACEHOLDER : {
                config : {}
            },
            EDITOR_PREHEADER_HEIGHT : 40,
            AVALON_COLORS_CLASS_NAMES : [
                "ws_background_main" ,
                "ws_text_light" ,
                "ws_medium",
                "ws_accent" ,
                "ws_text_dark" ,
                "ws_accent2"
            ],
            AVALON_COLORS_LIST : [
                "background_main" ,
                "text_light" ,
                "medium",
                "accent" ,
                "text_dark" ,
                "accent2"
            ],
            AVALON_STYLES_LIST : [
                "light_1" ,
                "light_2" ,
                "middle_1",
                "middle_2" ,
                "dark_1" ,
                "dark_2"
            ]
        }
    },
    computed : {
        SMALL() {
            return this.SM||this.MOBILE_VIEW
        },
        CONFIG_COLORS_ARRAY() {
            let colors = this.CONFIG_PARAM('colors')
            if ( !colors ) {
                return []
            }

            return Object.values(colors)
        },

        TEXT_MULTIPLIER() {
            return  (this.MOBILE_VIEW || this.SM) ? 0.85 : 1
        },
        DIM_KEY() {
            return {
                x1 : this.MOBILE_VIEW ? 'smX1' : "x1",
                x2 : this.MOBILE_VIEW ? 'smX2' : "x2",
                y1 : this.MOBILE_VIEW ? 'smY1' : "y1",
                y2 : this.MOBILE_VIEW ? 'smY2' : "y2",
                width : this.MOBILE_VIEW ? 'widthSM' : "width",
                height : this.MOBILE_VIEW ? 'heightSM' : "height",
                rows :  this.MOBILE_VIEW ? 'rows_sm' : "rows",
                image_position_x :  this.MOBILE_VIEW ? 'image_position_sm_x' : "image_position_x",
                image_position_y :  this.MOBILE_VIEW ? 'image_position_sm_y' : "image_position_y",
            }
        },
        HEADER_TOP_PADDING() {
            return this.$store.state.avalon.header.config.height + this.EDITOR_PREHEADER_HEIGHT
        },
        SITE_CONFIG() {
            if ( Object.keys(this.$store.state.avalon.config).length === 0 ) {
                return this.$store.state.avalon.defaultConfig
            }
            return this.$store.state.avalon.config
        },
        DEFAULT_CONFIG() {
            return this.$store.state.avalon.defaultConfig
        },
        DEFAULT_BLOCK() {
            return {config : this.CONFIG_PARAM('block')}
        },
        avalonConfig() {

            if ( Object.keys(this.$store.state.avalon.config).length === 0 ) {
                return this.$store.state.avalon.defaultConfig
            }
            return this.$store.state.avalon.config
        },
        avalonColorsArray() {
            return Object.keys(this.avalonConfig.colors)
        },
        MOBILE_VIEW() {
            return this.$store.state.avalon.mobileView
        },
        MOBILE_WIDTH() {
            return this.$store.state.avalon.mobileWidth
        },
        avalonAdminTemplateNavigationItems() {
            return [
                { text : this.$t('Pages')     , value : 'pages'    , path :  this.localeLink('avalon_editor/templates/' + this.templateId + '/pages')    , icon : 'mdi-file' ,    } ,
                { text : this.$t('Blocks')    , value : 'blocks'   , path :  this.localeLink('avalon_editor/templates/' + this.templateId + '/blocks')   , icon : 'mdi-page-layout-body' ,    } ,
                { text : this.$t('Settings')  , value : 'settings' , path :  this.localeLink('avalon_editor/templates/' + this.templateId + '/settings')   , icon : 'mdi-cogs' ,    } ,
            ]
        },
        AVALON_LANGS() {
            return this.$store.state.avalon.langs
        },
        AVALON_LANG_PRIMARY() {
            return this.$store.state.avalon.langPrimary
        },
        ALL_LANGS_HOMEPAGES() {

            if ( !this.$store.state.avalon.pagesSelect ) {
                return []
            }

            return this.$store.state.avalon.pagesSelect.filter(el => el.homepage === true )
        },
        CURRENT_LANG_HOMEPAGE() {
            if ( this.$store.state.avalon.pagesSelect.length === 0 ) {
                return null
            }

            let homepage = this.$store.state.avalon.pagesSelect.find(el => el.homepage === true && el.lang === this.$store.state.avalon.selectedLang )

            if (!homepage &&  this.ALL_LANGS_HOMEPAGES.length > 0 ) {
                homepage = {
                    text : 'no_lang',
                    value : 'no-lang_$homepage',
                    is_published : false,
                    noLang : true
                }
            }
            return homepage
        },
        CURRENT_LANG_HOMEPAGE_INDEX() {
            if ( this.$store.state.avalon.pagesSelect.length === 0 ) {
                return null
            }
            return this.$store.state.avalon.pagesSelect.findIndex(el => el.homepage === true && el.lang === this.$store.state.avalon.selectedLang )
        },
        CURRENT_LANG_PAGES() {
            let items = []

            if ( this.$store.state.avalon.pagesSelect.length === 0 ) {
                return []
            }

            let allAliases = this.$store.state.avalon.pagesSelect
                .filter(el => !el.homepage)
                .map(el => el.alias)
                .reduce((acc, current) => {
                    if (!acc.some(item => item === current)) {
                        acc.push(current);
                    }
                    return acc;
                }, [])

            allAliases.forEach(alias => {
                let page = this.$store.state.avalon.pagesSelect.find(el => el.alias === alias && el.lang === this.$store.state.avalon.selectedLang )
                if ( !page ) {
                    page = this.COPY(this.$store.state.avalon.pagesSelect.find(el => el.alias === alias ))
                    page.id = 'no-lang_' + page.alias
                    page.value = 'no-lang_' + page.alias
                    page.noLang = true
                }
                items.push(page)
            })

            return items

        },
        REDO_BUFFER_ACTION() {
            if ( this.$store.state.avalon.redoBuffer.length === 0 ) {
                return null
            }
            let activeBuffer = this.$store.state.avalon.redoBuffer.filter(el => el.active )

            if ( activeBuffer.length > 0 ) {
                return activeBuffer[0]
            }

            return null
        },
        SIDE_MENU_WIDTH() {
            return 280
        },
        SIDE_MENU() {
            return this.$store.state.avalon.sideMenu
        },
        WINDOW_WIDTH() {
            return this.$store.state.windowWidth
        },
        AVALON_EDITOR_WINDOW_WIDTH() {
            let width = this.WINDOW_WIDTH
            if ( this.SIDE_MENU && this.$store.state.avalon.editorOn ) {
                width -= this.SIDE_MENU_WIDTH
            }
            return width
        },
        DEFAULT_BLOCK_WIDTH() {
            return 1240
        },
        SCALE_COEFFICIENT() {
            const blockWidth = 1240
            if ( this.MOBILE_VIEW ) {
                return 1
            }
            if ( this.SM ) {
                let coefficient = this.WINDOW_WIDTH / parseFloat(this.MOBILE_WIDTH)
                return coefficient > 1 ? coefficient : 1
            }

            if (this.AVALON_EDITOR_WINDOW_WIDTH <blockWidth ) {
                return this.AVALON_EDITOR_WINDOW_WIDTH / blockWidth
            }
            return 1

        },

    },
    methods: {
        EDITOR_FONT_COEFFICIENT(blockWidth = 1240) {
            if ( this.MOBILE_VIEW ) {
                return 1
            }
            if ( this.SM ) {
                let coefficient = this.WINDOW_WIDTH / parseFloat(this.MOBILE_WIDTH)
                return coefficient > 1 ? coefficient : 1
            }

            if (this.AVALON_EDITOR_WINDOW_WIDTH <blockWidth ) {
                return this.AVALON_EDITOR_WINDOW_WIDTH / blockWidth
            }
            return 1

        },
        EDITOR_BLOCK_WIDTH( blockWidth = 1240 , withPadding = false , fullWidth = false) {
            let width = 0
            if ( !this.MOBILE_VIEW ) {
                width = this.AVALON_EDITOR_WINDOW_WIDTH > blockWidth && !fullWidth ? blockWidth : this.AVALON_EDITOR_WINDOW_WIDTH
            } else {
                width = parseFloat(this.MOBILE_WIDTH)
            }
            if ( withPadding ) {
                width -= 24
            }
            return width
        },
        GET_WINDOW_WIDTH() {

            this.$store.state.windowWidth = window.innerWidth

            if ( window.innerWidth > screen.availWidth ) {
                this.$store.state.windowWidth  =  screen.availWidth
            }
            setTimeout(()=> {
                this.$store.state.windowWidth = window.innerWidth

                if ( window.innerWidth > screen.availWidth ) {
                    this.$store.state.windowWidth  =  screen.availWidth
                }
            } , 50)

        },
        TEXT_STYLES_CONFIG(blockStyle = 'light_1') {
            console.log('TEXT_STYLES_CONFIG' , blockStyle)
            return {
                strong : {
                    fontWeight : 900
                },
                b : {
                    fontWeight : 900
                },
                a : {
                    color :this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'a'  ) ,
                    textDecoration : 'none'
                },
                p : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "paragraphs" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "p") ,
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "p_sm") ,
                    fontWeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "paragraphs" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "paragraphs" , "spacing" ) || 0) + 'px' ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "paragraphs" , "style" ),
                    color :  this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'p'  ) ,
                },
                ul : {
                    marginLeft : '-10px'
                },
                li : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "paragraphs" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "p") ,
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "p_sm") ,
                    fontWeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "paragraphs" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "paragraphs" , "spacing" ) || 0) + 'px' ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "paragraphs" , "style" ),
                    color :  this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'p'  ) ,
                    paddingLeft : 0,
                },
                h1 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "headings" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h1"),
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h1_sm"),
                    fontWeight : this.CONFIG_PARAM("fonts" , "headings" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "headings" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "headings" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "headings" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h1'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "headings" , "style" ),
                    marginBottom : '2px',
                },
                h2 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "headings" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h2"),
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h2_sm"),
                    fontWeight : this.CONFIG_PARAM("fonts" , "headings" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "headings" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "headings" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "headings" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h2'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "headings" , "style" ),
                },
                h3 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "headings" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h3"),
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h3_sm"),
                    fontWeight : this.CONFIG_PARAM("fonts" , "headings" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "headings" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "headings" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "headings" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h3'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "headings" , "style" ),
                },
                h4 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "headings" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h4"),
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h4_sm"),
                    fontWeight : this.CONFIG_PARAM("fonts" , "headings" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "headings" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "headings" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "headings" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h4'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "headings" , "style" ),
                },
                h5 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "headings" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h5")  ,
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "headings" , "size" , "h5_sm")  ,
                    fontWeight : this.CONFIG_PARAM("fonts" , "headings" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "headings" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "headings" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "headings" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h5'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "headings" , "style" ),
                },
                h6 : {
                    fontFamily : this.CONFIG_PARAM("fonts" , "paragraphs" , "family" ),
                    fontSize : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "h6")  ,
                    fontSize_sm : this.CONFIG_PARAM("fonts" , "paragraphs" , "size" , "h6_sm")  ,
                    fontWeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "weight" ),
                    textTransform : this.CONFIG_PARAM("fonts" , "paragraphs" , "case" ),
                    lineHeight : this.CONFIG_PARAM("fonts" , "paragraphs" , "lineHeight" ) + '%',
                    letterSpacing : (this.CONFIG_PARAM("fonts" , "paragraphs" , "spacing" ) || 0) + 'px' ,
                    color : this.CONFIG_PARAM_COLOR('styles' , blockStyle, 'h6'  ) ,
                    fontStyle : this.CONFIG_PARAM("fonts" , "paragraphs" , "style" ),
                },
                img : {}
            }
        },

        PROCESS_TEXT_SIZE(domId , blockWidth = 1240) {
            let coefficient = this.EDITOR_FONT_COEFFICIENT(blockWidth)
            let styles = this.TEXT_STYLES_CONFIG()
            Object.keys(styles).forEach(tag => {
                const domElements = document.querySelectorAll(`#${domId} ${tag}`);
                domElements.forEach(domElement => {

                    let sizeValue = styles[tag].fontSize
                    if ( (this.MOBILE_VIEW || this.SM) && styles[tag].fontSize_sm ) {
                        sizeValue = styles[tag].fontSize_sm
                    }

                    let size = sizeValue

                    if (coefficient !== 1) {
                        size = size * coefficient
                    }
                    size = size + 'px'
                    domElement.style.fontSize = size;
                })
            })
        },
        PROCESS_BLOCK_TEXT_STYLES(domId ,  blockStyle = 'light_1' , block = null, wsColors = false , isView = false , blockWidth = null ) {
            const getBlockColorParam = (tag)=> {
                if (!block) {
                    return null
                }
                if ( block && block.config && block.config.color_styles && block.config.color_styles[tag]) {
                    return block.config.color_styles[tag]
                }
            }
            let styles = this.TEXT_STYLES_CONFIG(blockStyle)
            let coefficient = this.EDITOR_FONT_COEFFICIENT(blockWidth)
            // Process General Styles
            Object.keys(styles).forEach(tag => {
                const domElements = document.querySelectorAll(`#${domId} ${tag}`);
                domElements.forEach(domElement => {
                    domElement.removeAttribute("style")
                    if ( tag.toLowerCase() === 'img') {
                        domElement.remove()
                        return
                    }
                    domElement.style.marginBottom = 0
                    if ( isView && tag === 'a') {
                        if ( domElement.href.includes(this.businessPublicLink('',true)) ) {
                            let route = domElement.href.replace(this.businessPublicLink('',true), "");
                            route = route.slice(1)
                            domElement.addEventListener("click", (event) => {
                                event.preventDefault();
                                this.$router.push(this.businessPublicLink(route))
                            });
                        }
                    }

                    Object.keys(styles[tag]).forEach(param => {
                        if ( param === 'fontSize' ) {
                            let textMultiplier = 1
                            if (coefficient !== 1) {
                                textMultiplier = coefficient
                            }
                            let sizeValue = styles[tag][param]
                            if ( (this.MOBILE_VIEW || this.SM) && styles[tag][param + '_sm'] ) {
                                sizeValue = styles[tag][param + '_sm']
                            }
                            let size  =  sizeValue*textMultiplier
                            size = size + 'px'

                            domElement.style[param] = size;
                        }
                        else if ( param === 'color' ) {
                            domElement.style.color = getBlockColorParam(tag) || styles[tag][param]
                        }
                        else if ( param === 'fontWeight' ) {
                            // let classNames = domElement.className.split(" ");
                            // // classNames.forEach(className => {
                            // //     // if ( className.includes('weight-') ) {
                            // //     //     domElement.classList.remove(className )
                            // //     // }
                            // // });
                            domElement.classList.add('weight-'+ styles[tag][param] )
                        } else {
                            domElement.style[param] = styles[tag][param];
                        }
                    })
                });
            })
            // Process wsColors
            if ( wsColors ) {
                const domElements = document.querySelectorAll(`#wsColor`);
                domElements.forEach(domElement => {
                    domElement.classList.forEach(className => {
                        if (this.AVALON_COLORS_CLASS_NAMES.includes(className) ) {
                            domElement.style.color = this.GET_AVALON_COLOR(className.substring(3));
                        }
                    })
                });
            }


        },
        PROCESS_TEXT_STYLES(domId , blockStyle = 'light_1' , wsColors = false , isView = false , blockWidth) {
            let styles = this.TEXT_STYLES_CONFIG(blockStyle)
            let coefficient = this.EDITOR_FONT_COEFFICIENT(blockWidth)
            // Process General Styles
            Object.keys(styles).forEach(tag => {
                const domElements = document.querySelectorAll(`#${domId} ${tag}`);
                domElements.forEach(domElement => {
                    domElement.removeAttribute("style")
                    if ( tag.toLowerCase() === 'img') {
                        domElement.remove()
                        return
                    }
                   domElement.style.marginBottom = 0
                    if ( isView && tag === 'a') {
                        if ( domElement.href.includes(this.businessPublicLink('',true)) ) {
                            let route = domElement.href.replace(this.businessPublicLink('',true), "");
                            route = route.slice(1)
                            domElement.addEventListener("click", (event) => {
                                event.preventDefault();
                                this.$router.push(this.businessPublicLink(route))
                            });
                        }
                    }

                    Object.keys(styles[tag]).forEach(param => {
                        if ( param === 'fontSize' ) {
                            let textMultiplier = 1
                            if (coefficient !== 1) {
                                textMultiplier = coefficient
                            }
                            let sizeValue = styles[tag][param]
                            if ( (this.MOBILE_VIEW || this.SM) && styles[tag][param + '_sm'] ) {
                                sizeValue = styles[tag][param + '_sm']
                            }
                            let size  =  sizeValue*textMultiplier
                            size = size + 'px'

                            domElement.style[param] = size;
                        }
                        if ( param === 'fontWeight' ) {
                            // let classNames = domElement.className.split(" ");
                            // // classNames.forEach(className => {
                            // //     // if ( className.includes('weight-') ) {
                            // //     //     domElement.classList.remove(className )
                            // //     // }
                            // // });
                            domElement.classList.add('weight-'+ styles[tag][param] )
                        } else {
                            domElement.style[param] = styles[tag][param];
                        }
                    })
                });
            })
            // Process wsColors
            if ( wsColors ) {
                const domElements = document.querySelectorAll(`#wsColor`);
                domElements.forEach(domElement => {
                    domElement.classList.forEach(className => {
                        if (this.AVALON_COLORS_CLASS_NAMES.includes(className) ) {
                            domElement.style.color = this.GET_AVALON_COLOR(className.substring(3));
                        }
                    })
                });
            }

        },

        GET_COLORS_PARAMS_LOCALES(block , variable = null) {

            let colorLocales = this.$store.state.avalon.colorsVariables.locales

            if ( !variable ) {
                if ( colorLocales[block] ) {
                    if ( colorLocales[block].$title ) {
                        return  colorLocales[block].$title
                    }
                }
                return 'avalon.color_styles.block.' + block
            }
            if ( colorLocales[block] ) {
                if (colorLocales[block][variable]) {
                    return colorLocales[block][variable]
                }
            }
            return   'avalon.color_styles.' +  variable

        },
        GET_FONT_PARAM_NAME(block , variable = null , group = 'elements') {

            let fontLocales = this.$store.state.avalon.fontsVariables.locales[group]

            if ( !variable ) {
                if ( fontLocales[block] ) {
                    if ( fontLocales[block].$title ) {
                        return  this.$t(fontLocales[block].$title)
                    }
                }
                return this.$t('font.elements.' + block)
            }
            if ( fontLocales[block] ) {
                if (fontLocales[block][variable]) {
                    return this.$t(fontLocales[block][variable])
                }
            }
            return   this.$t('font.variables.' +  variable)

        },

        BLOCK_TEXT_CSS(block, blockStyle , elementGroup ,  elementParam = null , colorElement = null , hover = false , blockWidth) {
            return this.CONFIG_TEXT_CSS(blockStyle , elementGroup ,  elementParam , colorElement , hover , block , blockWidth)
        },
        CONFIG_TEXT_CSS(blockStyle , elementGroup ,  elementParam = null , colorElement = null , hover = false , block = null , blockWidth = null  ) {

            if ( !elementParam && !colorElement ) {
                colorElement = elementGroup
            }

            if ( elementParam && !colorElement ) {
                colorElement = elementGroup + '_' + elementParam
            }

            let style = ''
            style += this.CONFIG_FONT_COLOR_STYLE(colorElement,blockStyle , hover , block )
            style += this.CONFIG_FONT_STYLE(elementGroup , elementParam , hover , block , blockWidth )

            return style
        },

        CONFIG_FONT_COLOR_STYLE(element , blockStyle = null , hover = false , block = null   ) {
            if ( !blockStyle && block && block.config && block.config.style ) {
                blockStyle = block.config.style
            }
            if ( block && block.config.color_styles && block.config.color_styles[element] ) {
                return `color : ${this.GET_AVALON_COLOR(block.config.color_styles[element])}${hover ? '66' : ''} !important;`
            }
            if ( !blockStyle ) {
                blockStyle = 'light_1'
            }
            return `color : ${this.CONFIG_PARAM_COLOR('styles' , blockStyle , element )}${hover ? '66' : ''} !important ; `
        },

        BLOCK_FONT_VARIABLE(block , variable , element , param ) {
            let textMultiplier = this.TEXT_MULTIPLIER

            if ( block && block.config && block.config.fonts_styles && block.config.fonts_styles[element + '__' + param] && block.config.fonts_styles[element + '__' + param].setup ) {
                let value = null;
                let baseElements = ['h1','h2','h3','h4' , 'p','h5','h6']
                let setup = block.config.fonts_styles[element + '__' + param].setup
                if ( baseElements.includes(setup) ) {

                    let setupGroup = ['p','h5','h6'].includes(setup) ? 'paragraphs' : 'headings'
                    value =  value = this.CONFIG_PARAM('fonts', setupGroup , variable , setup )
                    if ( variable === 'size' ) {
                        value = Math.round(value*textMultiplier)
                    }
                    return value
                } else if ( block.config.fonts_styles[element + '__' + param] && block.config.fonts_styles[element + '__' + param][variable] ) {
                    value = block.config.fonts_styles[element + '__' + param][variable] ;
                    if ( variable === 'size' ) {
                        value = Math.round(value*textMultiplier)
                    }
                    return value
                } else {
                    return this.CONFIG_FONT_VARIABLE(variable , element , param)
                }
            }
            return this.CONFIG_FONT_VARIABLE(variable , element , param)
        },
        CONFIG_FONT_VARIABLE(variable = 'size' , element , param = null  ) {
            let textMultiplier = this.TEXT_MULTIPLIER

            let baseElements = ['h1','h2','h3','h4' , 'p','h5','h6']
            let setupGroup = null
            let setup = null

            if ( baseElements.includes(element) ) {
                setupGroup = ['p','h6'].includes(element) ? 'paragraphs' : 'headings'
                setup = element
            } else {
                setup = this.CONFIG_PARAM('fonts','elements',element, param , 'setup')
                setupGroup = ['p','h6'].includes(setup) ? 'paragraphs' : 'headings'
            }

            let value = null
            if ( !setup || baseElements.includes(setup) ) {
                if ( variable === 'size' ) {
                    value = this.CONFIG_PARAM('fonts', setupGroup , variable , setup )
                    value = Math.round(value*textMultiplier)
                } else {
                    value = this.CONFIG_PARAM('fonts', setupGroup , variable  )
                }

            } else {
                value = this.CONFIG_PARAM('fonts','elements', element , param ,  variable)
                if ( variable === 'size' ) {
                    value = Math.round(value*textMultiplier)
                }
            }

            return value
        },
        CONFIG_FONT_STYLE(element , param = null , hover , block = {} , blockWidth) {

            let textMultiplier = 1

            if ( (block && block.config && block.config.width || blockWidth) && !( this.MOBILE_VIEW) ) {
                textMultiplier = this.EDITOR_FONT_COEFFICIENT(block.config.width)
            }

            let style = ''

            let baseElements = ['h1','h2','h3','h4' , 'h5' , 'p','h6']
            let setupGroup = null
            let setup = null

            let blockFontStyles = block && block.config && block.config.fonts_styles ? block.config.fonts_styles : null
            let blockElementParams = null
            if ( blockFontStyles && blockFontStyles[element + "__" + param ] ) {
                blockElementParams = blockFontStyles[element + "__" + param ]
            }


            if ( baseElements.includes(element) ) {
                setupGroup = ['p','h6'].includes(element) ? 'paragraphs' : 'headings'
                setup = element
            } else {
                setup = this.CONFIG_PARAM('fonts','elements',element, param , 'setup')
                if ( blockElementParams && blockElementParams.setup ) {
                    setup = blockElementParams.setup
                }
                setupGroup = ['p','h6'].includes(setup) ? 'paragraphs' : 'headings'
            }

            if ( !setup || baseElements.includes(setup) ) {

                style += `font-family : "${this.CONFIG_PARAM('fonts', setupGroup ,'family')}"  ;`
                let sizeSetup = setup
                if ( this.MOBILE_VIEW || this.SM ) {
                    sizeSetup += '_sm'
                }
                style += `font-size : ${ Math.round(this.CONFIG_PARAM("fonts" , setupGroup  , "size" , sizeSetup )*textMultiplier)  }px; `
                style += `font-weight : ${this.CONFIG_PARAM("fonts" , setupGroup  , "weight" )} ;`
                style += `text-transform : ${this.CONFIG_PARAM("fonts" , setupGroup  , "case" )} ;`
                style += `line-height : ${this.CONFIG_PARAM("fonts" , setupGroup  , "lineHeight" )}%;`
                style += `letter-spacing : ${this.CONFIG_PARAM("fonts" , setupGroup  , "spacing")  || 0}px  ;`
                style += `font-style : ${this.CONFIG_PARAM("fonts" , setupGroup  , "style")}  ;`
            } else {
                if ( blockElementParams && blockElementParams.family ) {
                    style += `font-family : "${ blockElementParams.family }"  ;`
                } else {
                    style += `font-family : "${this.CONFIG_PARAM('fonts','elements', element , param ,  'family')}"  ;`
                }

                let sizeTitle = 'size'
                if ( this.MOBILE_VIEW || this.SM ) {
                    sizeTitle += '_sm'
                }

                if ( blockElementParams && blockElementParams[sizeTitle] ) {
                    style += `font-size : ${ Math.round(blockElementParams[sizeTitle] * textMultiplier) }px;`
                } else {
                    style += `font-size : ${ Math.round(this.CONFIG_PARAM("fonts" , "elements" , element , param ,  sizeTitle  )*textMultiplier) }px; `
                }

                if ( blockElementParams && blockElementParams.weight ) {
                    style += `font-weight : ${ blockElementParams.weight } ;`
                } else {
                    style += `font-weight : ${this.CONFIG_PARAM("fonts" , "elements" , element , param ,  "weight" )} ;`
                }

                if ( blockElementParams && blockElementParams.case ) {
                    style += `text-transform : ${ blockElementParams.case } ;`
                } else {
                    style += `text-transform : ${this.CONFIG_PARAM("fonts" , "elements" , element , param ,  "case" )} ;`
                }

                if ( blockElementParams && blockElementParams.lineHeight ) {
                    style += `line-height : ${ blockElementParams.lineHeight }%;`
                } else {
                    style += `line-height : ${this.CONFIG_PARAM("fonts" , "elements" , element , param ,  "lineHeight" )}%;`
                }

                if ( blockElementParams && blockElementParams.spacing ) {
                    style += `letter-spacing : ${  blockElementParams.spacing   || 0}px  ;`
                } else {
                    style += `letter-spacing : ${this.CONFIG_PARAM("fonts" , "elements" , element , param ,  "spacing")  || 0}px  ;`
                }

                if ( blockElementParams && blockElementParams.style ) {
                    style += `font-style : ${ blockElementParams.style }  ;`
                } else {
                    style += `font-style : ${this.CONFIG_PARAM("fonts" , "elements" , element , param ,  "style")}  ;`
                }


            }

            if ( hover ) {
                style += `transition : color 0.2s ease;`
            }

            return style;
        },

        GET_HOMEPAGE_LANG_INDEX(lang) {
            if ( this.$store.state.avalon.pagesSelect.length === 0 ) {
                return -1
            }
            return this.$store.state.avalon.pagesSelect.findIndex(el => el.homepage === true && el.lang === lang )
        },


        ADD_UNDO_BUFFER(method , data , tooltip ) {
            this.$store.state.avalon.undoBuffer.push({
                method : method , data : data  , tooltip : tooltip
            })
        },
        ADD_REDO_BUFFER(method , data , tooltip , active ) {
            this.$store.state.avalon.redoBuffer.push({
                method : method , data : data  , tooltip : tooltip , active : active
            })
        },
        ACTIVATE_REDO() {

            let searchIndex = null

            for ( let i = this.$store.state.avalon.redoBuffer.length - 1; i >= 0; i-- ) {
                if ( !searchIndex &&  !this.$store.state.avalon.redoBuffer[i].active ) {
                    searchIndex = i
                }
            }

            this.$store.state.avalon.redoBuffer[searchIndex].active = true
        },
        POP_REDO_BUFFER() {
            this.$store.state.avalon.redoBuffer.splice((0) , 1)
        },
        POP_UNDO_BUFFER() {
            this.$store.state.avalon.undoBuffer.splice((this.$store.state.avalon.undoBuffer.length - 1) , 1)
        },

        GET_AVALON_COLOR(color) {
            if ( this.avalonColorsArray.includes(color) ) {
                return this.avalonConfig.colors[color]
            }
            return color
        },
        avalonBlockConfig(block,param) {
            if ( !block.config ) {
                return this.avalonConfig.block[param] + param
            }
            return block.config[param] !== undefined ? block.config[param] : this.avalonConfig.block[param]
        },
        BLOCK_ELEMENTS_SELECT() {
            let items = [
                { text : this.$t('Text')     , value : 'text'    , icon : 'mdi-format-size'             },
                { text : this.$t('Button')   , value : 'button'  , icon : 'mdi-button-cursor'           },
                { text : this.$t('List')     , value : 'list'    , icon : 'mdi-format-list-text'        },
                { text : this.$t('Image')    , value : 'image'   , icon : 'mdi-image'                   },
                { text : this.$t('Video')    , value : 'video'   , icon : 'mdi-video-plus'              },
                { text : this.$t('Rectangle')   , value : 'rectangle'  , icon : 'mdi-rectangle'         },
                { text : this.$t('Line')   , value : 'line'  , icon : 'mdi-minus'                       },
                { text : this.$t('ModalElement')   , value : 'modal'  , icon : 'mdi-dock-window'         },
                { text : this.$t('Form')   , value : 'form'  , icon : 'mdi-form-select'                           },
                { text : this.$t('Accordion')   , value : 'accordion'  , icon : 'mdi-view-day'                    },
                { text : this.$t('CourseContent')     , value : 'course-content'    , icon : 'mdi-school-outline' },

            ]

            return items
        },
        CONFIG_PARAM(...keys) {
            let value = null
            keys.forEach((key,index) => {

                if ( index === 0  ) {
                    if (this.SITE_CONFIG[key]) {
                        value = this.SITE_CONFIG[key];
                    } else {
                        value = this.DEFAULT_CONFIG[key]
                    }
                } else {

                    if ( value ) {
                        if ( value[key] ) {
                            value = value[key]
                        } else {
                            let dConfig = this.DEFAULT_CONFIG
                            for (let i = 0; i <= index; i++) {
                                dConfig = dConfig[keys[i]] || null
                            }
                            value = dConfig
                        }
                    }

                }



            })
            return value || null
        },
        CONFIG_PARAM_COLOR(...keys) {
            return this.GET_AVALON_COLOR(this.CONFIG_PARAM(...keys))
        },
        BLOCK_COLOR_PARAM(block = null, param , style = null ) {
            if ( typeof style !== 'string' ) {
                style = block.config.style || 'light_1'
            }
            if ( block && block.config && block.config.color_styles &&  block.config.color_styles[param]) {
                return this.GET_AVALON_COLOR(block.config.color_styles[param])
            }
            return this.CONFIG_PARAM_COLOR('styles' , style , param )
        },
        CHECK_CONFIG_PARAM(...keys) {
            let finish = false
            let updateConfig = false

            keys.forEach( (key,index) => {
                if ( !finish ) {
                    if (index === 0 ) {
                       if (!this.SITE_CONFIG[ keys[index] ]) {
                           this.SITE_CONFIG[ keys[0] ] = this.DEFAULT_CONFIG[ keys[0] ]
                           finish = true
                           updateConfig = true
                       }
                    }
                    if (index === 1 ) {
                        if (!this.$store.state.avalon.config[keys[0]][keys[1]] ) {
                            this.$store.state.avalon.config[keys[0]][keys[1]]  = this.DEFAULT_CONFIG[keys[0]][keys[1]]
                            finish = true
                            updateConfig = true
                        }
                    }
                    if (index === 2 ) {
                        if (!this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]] ) {
                            this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]]  = this.DEFAULT_CONFIG[keys[0]][keys[1]][keys[2]]
                            finish = true
                            updateConfig = true
                        }
                    }
                    if (index === 3 ) {
                        if (!this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]][keys[3]] ) {
                            this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]][keys[3]]  = this.DEFAULT_CONFIG[keys[0]][keys[1]][keys[2]][keys[3]]
                            finish = true
                            updateConfig = true
                        }
                    }
                    if (index === 3 ) {
                        if (!this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]  ) {
                            this.$store.state.avalon.config[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]   = this.DEFAULT_CONFIG[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]
                            finish = true
                            updateConfig = true
                        }
                    }

                }

                if ( updateConfig ) {
                    this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
                }

            })
        },
        CHECK_CONFIG_FONTS() {

            if (!this.$store.state.avalon.config.fonts ) {
                this.$store.state.avalon.config.fonts = {}
            }
            if (!this.$store.state.avalon.config.fonts.families ) {
                this.$store.state.avalon.config.fonts.families = []
            }

            this.$store.state.avalon.config.fonts.families = [
                this.CONFIG_PARAM('fonts','headings','family'),
                this.CONFIG_PARAM('fonts','paragraphs','family')
            ]
            Object.keys(this.CONFIG_PARAM('fonts','elements')).forEach(element => {
                Object.keys(this.CONFIG_PARAM('fonts','elements',element)).forEach(variable => {
                    let family = this.CONFIG_PARAM('fonts','elements',element,variable,'family')
                    if ( family && !this.$store.state.avalon.config.fonts.families.includes(family) ) {
                        this.$store.state.avalon.config.fonts.families.push(family)
                    }
                })
            })
            this.$store.state.avalon.config = this.COPY(this.$store.state.avalon.config)
        },
        LOAD_FONTS() {
            this.CHECK_CONFIG_FONTS()
            let font = this.CONFIG_PARAM("fonts" , "families")
            font = this.COPY(font)
            font.forEach((el , index ) => {
                font[index] = el + ':100,200,300,400,500,600,700,800,900'
            })
            WebFontLoader.load({
                google: {
                    families: [...font],
                },
                fontloading: () =>  {
                    this.$store.state.avalon.isLoadingFont = true
                },
                active: () =>  {
                    this.$store.state.avalon.isLoadingFont = false
                },
            });
        },
        LOAD_GOOGLE_FONT(font) {
            font += ':100,200,300,400,500,600,700,800,900'

            WebFontLoader.load({
                google: {
                    families: [font],
                },
                fontloading: () =>  {
                    this.$store.state.avalon.isLoadingFont = true
                },
                active: () =>  {
                    this.$store.state.avalon.isLoadingFont = false
                },
            });
        }
    }

}
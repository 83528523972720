export default {

    businessDashboard : [
        { path: "webinars" , component:() => import("@/modules/webinars/components/Webinars.vue")  , props : true },
        { path: "webinar/:webinarUuid" , component:() => import("@modules/webinars/components/Webinar/WebinarDetails.vue")  , props : true },
        { path: "webinars_student" , component:() => import("@/modules/webinars/components/student/StudentWebinars.vue")  , props : true },
        { path: "webinars_student/:webinarUuid" , component:() => import("@/modules/webinars/components/student/StudentWebinar.vue")  , props : true }
    ],
    businessPublic : [
        { path: "webinars/:webinarUuid" , component:() => import("@/modules/webinars/components/Webinar/WebinarPublicDefault.vue")  , props : true }
    ]
}



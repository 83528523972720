const state = {
    categoriesSelect : []
}
const actions = {
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route: body.route,
                method: body.method,
                headers: {'Auth': body.token},
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return false
        }
    },

    // :: BUSINESS ::

    // business admin
    async GET_ADMIN_BUSINESS_NEWS_ALL({dispatch,rootState} = 'fine') {

        return await dispatch('AJAX_SEND_NEW',
            {
                route:`news/admin`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async GET_ADMIN_BUSINESS_NEWS_ONE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/edit/${body.alias}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async ADD_EDIT_BUSINESS_NEWS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/edit`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },

    async DELETE_BUSINESS_NEWS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/business/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async CHECK_NEWS_ALIAS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/check_alias/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },


    // public
    async GET_BUSINESS_NEWS_PUBLIC({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW', {
            route: `news/${body}/public`,
            method: 'GET',
            business : rootState.business.selectedBusiness.alias,
        } )
    },
    async GET_NEWS_PUBLIC({dispatch,rootState}) {
        return await dispatch('AJAX_SEND_NEW', {
            route: `business/news`,
            method: 'GET',
            business : rootState.business.selectedBusiness.alias,
        } )
    },
    async GET_NEWS_CATEGORIES({dispatch,rootState ,state} , body) {
        let result = await dispatch('AJAX_SEND_NEW', {
            route: `business/news/categories${body ? `/${body}` : ''}`,
            method: 'GET',
            business : rootState.business.selectedBusiness.alias,
        } )

        if ( !result || result === true ) {
            return
        }
        state.categoriesSelect = result

    },
    async ADD_EDIT_NEWS_CATEGORY({dispatch,rootState ,state} , body) {
        let result = await dispatch('AJAX_SEND_NEW', {
            route: `business/news/category/edit`,
            method: 'POST',
            data : body,
            business : rootState.business.selectedBusiness.alias,
        } )


        if ( !result || result === true ) {
            return
        }


        if ( !body.value ) {
            state.categoriesSelect.push(result)
        } else {
            let index = state.categoriesSelect.findIndex(el => el.value === body.value)
            if ( index === -1 ) {
                return
            }
            state.categoriesSelect[index] = result
            state.categoriesSelect = JSON.parse(JSON.stringify(state.categoriesSelect))
        }


    },
    async DELETE_NEWS_CATEGORY({dispatch,rootState ,state} , body) {
        let result = await dispatch('AJAX_SEND_NEW', {
            route: `business/news/category/${body}`,
            method: 'DELETE',
            business : rootState.business.selectedBusiness.alias,
        } )

        if ( !result ) {
            return
        }
        let index = state.categoriesSelect.findIndex(el => el.value === body)
        if ( index === -1 ) {
            return
        }
        state.categoriesSelect.splice(index , 1 )

    },

    // :: MAIN NEWS ::

    // public
    async GET_NEWS_MAIN_PUBLIC({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/main/public/filtered`,
                method:'POST',
                data : body
            },{ root : true });
    },
    async GET_FEATURED_NEWS_MAIN_PUBLIC({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/recent`,
                method:'GET'
            },{ root : true });
    },

    async GET_NEWS_MAIN_ONE_PUBLIC({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/main/single/${body}`,
                method:'GET'
            });
    },
    // admin
    async GET_NEWS_MAIN({dispatch,rootState}, project = 'fine') {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/main/${project}`,
                method:'GET'
            });
    },
    async GET_NEWS_MAIN_ONE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`news/main/edit/${body.uuid}/${body.lang}`,
                method:'GET'
            });
    },
    async ADD_EDIT_NEWS_MAIN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`news/main/edit`,
                method:'POST',
                data : body
            },{ root : true });
    },
    async UPLOAD_IMAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`upload/user/image/${body.param}`,
            method:'POST',
            data: {
                fileFormData : body.file
            } });
    },


}

export default {
    namespaced:true,
    actions,
    state
}
export default {

    courseMarketMain : [
        { path: 'market' , component: () => import("@/modules/market/components/CourseMarket") , props : true, children: [
                { path: '' , component: () => import("@/modules/market/components/Homepage/MarketHome"), props : true },
                { path: 'courses' , component: () => import("@/modules/market/components/Courses/MarketCourses"), props : true },
                { path: 'courses/:courseUuid' , component: () => import("@/modules/market/components/Course/MarketCourse") , props : true }
            ]
        }
    ]
}



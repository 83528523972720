const actions = {

    async ADD_PAGE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`page`,
                method:'PUT',
                data:body,
                business : rootState.business.selectedBusiness.alias
            },{ root : true}
        );
    },

}

export default {
    namespaced:true,
    actions,
}
import Vue from "vue"
import Vuex from "vuex"
import modules from './modules';

Vue.use(Vuex);

export default function getStore($ajax, $ajax_new, $ajax_custom, baseUrl, hostname , second_hostname , i18n )  {
    let ajax = $ajax.makeAjax( baseUrl , i18n );
    let ajaxNew = $ajax_new.makeAjaxNew( baseUrl , i18n );
    let ajaxFlush = () => {
        $ajax.flushQueue()
        $ajax_new.flushQueue()
    }
    let store = new Vuex.Store({
        namespaced : true,
        modules,
        state : {
            QUERY : {},
            $NEW_AVALON : false,
            $ajax : ajax ,
            $ajax_new : ajaxNew ,
            $ajax_custom,
            $ajaxFlush : ajaxFlush,
            $i18n : i18n,
            isHomepage : false,
            LOADING : false,
            $socket : null,
            project : 'westudy',
            MAINTENANCE_ON : false,
            windowWidth : 1200,
            facebookPixelEvents : [],

            filesPublic : baseUrl + '/storage/',
            ajaxRoute : baseUrl,
            hostname : hostname,
            second_hostname : second_hostname,
            iconSetUrl : second_hostname + '/library/img/avalon/icon_set/',
            appUrl : "",
            FRONT_LOCATION : '',
            AGENT_SOCIAL_APP : false,
            MI_BROWSER_APP : false,

            refresh: false,
            showLoginMenu: false,

            notification_text : '',
            notification_icon : '',
            notification : false,

            domain : false,
            secondaryHost : false,

            isRouteChanged : false,
            routeChangeCount : 0,

            shiftDown : false,
            languages : [
                'ua',
                'en',
                'ru',
                'pl',
                'de',
                'fr',
                'it',
                'es',
                'lv',
                'bg',
                'sl',
                'cs',
                'pt-PT',
                'hr',
                'sr',
                'hu',
                'nl',
                'ro',
                'no',
                'sv',
                'da',
                'et',
                'fi',
                'tr',
                'kk',
                'az',
                'ka',
                'ja',
                'th',

            ],
            routeLang : 'ua',
            MOBILE_FULLSCREEN : false,

        },
        mutations : {
            GET_QUERY_PARAMS(state) {
                const params = new URLSearchParams(window.location.search);
                const queryParams = {};
                for (const [key, value] of params.entries()) {
                    queryParams[key] = value;
                }
                state.QUERY = queryParams
            },
            toggleLoginMenu(state) {
                state.showLoginMenu=!state.showLoginMenu;
            },
            REFRESH(state) {
                state.refresh = true;
                setTimeout(()=>{
                    state.refresh = false;
                },100)
            },
            TOOGLE_NOTIFICATION(state,payload) {
                state.showNotification = !state.showNotification;
                if (payload) {
                    state.notificationMessage = payload.message
                }
            }
        }

    })
    if (module.hot) {
        // принимаем действия и мутации как горячие модули
        module.hot.accept([ './modules/'], () => {

            // меняем местами новые модули и мутации
            store.hotUpdate({
                mutations: store.mutations,
                state  :store.state,
                modules: modules
            })
        })
    }
    return store
}
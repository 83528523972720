const state = {
    error : null
}

const actions = {

    // Courses
    async SEND({rootState,state}, body ) {

        let {route, method,data} = body

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Token': process.env.VUE_APP_API_MARKET_TOKEN,
            'Server' : process.env.VUE_APP_API_URL,
            'Lang' : rootState.$i18n.locale
        }
        if ( rootState.auth.user.id) {
            headers.User = rootState.auth.user.email
        }
        let params = {
            method: method,
            headers: headers,
        }
        if (data) {
            params.body = JSON.stringify(data)
        }

        return new Promise((resolve) => {
            fetch(`${process.env.VUE_APP_API_MARKET_URL}${route}`, params)
                .then(response => response.json())
                .then(data => {
                    if (data.result) {
                        resolve(data.data)
                    } else {
                        state.error = data.error
                        resolve(false)
                    }

                })
                .catch((error) => {
                    state.error = error.message
                    resolve(false)
                });
        })

    }



}

export default {
    namespaced:true,
    actions,
    state
}
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localesArray from '@/locales/locales.modules.json'

Vue.use(VueI18n)

async function loadLocaleMessages (lang = 'en') {

  let languageFiles = localesArray.filter(el => el.lang === lang)


  let messages = {}

  for (const languageFile of languageFiles) {
    try {
      let langMessages = await import(`@/${languageFile.path}.json`)
      messages = {...messages , ...langMessages}
    } catch(err) {
      console.error(err)
    }

  }

  return messages

}
const availableLanguages = [
  'ua',
  'en',
  'ru',
  'pl',
  'de',
  'fr',
  'it',
  'es',
  'lv',
  'bg',
  'sl',
  'cs',
  'pt-PT',
  'hr',
  'sr',
  'hu',
  'nl',
  'ro',
  'no',
  'sv',
  'da',
  'et',
  'fi',
  'tr',
  'kk',
  'az',
  'ka',
  'ja',
  'th'
]
const fallbackLanguage = 'en'
var loadedLanguages = []

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ua',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ua',
  messages: {},
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ua': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    },
    'ru': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
})



i18n.$loadLanguage = async (lang = 'en') => {

  if (!availableLanguages.includes(lang)) {
    lang = fallbackLanguage;
  }
  if (loadedLanguages.includes(lang)) {
    console.log(`Language ${lang} already loaded`);
    return
  }
  let messages = await loadLocaleMessages(lang)
  i18n.setLocaleMessage(lang, messages)
  loadedLanguages.push(lang)
  console.log(`Language uploaded: ${lang}`);
}


export default function makeI18n() {
  return i18n
}

const state = {
    liveStream : {}
}
const actions = {

    // GENERAL

    async SAVE_CHANGES({ dispatch,rootState } , body  ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/save_changes/${body}/${state.selectedLang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
            }, { root : true});

    },


    // Business
    async GET_HOMEPAGE_STREAM_DATA( {dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`stream/homepage`,
                method:'GET'
            }, { root : true});


    },












}

export default {
    namespaced:true,
    actions,
    state

}
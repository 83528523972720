<template>
  <span style="position:fixed; pointer-events: none " >


    <!-- Square-->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="svgMaskSquare" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
             <rect id="svgMaskSquare"  width="54" height="54" fill="#ED8A58" />
        </clipPath>
    </svg>

    <!-- Circle-->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="svgMaskCircle" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
        <circle  style="color : red" color="red" cx="27" cy="27" r="27" fill="#DEE8EE" />
      </clipPath>
    </svg>

    <!-- Sand Watch-->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="svgMaskSand" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
          <path d="M0 36.093L27 16L54 36.093V54H0V36.093Z" fill="#DEE8EE"/>
          <path d="M54 17.907L27 38L1.75659e-06 17.907L3.32207e-06 -4.72083e-06L54 0L54 17.907Z" fill="#DEE8EE"/>
        </clipPath>
    </svg>

    <!--  Stop -->

    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
       <clipPath id="svgMaskStop" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
         <path d="M27 0L50.3827 13.5V40.5L27 54L3.61731 40.5V13.5L27 0Z" fill="#DEE8EE"/>
       </clipPath>
    </svg>

    <!-- Parallel-->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskParallel" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
        <path d="M21.4286 0H54L32.5714 54H0L21.4286 0Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- Ellipse-->
    <svg width="54" height="47" viewBox="0 0 54 47" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskEllipse" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/47})`" >
        <ellipse cx="27.0677" cy="23.3083" rx="15.944" ry="31.5873" transform="rotate(52.4112 27.0677 23.3083)" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- DoubleEllipse-->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskDoubleElipse" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4269 27C49.8557 24.2485 54 19.9205 54 15.0528C54 6.73935 41.9117 0 27 0C12.0883 0 0 6.73935 0 15.0528C0 19.9205 4.14432 24.2485 10.5731 27C4.14432 29.7516 0 34.0796 0 38.9473C0 47.2607 12.0883 54.0001 27 54.0001C41.9117 54.0001 54 47.2607 54 38.9473C54 34.0796 49.8557 29.7516 43.4269 27Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>


    <!-- FourEllipse-->
    <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskQuadroEllipse" clipPathUnits="objectBoundingBox" :transform="`scale(${1/46} , ${1/48})`" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.0641 13.5309C43.7588 12.1267 45.3468 10.4016 45.3468 8.53677C45.3468 3.82204 35.1956 0 22.6734 0C10.1512 0 0 3.82204 0 8.53677C0 10.4016 1.58804 12.1267 4.28271 13.5309C1.58804 14.9351 0 16.6603 0 18.5251C0 20.4614 1.7122 22.2471 4.59765 23.6794C1.7122 25.1116 0 26.8973 0 28.8336C0 30.8428 1.84347 32.6899 4.9283 34.1481C1.84347 35.6063 0 37.4534 0 39.4626C0 44.1773 10.1512 47.9993 22.6734 47.9993C35.1956 47.9993 45.3468 44.1773 45.3468 39.4626C45.3468 37.4534 43.5034 35.6063 40.4185 34.1481C43.5034 32.6899 45.3468 30.8428 45.3468 28.8336C45.3468 26.8973 43.6346 25.1116 40.7492 23.6794C43.6346 22.2471 45.3468 20.4614 45.3468 18.5251C45.3468 16.6603 43.7588 14.9351 41.0641 13.5309Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- FourEllipse-->
    <svg width="33" height="54" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskTrapezoid" clipPathUnits="objectBoundingBox" :transform="`scale(${1/33} , ${1/54})`" >
        <path d="M0.5 0H33L25.1111 54H7.88889L0.5 0Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- HalfRounded-->
    <svg width="46" height="54" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskHalfRounded" clipPathUnits="objectBoundingBox" :transform="`scale(${1/46} , ${1/54})`" >
        <path d="M0 0H22C35.2548 0 46 10.7452 46 24V27V54H24C10.7452 54 0 43.2548 0 30V0Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- TopRounded-->
    <svg width="40" height="54" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <clipPath id="svgMaskTopRounded" clipPathUnits="objectBoundingBox" :transform="`scale(${1/40} , ${1/54})`" >
       <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.9543 40 20V54H0V20Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- Semi Ellipse Right-->
    <svg width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="svgMaskSemiEllipseRight" clipPathUnits="objectBoundingBox" :transform="`scale(${1/44} , ${1/54})`" >
         <path d="M19 8.75121C14.4148 3.39469 7.60383 0 2.26498e-06 0L0 54C7.60383 54 14.4148 50.6053 19 45.2488V54C32.8071 54 44 42.8071 44 29V25C44 11.1929 32.8071 0 19 0V8.75121Z" fill="#DEE8EE"/>
        </clipPath>
    </svg>


    <!-- Semi Ellipse Top-->
    <svg width="46" height="49" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
         <clipPath id="svgMaskSemiEllipseTop" clipPathUnits="objectBoundingBox" :transform="`scale(${1/46} , ${1/49})`" >
           <path d="M23 0C10.2975 0 0 10.2975 0 23V26H23C10.2975 26 0 36.2975 0 49H46C46 36.2975 35.7025 26 23 26H46V23C46 10.2975 35.7025 0 23 0Z" fill="#DEE8EE"/>
         </clipPath>
    </svg>

    <!-- House -->
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <clipPath id="svgMaskHouse" clipPathUnits="objectBoundingBox" :transform="`scale(${1/54} , ${1/54})`" >
         <path d="M0 20.093L27 0L54 20.093V54H0V20.093Z" fill="#DEE8EE"/>
      </clipPath>
    </svg>

    <!-- Vertical Capsule -->
    <svg width="36" height="54" viewBox="0 0 36 54" fill="none" xmlns="http://www.w3.org/2000/svg">
       <clipPath id="svgMaskVerticalCapsule" clipPathUnits="objectBoundingBox" :transform="`scale(${1/36} , ${1/54})`" >
         <path fill-rule="evenodd" clip-rule="evenodd" d="M18 0C8.05887 0 0 8.05887 0 18V34V36C0 45.9411 8.05888 54 18 54C27.9411 54 36 45.9411 36 36L36 34L36 24L36 18C36 8.05888 27.9411 0 18 0Z" fill="#DEE8EE"/>
       </clipPath>
    </svg>








  </span>
</template>

<script>
export default {
  name: "clippingMasks"
}
</script>

<style scoped>

</style>

export default  {

    init($this) {

        if ($this.$store.state.business.selectedBusiness.welcome_tour_finished) {
            return
        }

        $this.$store.commit('onboarding/DISPLAY_ADMIN_WELCOME_WESTUDY_DASHBOARD')

    },

    start($this) {

        if ($this.$store.state.business.selectedBusiness.welcome_tour_finished) {
            return
        }

        $this.$nextTick(() => {
            const tour = $this.$shepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    canClickTarget : false,
                    scrollTo: true,
                    action() {

                    },
                    cancelIcon: {
                        enabled: true,
                    },
                    classes: 'tooltiper',
                }
            });

            tour.addStep({
                title: '<h3>Навігація</h3>',
                attachTo: { element:  '#business_sidebar', on: 'left' },
                width : '500px',
                text: 'Навігація по основним модулям системи',
                classes: 'tooltiper',
                buttons : [
                    {
                        action(){
                            this.next();
                        },
                        text: $this.$t('Next')
                    }
                ]
            });

            // tour.addStep({
            //     title: '<h2>Повернутись на сайт</h2>',
            //     attachTo: { element:  '#user_dropdown_goto_public', on: 'left' },
            //     width : '500px',
            //     text: 'Посилання на Публічний сайт Вашого інфобізнесу',
            //     buttons : [
            //         {
            //             action(){
            //                 document.getElementById('header_user_dropdown_button').click()
            //                 this.next()
            //             },
            //             text: $this.$t('Next')
            //         }
            //     ]
            // });
            tour.addStep({
                title: '<h2>Публічний сайт</h2>',
                attachTo: { element:  '#dashboard_logo', on: 'left' },
                width : '500px',
                text: 'Посилання на Публічний сайт Вашого інфобізнесу\n',
                buttons : [
                    {
                        action(){
                            this.next()
                        },
                        text: $this.$t('Next')
                    }
                ]
            });

            tour.addStep({
                title: '<h2>Месенджер</h2>',
                attachTo: { element:  '#sidebar_messenger', on: 'left' },
                width : '500px',
                text: '<p>Месенджер дозволяє робити масову чи персональну переписку із клієнтами.</p><p style="margin-top: 5px"> Просто оберіть клієнта / курс із клієнтами / всіх клієнтів та надішліть необхідне повідомлення.</p>' +
                    '<p style="margin-top: 5px">Також через Месенджер працює техпідтримка платформи WeStudy.</p>',
                buttons : [
                    {
                        action(){
                            this.next();
                        },
                        text: $this.$t('Next')
                    }
                ]
            });


            tour.addStep({
                title: '<h1>Допомога</h1>',
                attachTo: { element:  '#sidebar_help', on: 'left' },
                width : '500px',
                text: 'Вкладинка «Допомога» має відповіді на найбільш поширені питання як у текстовому вигляді, так і в відео форматі. \n' +
                    'Також через «Допомогу» можливо: Замовити налаштування, Замовити консультацію, Залишити побажання, Повідомити про несправність \n',
                buttons : [
                    {
                        action(){
                            this.next()
                        },
                        text: $this.$t('Next')
                    }
                ]
            });

            tour.addStep({
                title: '<h1>Курси</h1>',
                attachTo: { element:  '#sidebar_courses', on: 'left' },
                width : '500px',
                text: 'Через цю вкладинку створюються та редагуються курси. \n' +
                    'Для створення Вашого першого курсу на платформі WeStudy – натисніть на вкладинку «Курси» та розпочніть роботу. \n',
                buttons : [
                    {
                        action(){
                            document.getElementById('sidebar_courses').click()
                            this.next()
                        },
                        text: $this.$t('Next')
                    }
                ]
            });
            tour.on('cancel', () => {
                $this.$store.dispatch('onboarding/CANCEL_ADMIN_WECLOME')
                $this.notify($this.$t('TourCancelled'))
            })
            tour.on('complete', () => {
                $this.$store.dispatch('onboarding/CANCEL_ADMIN_WECLOME')
            })

            tour.start();
        });
    },
}
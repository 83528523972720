<template>
<div>
  <h5  v-if="label"
       :key="SCALE_COEFFICIENT + 'label'"
       :style="BLOCK_TEXT_CSS( block , configStyle , 'form' , 'label' , null , null , DEFAULT_BLOCK_WIDTH)"
     >
    {{  label  }}
  </h5>

  <v-sheet
      :style="`border : 1px solid ${BLOCK_COLOR_PARAM(block , 'form_border', configStyle)}; padding : ${FONT_SIZE/3}px 0`"
      :color="BLOCK_COLOR_PARAM(block , 'form_background' , configStyle) "
      class="d-flex justify-space-between"
  >
    <!--  COUNTRY CODE -->
    <v-menu
        v-model="countryCodeEditing"
        :close-on-click="false"
        :close-on-content-click="false"
        offset-y max-height="400" >

        <!-- Value Text  -->
        <template #activator="{ on, attrs }">
          <v-sheet
              @click="countryCodeEditing = true"
              v-bind="attrs"
              color="transparent"
          >
            <form autocomplete="off">
              <div class="d-flex" style="position:relative;">
                <h5 style="z-index: 99999; pointer-events: none"
                    :key="SCALE_COEFFICIENT + 'country_code'"
                    :style="BLOCK_TEXT_CSS(block , configStyle , 'form' , 'text', null , null , DEFAULT_BLOCK_WIDTH )
                    + `;margin-left : ${FONT_SIZE/2}px;`
                    + `opacity: ${!countrySearch ? 0.3 : 0};`
"
                >
                  <span v-if="!countrySearch">+380</span>
                  <span v-else>
                     {{ countrySearch.length > 4 ? countrySearch : '+380' }}
                  </span>

                </h5>

                <input
                    id="cc"
                    :key="SCALE_COEFFICIENT + 'input'"
                    v-model="countrySearch"
                    maxlength="5"
                    @blur="handleCountryCodeBlur"
                    style="position:absolute; outline: none; width: 100%; "
                    :style="BLOCK_TEXT_CSS(block , configStyle , 'form' , 'text', null , null , DEFAULT_BLOCK_WIDTH ) + `;margin-left : ${FONT_SIZE/2}px;`"
                    ref="phone"
                    type="text"
                    autocomplete="off"
                    inputmode="text"
                />
              </div>

            </form>


          </v-sheet>

        </template>

        <!-- Dropdown  -->
        <v-sheet :color="BLOCK_COLOR_PARAM(block , 'form_background' , configStyle) ">
          <v-hover v-for="(item , i) in countryCodesFiltered" :key="i"  #default="{hover}">

            <v-sheet
                @mouseover="mouseOverDropdown = true"
                @mouseleave="mouseOverDropdown = false"
                @click="selectCountry(item)"
                :color="hover ? '#00000011' : 'transparent'"
                class="px-3 py-1 pointer" >

              <h3 :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null ) + `padding : ${FONT_SIZE/3}px 0`" >
               {{ item.value }} ({{ item.text }})
              </h3>


            </v-sheet>

          </v-hover>
        </v-sheet>
      </v-menu>

    <!-- TEXT INPUT  -->
    <div class="d-flex align-center" style="width: 100%; position: relative">

      <!-- Placeholder  -->
      <h5 v-if="!phone"
          :key="SCALE_COEFFICIENT + 'placeholder'"
          style="position: absolute;pointer-events: none; line-height: 1 !important"
          :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null , true , DEFAULT_BLOCK_WIDTH) + `;margin-left : ${FONT_SIZE/2}px;`"
      >
        {{ placeholder || $t('EnterText') }}
      </h5>

      <!-- Textfield -->
      <input
          :key="SCALE_COEFFICIENT + 'phone_input'"
          @keypress="handleKeyPress( $event,phone )"
          style="width: 100%; outline: none;"
          :style="BLOCK_TEXT_CSS(block , configStyle , 'form' , 'text' , null , !value , DEFAULT_BLOCK_WIDTH  ) + `;margin-left : ${FONT_SIZE/2}px;`"
          ref="text"
          v-model="phone"
      />

    </div>



    <!-- Actions -->
    <div class="d-flex align-center" :style="`margin-right : ${FONT_SIZE/3}px;`">


      <!-- Clearable Icon-->
      <v-icon v-if="text" @click="text = ''" class="pointer" :color="BLOCK_COLOR_PARAM(block , 'form_icon', configStyle)">mdi-close-circle</v-icon>

      <v-icon class="pointer" :color="BLOCK_COLOR_PARAM(block , 'form_icon', configStyle)">
        {{ value ? 'mdi-check-circle-outline' : 'mdi-information-outline' }}
      </v-icon>


    </div>

  </v-sheet>


</div>
</template>

<script>
import countryCodes from '@/assets/json/country_codes_select.json'

export default {
  name: "avalonTextField",
  props : {
    value : {
      type : String,
    },
    block : {
      type : Object,
    },
    configStyle : {
      type : String,
    },
    label : {
      type : String
    },
    placeholder : {
      type : String,
    },
  },
  data() {
    return {
      countryCode : '+380',
      countrySearch : '+380',
      phone : '',
      expandCodes : false,
      countryCodeEditing : false,
      mouseOverDropdown : false,
    }
  },
  computed:  {
    phoneNumber() {
      return this.countryCode + this.phone
    },

    countryCodesFiltered() {
      if ( !this.countrySearch  ) {
        return this.countryCodes
      }
      return this.countryCodes.filter(el => el.value.toLowerCase().includes(this.countrySearch.trim().toLowerCase()))
    },
    countryCodes() {
      return countryCodes
    },
    FONT_SIZE() {
      return this.BLOCK_FONT_VARIABLE(this.block, 'size' , 'form' , 'text' ) * this.SCALE_COEFFICIENT
    }
  },
  watch : {
    phone(value) {
      this.checkPhone(value)
    },
    countryCode() {
      this.checkPhone()
    },
    value(value) {
      let number = this.countryCode + this.phone

      if ( number!== value) {
        this.checkCode(value  )
      }
    }
  },
  methods : {
    checkCode(number) {
      if ( this.countryCodes.length === 0 ) {
        return
      }
      let codesArray = this.countryCodes.map( el => el.value)
      codesArray.forEach((code) => {
        if (number.includes(code)) {
          this.countryCode = code
          this.countryCodeSearch = code
          this.phone = number.replace(code,'')
        }
      })
    },
    checkPhone(value) {

      if ( value && value.length < 8 ) {
        this.$emit('input','')
        return this.errorMessage = 'Номер телефона складається з 10 цифр'
      } else {
        this.errorMessage = ''
        if (this.phoneNumber.length === 3 ) {
          this.$emit('input','')
        } else {
          this.$emit('input',this.phoneNumber)
        }
      }
    },

    handleKeyPress($event , element ) {
        this.NUMBER_INPUT($event,element, parseInt(this.numberLength) )
    },

    handleCountryCodeBlur() {
      if ( !this.mouseOverDropdown ) {
        this.countryCodeEditing = false
        if ( this.countrySearch !== this.countryCode) {
          let item = this.countryCodes.find(el => el.value.replace('+','') === this.countrySearch.replace('+','').trim() )
          if ( item ) {
            this.countryCode = item.value
            this.countrySearch = item.value
          } else {
            this.countrySearch = this.countryCode
          }
        }

      }

    },
    selectCountry(item) {
      this.countryCodeEditing = false
      this.countryCode = item.value
      this.countrySearch = this.countryCode
    }
  },
  mounted() {
    if ( !this.block && !this.configStyle ) {
      this.configStyle = 'light_1'
    }
    if  (this.value ) {
      if ( this.value.length > 10 ) {
        this.checkCode(this.value)
      }
    }
  }
}
</script>

<style scoped>
.auto-growable-input {
  resize: none;
  width: auto;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden !important; /* Hide any vertical overflow */
  white-space: nowrap; /* Prevent line breaks */
}
</style>